import React from 'react';
import moment from 'moment-timezone';
import "./../assets/styles/Footer.css";
import { useTranslation } from 'react-i18next';
import Language from './language';


const FooterMidlle = () => {
    const { t } = useTranslation();

  return (
    <footer className="d-flex my-footer">
      <div id="footerContainer" className="container  justify-content-center">
          <a
            href="http://skiesbook.com"
            target="_blank"
            className="row justify-content-center col-12 col-lg-2 item noMargin noPadding"
            style={{color:'#958f8f'}} rel="noreferrer"
          >
            {t("© Skiesbook LLC - ")} {moment().get("year")}

        </a>
        <a
            href="/personal-information-policy"
            target="_blank"
            className="row justify-content-center col-12 col-lg-2 item noMargin noPadding"
            style={{color:'#958f8f'}}
          >
            {t("Personal information protection policy")}
          </a>

        <a
            href="/privacy-policy"
            target="_blank"
            className="row justify-content-center col-12 col-lg-2 item noMargin noPadding"
            style={{color:'#958f8f'}}
          >
            {t("Privacy Policy")}
          </a>

      </div>
      <div className='justify-content-end mr-3'>
      <div className="p-1">
            <Language />
          </div>
      </div>
    </footer>
  );
};

export default FooterMidlle;
