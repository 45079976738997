import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faArrowLeft,
  faBookReader,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "./pagination";
import "./pagination.css";
import {
  Col,
  Row,
  Card,
  Image,
  Nav,
  Dropdown,
  Button,
  ButtonGroup,
  InputGroup,
  Form,
  Breadcrumb,
  Modal,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "src/utils/axios";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment-timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import { Table as TableBoost } from "@themesberg/react-bootstrap";

import { Avatar } from "@mui/material";

const GetClient = () => {
  const { t } = useTranslation();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const [locale, setLocale] = React.useState(currentLanguageCode);

  useEffect(() => {
    setLocale(currentLanguageCode);
  }, [currentLanguageCode]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const token = JSON.parse(localStorage.getItem("token"));
  let hasAccess = false;
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);

  let graveyardId;
  let url;
  let { id } = useParams();
  if (decoded.role === ("admin" || "gadmin")) {
    graveyardId = decoded?.graveyardId;
    url =
      process.env.REACT_APP_BACKEND_URL +
      "/users/getclients/" +
      decoded?.graveyardId;
  } else if (decoded.role === ("gstaff" || "gcompta" || "gadmin")) {
    graveyardId = decoded?.graveyardId;
    url =
      process.env.REACT_APP_BACKEND_URL +
      "/profile/staffgetclients/" +
      decoded.userId;
  } else if (decoded.role === "funHomeAdmin") {
    graveyardId = id;
    url = process.env.REACT_APP_BACKEND_URL + "/funHome/getclients/";
  } else {
    graveyardId = id;
    url = process.env.REACT_APP_BACKEND_URL + "/users/getclients/" + id;
    hasAccess = true;
  }
  const history = useHistory();

  const [data, setData] = useState([]);
  const [grave, setGrave] = useState({});
  const [pData, setPData] = useState([]);
  const [word, setWord] = useState("");

  const fetchData = async (fetch) => {
    let indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;
    try {
      if (fetch) {
        const { data: response } = await axios.get(url);
        setData(response);
        setPData(response.slice(indexOfFirstPost, indexOfLastPost));
      } else {
        setPostsPerPage(20);
        setPData(data.slice(indexOfFirstPost, indexOfLastPost));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  async function fetchGrave() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/graveyard/" + graveyardId
      );
      setGrave(response);
    } catch (error) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    fetchGrave();
    if (data.length === 0) fetchData(true);
    if (word !== "") search(word);
    else fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, word]);

  async function search(word) {
    if (word === "") {
      fetchData(false);
    } else {
      searchKeyword(word);
    }
  }

  async function searchKeyword(w) {
    w = w.replace(/\s/g, "").toUpperCase();
    const filtredData = data.filter((item) => {
      const name = item?.name?.toUpperCase() || "";
      const lastName = item?.lastname?.toUpperCase() || "";
      const email = item?.email?.toUpperCase() || "";
      return name.includes(w) || lastName.includes(w) || email.includes(w);
    });

    setPData(filtredData);
    if (filtredData.length !== 0) {
      setPostsPerPage(1000);
    }
  }

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => {
    setInputList({
      ...inputList,
      modeDeath: "I",
      collabValue: null,
      collab: null,
      position: {},
    });
    setShowDefault(false);
  };

  const [editModal, setEditmodal] = useState(false);
  const handleCloseEditModal = () => setEditmodal(false);

  const [clientMail, setMail] = useState("");
  const [client, setClient] = useState("");
  const [alive, setAlive] = useState(true);
  const [emplacement, setEmplacement] = useState([]);
  const [profile, setProfile] = useState({
    profileName: "",
    profileLastName: "",
    profileDatebirth: null,
    profileDatedeath: null,
    gender: "F",
    cords: "",
    modeDeath: "I",
    hometown: "",
    client: "",
    vendor: "",
    alive: false,
    position: {},
  });

  async function setModal(mail, clientid) {
    setShowDefault(true);
    setMail(mail);
    setClient(clientid);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/getemplacements/" +
          clientid
      )
      .then((response) => {
        setEmplacement(response.data);
      });
  }

  const [inputList, setInputList] = useState({
    profileName: "",
    profileLastName: "",
    profileDatebirth: null,
    profileDatedeath: null,
    gender: "F",
    cords: "",
    modeDeath: "I",
    profileEmail: clientMail,
    hometown: "",
    graveyard: decoded.graveyardId,
    banner: "banner.jpg",
    profileImage: "avatar.jpg",
    client: "",
    vendor: "",
    alive: false,
    collab: null,
    collabValue: null,
    collabValues: [],
    position: {},
  });

  async function sendMail(email) {
    Swal.fire({
      title: t("Send reset password email to the client"),
      showCancelButton: true,
      confirmButtonText: `${t("yes")} !`,
      showLoaderOnConfirm: true,
      cancelButtonText: t("cancel"),
      preConfirm: async () => {
        return await axios
          .post(process.env.REACT_APP_BACKEND_URL + "/users/resetpassword/", {
            email: email,
          })
          .then((result) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("email sent successfully"),
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                console.log("ok");
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: t("error_occured"),
              showConfirmButton: true,
            });
          });
      },
    });
  }

  async function ajouterProfil(e) {
    e.preventDefault();
    inputList.client = client;
    inputList.vendor = decoded.userId;
    inputList.profileEmail = clientMail;

    let valid = true;

    if (
      inputList.profileName === "" ||
      inputList.profileLastName === "" ||
      inputList.profileDatebirth === null
    ) {
      valid = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: t("Fill all the required fields"),
        showConfirmButton: true,
      });
      return;
    }

    if (
      inputList.modeDeath === "C" &&
      (inputList.collab === null || inputList.collabValue === null)
    ) {
      valid = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: t("Fill all the required fields"),
        showConfirmButton: true,
      });
      return;
    }

    if (valid === false) return;

    Swal.fire({
      title: t("Add a new profile"),
      showCancelButton: true,
      confirmButtonText: `${t("yes")} !`,
      showLoaderOnConfirm: true,
      cancelButtonText: t("cancel"),
      preConfirm: async () => {
        return await axios
          .post(
            process.env.REACT_APP_BACKEND_URL + "/users/addinstance",
            inputList
          )
          .then((result) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("profile_added_successfully"),
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                fetchData(true);
                handleClose();
              }
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`${t("error")}: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  //console.log(currentPosts);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  async function handlePosition(pos) {
    const position = emplacement.find((e) => e.code === pos);

    setInputList({
      ...inputList,
      position: position,
    });
  }
  async function handleEdit(profile) {
    setProfile(profile);
    if (profile.profileDatedeath) setAlive(false);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/getemplacementsgrave/" +
          profile?.graveyard
      )
      .then((response) => {
        setEmplacement(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setEditmodal(true);
  }

  async function changeStatus(status) {
    setAlive(status);
    setProfile({ ...profile, profileDatedeath: null });
    setInputList({ ...inputList, profileDatedeath: null });
  }

  async function updateProfile(e) {
    e.preventDefault();
    try {
      Swal.fire({
        title: t("Are you sure?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, update it!"),
        cancelButtonText: t("cancel"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data: response } = await axios.put(
            process.env.REACT_APP_BACKEND_URL + "/profile/" + profile._id,
            profile
          );
          if (response) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("Profile updated!"),
              showConfirmButton: false,
              timer: 1500,
            });
            fetchData(true);
            handleClose();
          }
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }
  const [matrix, setMatrix] = React.useState([]);
  const [collabModal, setCollabModal] = React.useState(false);

  const handleCloseModal = () => {
    setCollabModal(false);
    setMatrix([]);
  };

  function handleCollab(val) {
    setInputList({
      ...inputList,
      collab: val?._id,
      collabValues: val?.values,
      position: val?.position,
    });
  }

  const addNicheAsValue = (i, j) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    let alphabetCode = alphabet[i];
    const nicheCode = alphabetCode + (j + 1);
    const niche = {
      [alphabetCode]: j + 1,
      value: nicheCode,
    };
    inputList.collabValue = niche;
    setInputList(inputList);
    handleCloseModal();
  };

  const handleCollabModal = (id) => {
    const myCollab = grave?.collabs?.find((collab) => collab._id === id);
    setMatrix(myCollab?.data);
    setCollabModal(true);
  };
  return (
    <>
      {/*     <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div> */}

      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={collabModal}
        size="xl"
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Select a niche")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => console(e)}>
            <Row>
              <Col xs={12} className="mb-3">
                <Card.Body className="p-0">
                  <TableContainer component={Paper}>
                    <TableBoost hover className="user-table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="border-2">
                            #
                          </th>
                          {
                            // number of columns
                          }
                          {matrix[0]?.map((col, i) => {
                            return (
                              <>
                                <th scope="col" className="border-2">
                                  {i + 1}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {matrix.map((row, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="border-2">
                                  {
                                    // alphabet
                                  }
                                  {String.fromCharCode(65 + i)}
                                </td>
                                {row.map((col, j) => {
                                  return (
                                    <>
                                      <td
                                        key={j}
                                        className={matrix[i][j] ? "active" : ""}
                                      >
                                        {matrix[i][j].length > 0 ? (
                                          // if matrix[i][j] has only one element
                                          matrix[i][j].length === 1 ? (
                                            <div
                                              className="periodic-element just-trying"
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                              data-description={
                                                matrix[i][j][0]?.profileName +
                                                " " +
                                                // if col.profileLastName is undefined don't show it
                                                (matrix[i][j][0]
                                                  ?.profileLastName || "")
                                              }
                                            >
                                              <span
                                                style={{
                                                  color: "black",
                                                  float: "left",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  addNicheAsValue(i, j);
                                                }}
                                              >
                                                <i className="ri-add-fill" />{" "}
                                              </span>
                                              <div className="periodic-element-inner">
                                                <Avatar
                                                  style={{
                                                    marginLeft: "10px",
                                                  }}
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_IMAGE +
                                                    "/" +
                                                    matrix[i][j][0]
                                                      ?.profileImage
                                                  }
                                                />
                                                {
                                                  // span in the middle of the element
                                                }
                                                <div
                                                  style={{
                                                    width: "90px",
                                                    whiteSpace: "normal",
                                                  }}
                                                >
                                                  {matrix[i][j][0]?.profileName}{" "}
                                                  {
                                                    matrix[i][j][0]
                                                      ?.profileLastName?.[0]
                                                  }
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                width: "100px",
                                                height: "80px",
                                                // blue background color
                                                background: "#323c46",
                                                // all elements are in the center
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                            >
                                              <div
                                                // circle with number of elements
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  borderRadius: "50%",
                                                  background: "#2c343d",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  color: "white",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                {matrix[i][j].length}
                                              </div>
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            style={{
                                              width: "100px",
                                              height: "80px",
                                              border: "2px solid #323c46",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // circle with the add icon
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                background: "#2c343d",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "white",
                                                fontSize: "20px",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                addNicheAsValue(i, j);
                                              }}
                                            >
                                              <i className="ri-add-fill" />
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </TableBoost>
                  </TableContainer>
                </Card.Body>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={editModal}
        size="lg"
        onHide={handleCloseEditModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("edit profile")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleCloseEditModal}
          />
        </Modal.Header>
        <Form onSubmit={(e) => updateProfile(e)}>
          <Modal.Body>
            <Row>
              {decoded.role === "superadmin" && (
                <>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>{t("lastname")}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        maxLength={50}
                        name="profileName"
                        defaultValue={profile?.profileName}
                        onChange={(e) =>
                          setProfile({
                            ...profile,
                            profileName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>{t("firstname")}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        maxLength={50}
                        defaultValue={profile?.profileLastName}
                        name="profileLastName"
                        onChange={(e) =>
                          setProfile({
                            ...profile,
                            profileLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Row className="align-items-center">
              <Col md={4} className="mb-3">
                <Form.Group id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        disabled={!hasAccess}
                        disableFuture
                        value={profile?.profileDatebirth}
                        label={t("date_of_birth")}
                        openTo="year"
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setProfile({ ...profile, profileDatebirth: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="checkbox">
                  <Form.Check
                    type="checkbox"
                    checked={!alive}
                    label={t("Deceased")}
                    onChange={(e) => changeStatus(!alive)}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group hidden={alive} id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        disableFuture
                        openTo="year"
                        label={t("date_of_death")}
                        value={profile?.profileDatedeath}
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setProfile({ ...profile, profileDatedeath: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>{t("gender")}</Form.Label>
                  <Form.Select
                    defaultValue={profile?.gender}
                    name="gender"
                    disabled={!hasAccess}
                    onChange={(e) =>
                      setProfile({ ...profile, gender: e.target.value })
                    }
                  >
                    <option value="F">{t("women")}</option>
                    <option value="M">{t("man")}</option>
                    <option value="I">{t("other")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="funérailles">
                  <Form.Label>{t("Funeral arrangements")}</Form.Label>
                  <Form.Select
                    defaultValue={profile?.modeDeath}
                    disabled={!hasAccess}
                    name="modeDeath"
                    onChange={(e) =>
                      setProfile({ ...profile, modeDeath: e.target.value })
                    }
                  >
                    <option value="I">{t("Burial")}</option>
                    <option value="C">{t("Columbarium")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="email">
                  <Form.Label>{t("reference_email")}</Form.Label>
                  <Form.Control
                    required
                    name="profileEmail"
                    type="email"
                    value={profile?.profileEmail}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("edit")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleCloseEditModal}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={showDefault}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Add a new profile")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleClose}
          />
        </Modal.Header>
        <Form onSubmit={(e) => ajouterProfil(e)}>
          <Modal.Body>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("lastname")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    name="profileName"
                    onChange={(e) =>
                      setInputList({
                        ...inputList,
                        profileLastName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>{t("firstname")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    name="profileLastName"
                    onChange={(e) =>
                      setInputList({
                        ...inputList,
                        profileName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={4} className="mb-3">
                <Form.Group id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        value={inputList.profileDatebirth}
                        disableFuture
                        label={t("date_of_birth")}
                        openTo="year"
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setInputList({ ...inputList, profileDatebirth: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="checkbox">
                  <Form.Check
                    type="checkbox"
                    checked={alive}
                    label={t("Deceased")}
                    onChange={(e) => changeStatus(!alive)}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group hidden={!alive} id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        value={inputList.profileDatedeath}
                        disableFuture
                        label={t("date_of_death")}
                        openTo="year"
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setInputList({ ...inputList, profileDatedeath: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="funérailles">
                  <Form.Label>{t("Funeral arrangements")}</Form.Label>
                  <Form.Select
                    defaultValue="I"
                    name="modeDeath"
                    onChange={(e) =>
                      setInputList({
                        ...inputList,
                        modeDeath: e.target.value,
                        collab: null,
                        collabValue: null,
                        position: null,
                      })
                    }
                  >
                    <option value="I">{t("Burial")}</option>
                    <option value="C">{t("Columbarium")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {inputList?.modeDeath === "I" ? (
                <Col md={6} className="mb-3">
                  <Form.Group id="location">
                    <Form.Label>{t("location")}</Form.Label>
                    <Autocomplete
                      id="position"
                      options={emplacement.map((option) => option?.code)}
                      onChange={(e, value) => {
                        handlePosition(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label={t("location")} />
                      )}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <>
                  <Col md={4} className="mb-3">
                    <Form.Group id="Columbarium">
                      <Form.Label>{t("Columbarium")}</Form.Label>
                      {inputList?.collab ? (
                        // eye icon to see the columbarium
                        <i
                          style={{
                            color: "black",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          className="ri-eye-line"
                          onClick={() => {
                            handleCollabModal(inputList?.collab);
                          }}
                        ></i>
                      ) : (
                        <> </>
                      )}

                      <Autocomplete
                        id="position"
                        options={grave?.collabs}
                        disableClearable
                        getOptionLabel={(option) => option?.name}
                        onChange={(e, value) => handleCollab(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group id="Niche">
                      <Form.Label>{t("Niche")}</Form.Label>
                      {
                        // free solo option with search
                      }
                      <Autocomplete
                        id="position"
                        options={inputList?.collabValues}
                        getOptionLabel={(option) =>
                          option?.value?.toUpperCase()
                        }
                        value={inputList?.collabValue}
                        onChange={(e, value) =>
                          setInputList({ ...inputList, collabValue: value })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>{t("gender")}</Form.Label>
                  <Form.Select
                    defaultValue="0"
                    name="gender"
                    onChange={(e) =>
                      setInputList({ ...inputList, gender: e.target.value })
                    }
                  >
                    <option value="0">{t("other")}</option>
                    <option value="F">{t("woman")}</option>
                    <option value="M">{t("man")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>{t("reference_email")}</Form.Label>
                  <Form.Control
                    required
                    name="profileEmail"
                    type="email"
                    value={clientMail}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("Add")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          ></Breadcrumb>

          <div className="btn-toolbar mb-2 mb-md-2">
            <ButtonGroup>
              <Dropdown.Toggle
                onClick={(e) => history.goBack()}
                as={Button}
                variant="primary"
                size="sm"
                className="me-2"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                {t("back")}
              </Dropdown.Toggle>
            </ButtonGroup>
          </div>
          <h4>
            {t("List of Cemetery Clients")} {grave?.name}
          </h4>

          {/*           <p className="mb-0">Your web analytics dashboard template.</p>

 */}
        </div>
        {decoded?.role === "admin" ||
        decoded?.role === "gadmin" ||
        decoded?.role === "funHomeAdmin" ? (
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
              <Dropdown.Toggle
                onClick={(e) => history.push("/adminaddclient")}
                as={Button}
                variant="primary"
                size="sm"
                className="me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("New customer")}
              </Dropdown.Toggle>
            </ButtonGroup>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                maxLength={50}
                placeholder={t("Search")}
                onChange={(e) => setWord(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <CollapsibleTable />
        </Card.Body>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination
              className="pagination"
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
            />
          </Nav>
        </Card.Footer>
      </Card>
    </>
  );

  function Rows(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label={t("expand row")}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Card.Link href="#" className="text-primary fw-bold">
              <div className="user-avatar lg-avatar me-4">
                <Image
                  style={{
                    flex: 1,
                    width: "50px",
                    height: "50px",
                    resizeMode: "contain",
                  }}
                  src={process.env.REACT_APP_API_IMAGE + "/" + row?.userimage}
                  className="card-img-top rounded-circle border-white"
                />
              </div>
            </Card.Link>
          </TableCell>
          <TableCell align="left">
            {row?.name} {row?.lastn}
          </TableCell>
          <TableCell align="left"> {row?.email}</TableCell>
          <TableCell align="left">{row?.phone}</TableCell>
          <TableCell align="left">{row?.profiles.length}</TableCell>
          <TableCell align="left">
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                onClick={() => setModal(row?.email, row?._id)}
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                size="sm"
                title={t("add")}
              >
                <i style={{ color: "black" }} className="ri-add-line" />
              </Button>
              <Button
                onClick={() => history.push("/editclient/" + row?._id)}
                size="sm"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                title={t("edit")}
              >
                <i style={{ color: "black" }} className="ri-pencil-line" />
              </Button>
              <Button
                onClick={() => sendMail(row?.email)}
                size="sm"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                title={t("send mail")}
              >
                <i style={{ color: "black" }} className="ri-key-2-line" />
              </Button>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t("Profiles")}
                </Typography>
                <Table size="small" aria-label={t("purchases")}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("photo")}</TableCell>
                      <TableCell>{t("full_name")}</TableCell>
                      <TableCell align="left">{t("Date of death")}</TableCell>
                      <TableCell align="left">
                        {t("Profile creation")}
                      </TableCell>
                      <TableCell align="left">{t("actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.profiles.map((profile) => (
                      <TableRow key={profile._id}>
                        <TableCell align="left">
                          <Card.Link href="#" className="text-primary fw-bold">
                            <div className="user-avatar lg-avatar me-4">
                              <Image
                                style={{
                                  flex: 1,
                                  width: "50px",
                                  height: "50px",
                                  resizeMode: "contain",
                                }}
                                src={
                                  process.env.REACT_APP_API_IMAGE +
                                  "/" +
                                  profile?.profileImage
                                }
                                className="card-img-top rounded-circle border-white"
                                onClick={() =>
                                  history.push("/prof/" + profile._id)
                                }
                              />
                            </div>
                          </Card.Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {profile.profileName} {profile.profileLastName}
                        </TableCell>
                        <TableCell>
                          {profile?.profileDatedeath
                            ? moment(profile?.profileDatedeath).format(
                                "YYYY-MM-DD"
                              )
                            : " "}
                        </TableCell>
                        <TableCell align="left">
                          {moment(profile?.createdAt).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Button
                              onClick={() =>
                                history.push("/prof/" + profile._id)
                              }
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                              }}
                              size="sm"
                              className="m-1"
                              title={t("view")}
                            >
                              <i
                                style={{ color: "black" }}
                                className="ri-eye-line"
                              />{" "}
                            </Button>
                            <Button
                              onClick={() =>
                                history.push("/editprof/" + profile._id)
                              }
                              size="sm"
                              className="m-1"
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                              }}
                              title={t("edit")}
                            >
                              <i
                                style={{ color: "black" }}
                                className="ri-pencil-line"
                              />
                            </Button>
                            <Button
                              onClick={() => handleEdit(profile)}
                              size="sm"
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                              }}
                              className="m-1"
                              title={t("inspect")}
                            >
                              <FontAwesomeIcon
                                style={{ color: "black" }}
                                icon={faBookReader}
                              />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function CollapsibleTable() {
    return (
      <TableContainer component={Paper}>
        <Table aria-label={t("collapsible table")}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>#</TableCell>
              <TableCell align="left">{t("Client")}</TableCell>
              <TableCell align="left">{t("email")}</TableCell>
              <TableCell align="left">{t("phone")}</TableCell>
              <TableCell align="left">{t("number_of_profiles")}</TableCell>
              <TableCell align="left">{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pData?.map((row) => (
              <Rows key={row._id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};
export default GetClient;
