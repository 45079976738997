import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  InputGroup,
  ButtonGroup,
  Dropdown,
  Button,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'src/utils/axios';import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";


const EditClient = () => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const [hasAccess, setHasAccess] = useState(false);
 
  const [user, setUser] = useState({});
  let { id } = useParams();
  const history = useHistory();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  async function getGrave() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/" + id
      );
      setUser(response);
    } catch (error) {
      console.error(error.message);
    }
  }
  useEffect(() => {
    if (decoded !== null) {
      if (decoded.role === "superadmin") {
        setHasAccess(true);
      }
    }
    getGrave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [errorImage , setErrorImage] = useState(false)
  function setImage(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.type && file.type.startsWith("image/")) {
      setErrorImage(false)
      reader.onloadend = () => {
        setFormData({ ...formData, userimage: file });
      };
      reader.readAsDataURL(file);
    } else {
      setErrorImage(true)
      Swal.fire({
        title: t("Error"),
        text: t("Please select an image"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
    }
  }

  const [formData, setFormData] = useState({
    name: user?.name,
    lastn: user?.lastn,
    Datebirth: user?.Datebirth,
    email: user?.email,
    sex: user?.sex,
    password: "",
    confirmPassword: "",
    userimage: "",
    phone: user?.phone,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function Submit(e) {
    e.preventDefault();
    const mydata = new FormData();
    mydata.append("name", e.target[0].value);
    mydata.append("lastn", e.target[1].value);
    mydata.append("email", e.target[2].value);
    mydata.append("phone", e.target[3].value);

    if (formData.userimage !== "") {
      mydata.append("userimage", formData.userimage);
    }
    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        title: t("Error"),
        text: t("Password not match"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
      return;
    }
    if (
      formData.password !== "" &&
      formData.password === formData.confirmPassword
    ) {
      mydata.append("password", formData.password);
    }

    /*     mydata.append("latitude", position.lat);
    mydata.append("logitude", position.lng); */
    const { data } = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/users/" + id,
      mydata,
      config
    );
    if (data._id) {
      Swal.fire({
        title: t("Success"),
        text: t("User Updated"),
        icon: "success",
        confirmButtonText: t("OK"),
      }).then((result) => {
        if (result.value) {
          history.push("/adminclients");
        }
      });
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("User not Updated"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
    }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="btn-toolbar mb-2 mb-md-2">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.goBack()}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              {t("back")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
        <h5 className="mb-4">{t("Edit a customer")}</h5>

        <h5 className="mb-4">{t("General informations")}</h5>
        <Form onSubmit={(e) => Submit(e)}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.name}
                  type="text"
                  maxLength={50}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.lastn}
                  type="text"
                  maxLength={50}
                  onChange={(e) =>
                    setFormData({ ...formData, lastn: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  defaultValue={user?.email}
                  type="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <PhoneInput
                  country={"ca"}
                  onlyCountries={["us", "ca"]}
                  value={user?.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row hidden>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    disabled={!hasAccess}
                    type={passwordType}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("confirm_your_password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    type={passwordType}
                    disabled={!hasAccess}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      })
                    }
                  ></Form.Control>
                </InputGroup>
                {formData.confirmPassword !== formData.password &&
                formData.confirmPassword !== "" ? (
                  <MuiAlert severity="warning">
                    {t("password_does_not_match")}
                  </MuiAlert>
                ) : (
                  <span></span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Col md={6} className="mb-3">
            <Form.Group id="firstName">
              <Form.Label>{t("image")}</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e)}
                defaultValue={formData.userimage}
                isInvalid={errorImage}
              />
            </Form.Group>
          </Col>

          <div className="mt-3">
            <Button variant="primary" disabled={errorImage} type="submit">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default EditClient;
