import React from "react";
import QRCode from "qrcode.react";

export default function App(props) {
  // eslint-disable-next-line


  return (
    <div className="App">
      <br />
      <QRCode
        id="qr-gen"
        value={props.myvalue}
        size={230}
        level={"H"}
        includeMargin={true}
      />
      
    </div>
  );
}


