import React from "react";
import "./style.css";
import QRCode from "qrcode.react";
import moment from "moment-timezone";

export default function MetalPlate({ profile }) {
  return (
    <>
      <div id="qr-gen" className="metallic-plate">
        <div className="qr-code-container">
          <QRCode
            value={profile?.id}
            size={360}
            level={"H"}
            includeMargin={true}
            bgColor={"transparent"}
            fgColor={"#ffffffff"}
          />
        </div>
        <div className="plate-info">
          <p className="plate-text">{profile?.fullName}</p>
          <p className="plate-date">
            {moment(profile?.birthdate).format("DD/MM/YYYY")} -{" "}
            {moment(profile?.deathdate).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    </>
  );
}
