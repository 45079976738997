import React, { useState, useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { TextInput } from "./TextInput.js";
import { MessageLeft, MessageRight } from "./Message";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "80vw",
      height: "50vh",
      maxHeight: "700px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "auto",
      left: "0",
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )",
    },
  })
);

export default function App(props) {
  const classes = useStyles();
  const [flip, setFlip] = useState(false);
  const ref = useRef(null);

  const scrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });

    // scroll to id with smooth behavior called style-1
    var element = document.getElementById("style-1");
    element.scrollIntoView({ behavior: "smooth" });
    
  };
  useEffect(() => {
    scrollToBottom();
  }, [flip]);

  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  let side = 1;
  if (decoded.role === "admin") side = 0;
  // eslint-disable-next-line
  const [ticket, setTicket] = useState();
  function refresh(flip) {
    setFlip(flip);
  }

  return (
    <>
      <h5 className="mb-4">{ticket?.subject}</h5>
      <Paper className={classes.paper} id="chat" zDepth={2}>
        <Paper className={classes.messagesBody}>
          {props?.ticket &&
            props?.ticket?.messages?.map((message, index) => {
              if (message?.send === side) {
                return (
                  <MessageRight
                    key={index}
                    message={message?.msg}
                    timestamp={moment(message?.timestamp).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                    displayName={message?.sender?.name}
                    photoURL={
                      process.env.REACT_APP_API_IMAGE +
                      "/" +
                      message?.sender?.userimage
                    }
                    avatarDisp={true}
                  />
                );
              } else {
                return (
                  <MessageLeft
                    key={index}
                    message={message?.msg}
                    timestamp={moment(message?.timestamp).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                    displayName={message?.sender?.name}
                    photoURL={
                      process.env.REACT_APP_API_IMAGE +
                      "/" +
                      message?.sender?.userimage
                    }
                    avatarDisp={true}
                  />
                );
              }
            })}
          <div />

          <div id="style-1" ref={ref}></div>
        </Paper>
        <TextInput flip={refresh} />
      </Paper>
    </>
  );
}
