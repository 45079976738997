import React, { Fragment } from "react";
import hero3 from "../assets/img/noExist.jpg";

import {
  Col,
  Row,
  Card,
  Image,
  Container,
} from "@themesberg/react-bootstrap";

import { Link } from "react-router-dom";

import { Routes } from "../routes";
export default function NoProfile() {
  return (
    <Fragment>
      <main 
      // background color
        style={{ backgroundColor: "#323c46" }}
      
      >
        <section className="vh-100 d-flex align-items-center justify-content-center">
          <Container>
            <Row>
              <Col
                xs={12}
                className="text-center d-flex align-items-center justify-content-center"
              >
                {
                    // image cover all screen
                }
                <div>
                    <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                        <Image src={hero3} className="img-fluid w-75" />
                    </Card.Link>
                    </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Fragment>
  );
}
