import React, { useEffect, useState } from "react";

import Search from "./seach";
import jwt_decode from "jwt-decode";

import axios from 'src/utils/axios';import logo from "../../assets/img/logo_colored.png";
import Navbar from "../../components/Navbar";

import "./search.css";

function Home() {
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);

  const [graveyard, setGraveyard] = useState({});

  async function getGraveyard() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/users/getadminbygrave/${decoded?.graveyardId}`
      );
      setGraveyard(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getGraveyard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="home">
        <Navbar />

        <div className="home__body">
          <img
            src={
              graveyard?.userimage
                ? process.env.REACT_APP_API_IMAGE + "/" + graveyard?.userimage
                : logo
            }
            alt="Logo"
          />

          <h4
            style={{
              // allign text to center
              textAlign: "center",
            }}
          >
            {graveyard?.graveyard?.name}
          </h4>

          <div className="home__inputContainer">
            <Search />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
