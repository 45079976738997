import React from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function Result() {
    const location = useLocation();
    const { t } = useTranslation();


    // Accédez à la valeur de 'valid' depuis le state passé via 'history.push'
    const valid = location.state;

    return (
        <>
            <p>{t("Result")}</p>
            <p>{valid}</p>
            <p>{t("You were redirected from ")} {location.state.from}</p>
        </>
    );
}

export default Result;
