import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'src/utils/axios';import {
  Card,
  Toast,
  Image,
  Button,
  ButtonGroup,
  Dropdown,
  Row,
  Col
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";



const InvOut = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const id = props.id;

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/profile/prof/" + id
      );
      setData(response);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDefault, setShowDefault] = useState(true);
  const toggleDefaultToast = () => setShowDefault(!showDefault);



 
  return (
    <>
   
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div className="btn-toolbar mb-2 mb-md-2">
            <ButtonGroup>
              <Dropdown.Toggle
                disabled
                as={Button}
                variant="primary"
                size="sm"
                className="me-2"
              >
                {t("Profile of")} {data.profileName}
              </Dropdown.Toggle>
            </ButtonGroup>
          </div>
          <h5 className="mb-4">{t("Family ties")}</h5>
          <Row>
            {data?.friends?.map((item) => (
              <Col xs={6} md={4}>
                <Toast className="m-2" show={showDefault} onClose={toggleDefaultToast}>
                  <Toast.Header className="text-primary" closeButton={false}>
                    <a href={"/prof/" + item.prof._id}>
                      <Image
                        onClick={() => {
                          history.push("/prof/" + item.prof._id);
                        }}
                        src={
                          process.env.REACT_APP_API_IMAGE +"/" +
                          item?.prof.profileImage
                        }
                        className="user-avatar md-avatar rounded-circle"
                      />
                    </a>
                    <strong className="me-auto ms-2">{t(item?.lien)}</strong>
                  </Toast.Header>
                  <Toast.Body>
                    <div className="text-center">
                      <b>
                        {" "}
                        {item?.prof.profileName} {item?.prof.profileLastName}{" "}
                      </b>
                      <p className="mb-0">
                        {moment(item?.prof.profileDatebirth).format(
                          "DD-MM-YYYY"
                        )}
                        <strong> - </strong>

                        {moment(item?.prof.profileDatedeath).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                  </Toast.Body>
                </Toast>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default InvOut;
