import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function Tags({ data, action, multiple = false }) {
  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      <Autocomplete
        multiple={multiple}
        id="tags-outlined"
        options={data}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Graveyards" placeholder="Graveyards" />
        )}
        onChange={(event, newValue) => {
          action(newValue);
        }}
      />
    </Stack>
  );
}
