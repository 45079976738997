import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import axios from 'src/utils/axios';
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

const Addadmin = () => {
  const { t } = useTranslation();

  const [user, setUser] = useState({});
  let { id } = useParams();
  const history = useHistory();

  async function getGrave() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/" + id
      );
      setUser(response);
    } catch (error) {
      console.error(error.message);
    }
  }
  useEffect(() => {
    getGrave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData] = useState({
    name: user?.name,
    lastn: user?.lastn,
    Datebirth: user?.Datebirth,
    email: user?.email,
    sex: user?.sex,
    userimage: "",
    phone: user?.phone,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function Submit(e) {
    e.preventDefault();
    const mydata = new FormData();
    mydata.append("name", e.target[0].value);
    mydata.append("lastn", e.target[1].value);
    mydata.append("Datebirth", e.target[2].value);
    mydata.append("email", e.target[4].value);
    mydata.append("sex", e.target[3].value);
    mydata.append("phone", e.target[5].value);
    if (formData.userimage !== "") {
      mydata.append("userimage", formData.userimage);
    }

    /*     mydata.append("latitude", position.lat);
    mydata.append("logitude", position.lng); */
    const { data } = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/users/" + id,
      mydata,
      config
    );
    if (data._id) {
      Swal.fire({
        title: t("Success"),
        text: t("User Updated"),
        icon: "success",
        confirmButtonText: t("OK"),
      }).then((result) => {
        if (result.value) {
          history.push("/admin/superadmins");
        }
      });
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("User not Updated"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
    }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="btn-toolbar mb-2 mb-md-2">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.goBack()}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              {t("back")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
        <h5 className="mb-4">
          {t("Update")} {user?.name}
        </h5>

        <h5 className="mb-4">{t("General informations")}</h5>
        <Form onSubmit={(e) => Submit(e)}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.name}
                  type="text"
                  maxLength={50}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.lastn}
                  type="text"
                  maxLength={50}
                  onChange={(e) =>
                    setFormData({ ...formData, lastn: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>{t("date_of_birth")}</Form.Label>
                <Col style={{ padding: 0 }} md={6} className="mb-3">
                  <Form.Group id="birthday">
                    <InputGroup>
                      <Form.Control
                        className="date"
                        name="Datedeath"
                        type="date"
                        // distable future date
                        max={new Date().toISOString().split("T")[0]}
                        defaultValue={user?.Datebirth}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Datebirth: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>{t("gender")}</Form.Label>
                <Form.Select
                  defaultValue={user?.sex}
                  onChange={(e) =>
                    setFormData({ ...formData, sex: e.target.value })
                  }
                >
                  <option selected={user?.sex === "o" ? true : false} value="o">
                    {t("other")}
                  </option>
                  <option selected={user?.sex === "F" ? true : false} value="F">
                    {t("women")}
                  </option>
                  <option selected={user?.sex === "M" ? true : false} value="M">
                    {t("man")}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  defaultValue={user?.email}
                  type="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <PhoneInput
                  country={"ca"}
                  onlyCountries={["us", "ca"]}
                  value={user?.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("image")}</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  placeholder={t("Choose a picture")}
                  onChange={(e) =>
                    setFormData({ ...formData, userimage: e.target.files[0] })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3">
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default Addadmin;
