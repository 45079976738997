import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";

import axios from 'src/utils/axios';import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { right } from "@popperjs/core";

const PlacesAutocomplete = ({ setSelected, setCenter2, setAddress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t } = useTranslation();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    setSelected({ lat, lng });
    setAddress(address);
    setCenter2({ lat, lng });
  };
  const handleValue = (e) => {
    setAddress(e.target.value);
  };
  return (
    <div>
      <Combobox onSelect={handleSelect} onChange={handleValue}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="form-control"
          placeholder={t("Search an address")}
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

const Addadmin = () => {
  const { t } = useTranslation();

  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const [address, setAddress] = useState("");
  const [center2, setCenter2] = useState({ lat: 43.45, lng: -80.49 });
  const [selected, setSelected] = useState({ lat: 43.45, lng: -80.49 });

  const history = useHistory();
  function handleAddress(addr) {
    setAddress(addr);
  }

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    lastn: "",
    Datebirth: new Date(),
    email: "",
    sex: "M",
    password: "",
    confirmPassword: "",
    userimage: "",
    phone: "",
    role: "admin",
    gname: "",
    address: "",
    logitude: "",
    latitude: "",
    funeral_home: "",
    profileImage: "avatar.jpg",
    sub: "",
    plan: "",
    Lat: "",
    Lng: "",
    code: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/graveyard/prices"
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function Submit() {
    const mydata = new FormData();
    if (formData.sub === "") {
      formData.sub = data[0]?._id;
    }

    if (address === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: t("Please select an address"),
      });
      return;
    }
    mydata.append("name", formData.name);
    mydata.append("lastn", formData.lastn);
    mydata.append("Datebirth", formData.Datebirth);
    mydata.append("email", formData.email);
    mydata.append("sex", formData.sex);
    mydata.append("password", formData.password);
    mydata.append("userimage", formData.userimage);
    mydata.append("phone", formData.phone);
    mydata.append("role", formData.role);
    mydata.append("gname", `${formData.gname} ${formData.code}`);
    mydata.append("funeral_home", formData.funeral_home);
    mydata.append("vendor", decoded.userId);
    mydata.append("sub", formData.sub);
    mydata.append("Lat", selected.lat);
    mydata.append("Lng", selected.lng);
    mydata.append("address", address);

    /*     mydata.append("latitude", position.lat);
    mydata.append("logitude", position.lng); */

    Swal.fire({
      title: t("Are you sure you want to add this admin?"),
      showCancelButton: true,
      confirmButtonText: t("yes"),
      showLoaderOnConfirm: true,
      cancelButtonText: t("cancel"),
      preConfirm: async () => {
        return await axios
          .post(
            process.env.REACT_APP_BACKEND_URL + "/users/addadmin",
            mydata,
            config
          )
          .then((result) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("Customer added successfully"),
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/AdminClients");
              }
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(t("email_address_already_exists"));
            Swal.fire({
              position: "center",
              icon: "error",
              title: t("email_address_already_exists"),
              showConfirmButton: true,
            });
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: ["places"],
  });
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="btn-toolbar mb-2 mb-md-2">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.goBack()}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              {t("Retour")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
        <Form>
          <h5 className="my-4">{t("Cemetery Information")} </h5>
          <Row>
            <Col sm={3} className="mb-3">
              <Form.Group id="address">
                <Form.Label>{t("Cemetery Name")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, gname: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            <Col sm={3} className="mb-3">
              <Form.Group id="code">
                <Form.Label>{t("code")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, code: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>{t("address")}</Form.Label>
                <PlacesAutocomplete
                  className="form-control"
                  setSelected={setSelected}
                  setCenter2={setCenter2}
                  setAddress={(addr) => handleAddress(addr)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>{t("Subscription")}</Form.Label>

                <Form.Control
                  as="select"
                  name="searchId"
                  defaultValue={data[0]?._id}
                  onChange={(e) =>
                    setFormData({ ...formData, sub: e.target.value })
                  }
                >
                  {data.map((option) => (
                    <option value={option?._id}>{option?.tag}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h5 className="mb-4">{t("Cemetery responsible information")}</h5>

            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  required
                  maxLength={50}
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control
                  required
                  maxLength={50}
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, lastn: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>{t("gender")}</Form.Label>
                <Form.Select
                  defaultValue="M"
                  onChange={(e) =>
                    setFormData({ ...formData, sex: e.target.value })
                  }
                >
                  <option value="0">{t("other")}</option>
                  <option value="F">{t("women")}</option>
                  <option value="M">{t("man")}</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  required
                  type="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <PhoneInput
                  country={"ca"}
                  onlyCountries={["us", "ca"]}
                  value=""
                  onChange={(value, country, e, formattedValue) => {
                    setFormData({ ...formData, phone: formattedValue });
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("image")}</Form.Label>
                <Form.Control
                  required
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({ ...formData, userimage: e.target.files[0] })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="bg-white shadow-sm mb-4">
            <div style={{width:"50%"}}>
                <h5 className="mb-4">{t("Map")}</h5>
                <GoogleMap
                  zoom={20}
                  center={center2}
                  mapContainerStyle={{height:"50vh"}}
                  mapContainerClassName="map-container"
                  // satellite
                  mapTypeId="satellite"
                >
                  {selected && (
                    <Marker
                      draggable={true}
                      position={selected}
                      onDragEnd={(e) => {
                        setSelected({
                          lat: e.latLng.lat(),
                          lng: e.latLng.lng(),
                        });
                      }}
                    />
                  )}
                </GoogleMap>
            </div>
          </div>
          <div className="mt-3" style={{textAlign:right}}>
            <Button variant="primary" onClick={(e) => Submit()}>
              {t("save")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default Addadmin;
