import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import PhoneInput from "react-phone-input-2";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import axios from 'src/utils/axios';import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const PlacesAutocomplete = ({
  setSelected,
  setCenter2,
  setAddress,
  defaultValue,
}) => {
  const {
    ready,
    value = defaultValue || "",
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t } = useTranslation();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    setSelected({ lat, lng });
    setAddress(address);
    setCenter2({ lat, lng });
  };
  const handleValue = (e) => {
    setAddress(e.target.value);
  };
  return (
    <div>
      <Combobox onSelect={handleSelect} onChange={handleValue}>
        <ComboboxInput
          value={value || defaultValue}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="form-control"
          placeholder={t("Search an address")}
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};
const Addadmin = () => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  // eslint-disable-next-line no-unused-vars
  const [place, setPlace] = useState("");
  const [user, setUser] = useState(null);
  let { id } = useParams();
  const history = useHistory();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  async function getGrave() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/" + id
      );
      response.password = "";
      setUser(response);
      setCenter2({
        lat: parseFloat(response?.graveyard?.Lat),
        lng: parseFloat(response?.graveyard?.Lng),
      });
      setSelected({
        // to int
        lat: parseFloat(response?.graveyard?.Lat),
        lng: parseFloat(response?.graveyard?.Lng),
      });
      setAddress(response?.graveyard?.address);
    } catch (error) {
      console.error(error.message);
    }
  }
  useEffect(() => {
    getGrave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleUpload = (e) => {
    const type = e.target.files[0].type.split("/")[0];
    if (type !== "image") {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Image is required!"),
      });
      setError(true);
      return;
    } else {
      setFormData({ ...formData, userimage: e.target.files[0] });
      setError(false);
    }
  };
  const [address, setAddress] = useState("");
  const [center2, setCenter2] = useState(null);
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    userimage: "",
    role: "admin",
    address: user?.graveyard?.address,
    logitude: "",
    latitude: "",
    funeral_home: "",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function Submit(e) {
    e.preventDefault();
    const mydata = new FormData();
    console.log(selected);
    mydata.append("name", user.name);
    mydata.append("lastn", user.lastn);
    mydata.append("Datebirth", user.Datebirth);
    mydata.append("email", user?.email);
    mydata.append("sex", user?.gender);
    mydata.append("phone", user?.phone);
    mydata.append("role", user?.role);
    mydata.append("gname", user?.graveyard?.name);
    mydata.append("address", address);
    mydata.append("Lat", selected.lat);
    mydata.append("Lng", selected.lng);
    mydata.append("graveyard", user?.graveyard?._id);
    if (error) {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("There is an error with the image!"),
      });
      return;
    }
    if (formData.userimage !== "") {
      // check if image is type image

      mydata.append("userimage", formData.userimage);
    }

    if (user?.phone < 3) {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Phone is required!"),
      });
      return;
    }
    if (user.password !== formData.confirmPassword) {
      Swal.fire({
        title: t("Error"),
        text: t("Password not match"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
      return;
    }
    if (user?.password !== "" && user.password !== formData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Password and Confirm Password must be the same!"),
      });
      return;
    }
    if (user?.password && user?.password !== "") {
      mydata.append("password", user.password);
    }
    /*     mydata.append("latitude", position.lat);
    mydata.append("logitude", position.lng); */
    await axios
      .put(
        process.env.REACT_APP_BACKEND_URL + "/users/editadmin/" + id,
        mydata,
        config
      )
      .then((res) => {
        Swal.fire({
          title: t("Success"),
          text: t("User Updated"),
          icon: "success",
          confirmButtonText: t("OK"),
        }).then((result) => {
          if (result.value) {
            history.push("/AdminClients");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: t("Error"),
          text: t("User not Updated") + " email exists",
          icon: "error",
          confirmButtonText: t("OK"),
        });
      });
  }
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: ["places"],
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="btn-toolbar mb-2 mb-md-2">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.goBack()}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              {t("back")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
        <h5 className="mb-4">
          {t("Edit The Cemetery")} {user?.graveyard?.name}
        </h5>

        <h5 className="mb-4">{t("General informations")}</h5>
        <Form onSubmit={(e) => Submit(e)}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.name}
                  type="text"
                  maxLength={50}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.lastn}
                  type="text"
                  maxLength={50}
                  onChange={(e) => setUser({ ...user, lastn: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  defaultValue={user?.email}
                  type="email"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <PhoneInput
                  autoComplete="new-password"
                  country={"ca"}
                  onlyCountries={["us", "ca"]}
                  value={user?.phone}
                  onChange={(e) => setUser({ ...user, phone: e })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    type={passwordType}
                    autoComplete="new-password"
                    defaultValue=""
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("confirm_your_password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    type={passwordType}
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      })
                    }
                  ></Form.Control>
                </InputGroup>
                {formData.confirmPassword !== user?.password &&
                formData.confirmPassword !== "" ? (
                  <MuiAlert severity="warning">
                    {t("password_does_not_match")}
                  </MuiAlert>
                ) : (
                  <span></span>
                )}
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("image")}</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleUpload(e)}
                  // if there is an error with the image
                  isInvalid={error}
                />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">{t("Cemetery Information")}</h5>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>{t("cemetery_name")}</Form.Label>
                <Form.Control
                  required
                  defaultValue={user?.graveyard?.name}
                  type="text"
                  maxLength={50}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      graveyard: { ...user.graveyard, name: e.target.value },
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Label>{t("Address Cemetery")}</Form.Label>

              <PlacesAutocomplete
                className="form-control"
                setSelected={setSelected}
                setCenter2={setCenter2}
                setAddress={(addr) => setAddress(addr)}
                defaultValue={user?.graveyard?.address}
                required
              />
            </Col>

            {/*    <Col sm={4} className="mb-3">
                <Form.Group id="address">
                  <Form.Label>Maison Funéraire</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Donner votre Maison Funéraire"
                    onChange={(e) =>
                      setFormData({ ...formData, funeral_home: e.target.value })
                    }
                  />
                </Form.Group>
              </Col> */}
          </Row>
          {/*     <Card className="bg-white shadow-sm mb-4">
              <Row>
                <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <DraggableMarker />
                </MapContainer>
              </Row>
            </Card> */}
          <div className="bg-white shadow-sm mb-4">
            <Row>
              <>
                <h5 className="mb-4">{t("Map")}</h5>
                <GoogleMap
                  zoom={20}
                  center={center2}
                  mapContainerClassName="map-container"
                  // satellite
                  mapTypeId="satellite"
                >
                  {selected && (
                    <Marker
                      draggable={true}
                      position={selected}
                      onDragEnd={(e) => {
                        console.log(e.latLng.lat());
                        setSelected({
                          lat: e.latLng.lat(),
                          lng: e.latLng.lng(),
                        });
                      }}
                    />
                  )}
                </GoogleMap>
              </>
            </Row>
          </div>
          <div className="mt-3">
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default Addadmin;
