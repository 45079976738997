import React, { useState, useEffect } from "react";
import {
  faSearch,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Nav,
  Card,
  Table,
  Row,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Dropdown,
  Breadcrumb,
  Modal,
} from "@themesberg/react-bootstrap";
import Pagination from "./pagination";
import "./pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import axios from 'src/utils/axios';import { useTranslation } from "react-i18next";
import { Paper, TableContainer } from "@mui/material";

export default function AllSuperAdmins() {
  const { t } = useTranslation();

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  async function fetchData() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/graveyard/prices"
      );
      setData(response);
    } catch (error) {
      console.error(error.message);
    }
  }

  const [data, setData] = useState([]);
  const [sub, setSub] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
  //console.log(currentPosts);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [editModal, setEditmodal] = useState(false);
  const handleCloseEditModal = () => setEditmodal(false);
  async function handleEdit(sub) {
    setSub(sub);
    setEditmodal(true);
  }

  async function updateSub(e) {
    e.preventDefault();
    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/graveyard/prices/" + sub._id,
        sub
      );
      handleCloseEditModal();
      fetchData();
    } catch (error) {
      console.error(error.message);
    }
  }

  return (
    <>
      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={editModal}
        size="lg"
        onHide={handleCloseEditModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("edit profile")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleCloseEditModal}
          />
        </Modal.Header>
        <Form onSubmit={(e) => updateSub(e)}>
          <Modal.Body>
            <Row>
              <>
                <Col md={6} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>{t("Price")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={50}
                      name="profileName"
                      defaultValue={sub?.price}
                      onChange={(e) =>
                        setSub({
                          ...sub,
                          price: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>{t("Tag")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={50}
                      defaultValue={sub?.tag}
                      name="profileLastName"
                      onChange={(e) =>
                        setSub({
                          ...sub,
                          tag: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("edit")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleCloseEditModal}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          ></Breadcrumb>
          <h4>{t("Subscription plans")}</h4>
          {/*           <p className="mb-0">Your web analytics dashboard template.</p>

 */}
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.push("/addprices")}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              {t("New formula")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder={t("search")} />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <TableContainer component={Paper}>
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{t("Tag")}</th>
                  <th className="border-bottom">{t("formula")}</th>
                  <th className="border-bottom">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts?.map((dm, index) => (
                  <tr>
                    <td>{index + 1}</td>

                    <td>{dm?.tag}</td>
                    <td>{dm?.price}</td>

                    <td>
                      {" "}
                      <Button
                        onClick={() => handleEdit(dm)}
                        size="sm"
                        className="m-1"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        title={t("edit")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-pencil-line"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination
                className="pagination"
                postsPerPage={postsPerPage}
                totalPosts={data.length}
                paginate={paginate}
              />
            </Nav>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
}
