import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";


export default function Expired() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <h1>{t("Link Expired")}</h1>
        <p>
        {t("Sorry, the link you followed has expired. Please request a new link to reset your password.")}
        </p>
      </Grid>
    </Grid>
  );
}
