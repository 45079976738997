import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Modal, useMediaQuery, IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "react-feather";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React, { useState } from "react";

const useStyles = makeStyles(
  () => ({
    bigImageModal: {
      paddingBottom: 40,
      width: "100%",
      height: "100%",
      "@media (max-width: 600px)": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      opacity: 1,
    },
    modalDiv: {
      height: "100%",
      display: "grid",
      gridTemplateAreas: "'. close .''leftIcon image rightIcon'",
      "@media (max-width: 600px)": {
        gridTemplateAreas: "'close close''image image''leftIcon rightIcon'",
      },
    },

    centerImageDiv: {
      maxWidth: 400,
      display: "flex",
      justifyContent: "center",
      width: "200px",
    },

    modalIcon: {
      width: "30px",
      height: "30px",
      borderRadius: "4px",
      background: "#DCDCDC",
      padding: 4,
    },
    leftButtonContainer: {
      gridArea: "leftIcon",
      display: "flex",
      paddingLeft: 100,
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    rightButtonContainer: {
      gridArea: "rightIcon",
      display: "flex",
      paddingRight: 100,
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    imageContainer: {
      gridArea: "image",

      display: "flex",
      justifyContent: "center",
    },
    image: { width: "100%", maxHeight: "80vh", objectFit: "contain" },
  }),
  { index: 1 }
);

const ImageModal = ({ images, open, onClose, defaultIndex = 0 }) => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:600px)");
  const [displayedImageIndex, setDisplayedImageIndex] = useState(defaultIndex);

  /**
   * Handlers
   */
  const handleImageTransition = (transition = 1) => {
    setDisplayedImageIndex((previous) => {
      if (previous + transition < 0) {
        return images.length - 1;
      }
      if (previous + transition > images.length - 1) {
        return 0;
      }
      return previous + transition;
    });
  };

  /**
   * Main render
   */
  return (
    <Modal
      BackdropProps={{
        invisible: isTablet,
      }}
      open={open}
      onClose={onClose}
      className={classes.bigImageModal}
    >
      <div className={classes.modalDiv}>
        <div
          style={{
            gridArea: "close",
            marginLeft: "auto",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon className={classes.modalIcon} />
          </IconButton>
        </div>
        <div className={classes.leftButtonContainer}>
          <IconButton className={classes.iconDiv}>
            <ChevronLeft
              className={classes.modalIcon}
              onClick={() => {
                handleImageTransition(-1);
              }}
            />
          </IconButton>
        </div>
        <div className={classes.imageContainer}>
          {
            // check if images[displayedImageIndex] in an image
            images[displayedImageIndex].split(".").pop() === "mp4" ? (
              <video
                src={images[displayedImageIndex]}
                className={classes.image}
                controls
              />
            ) : (
              <img
                src={images[displayedImageIndex]}
                className={classes.image}
                alt="Item"
              />
            )
          }
        </div>
        <div className={classes.rightButtonContainer}>
          <IconButton className={classes.iconDiv}>
            <ChevronRight
              className={classes.modalIcon}
              onClick={() => {
                handleImageTransition();
              }}
            />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
};

ImageModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultIndex: PropTypes.number,
};

export default ImageModal;
