import React from "react";
import moment from "moment-timezone";
import { Col, Card } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import "./../assets/styles/Footer.css";

function Footer (props) {
  const {centerStyle } = props;

  const { t } = useTranslation();
  return (
    <footer >
<div className={`d-flex row ${centerStyle ? 'centerFooter' : 'positionfooter'}`}>
        <Col id="identity" className="row justify-content-center col-12 col-lg-3 item noMargin noPadding ">
        <Card.Link
            href="http://skiesbook.com"
            target="_blank"
            className="text-decoration-none fw-normal link noPadding col-lg-12 col-12"
          >
            {t("© Skiesbook LLC - ")} {moment().get("year")}
          </Card.Link>
        </Col>
        <Col className="row justify-content-center col-12 col-lg-3 item noMargin noPadding ">
        <Card.Link
            href="/personal-information-policy"
            target="_blank"
            className="text-decoration-none fw-normal link noPadding col-lg-12 col-12"
          >
            {t("Personal information protection policy")}
          </Card.Link>
        </Col>
        <Col className="row justify-content-center col-12 col-lg-2 item   noMargin noPadding ">
        <Card.Link
            href="/privacy-policy"
            target="_blank"
            className=" text-decoration-none fw-normal link noPadding col-lg-9 col-12"
          >
            {t("Privacy Policy")}
          </Card.Link>
        </Col>
      </div>
    </footer>
  );
}

export default Footer;
