import React from "react";

import { useTranslation } from "react-i18next";

import { Avatar, Paper } from "@mui/material";
import jwtDecode from "jwt-decode";
import axios from 'src/utils/axios';import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Card, Row, Col, Button } from "@themesberg/react-bootstrap";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import Swal from "sweetalert2";

export default function MyPlace() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwtDecode(token);

  const [data, setData] = React.useState([]);
  const [center, setCenter] = React.useState({});
  const [profiles, setProfiles] = React.useState([]);
  const [activeMarker, setActiveMarker] = React.useState(null);

  function profiling(profiles, id) {
    setProfiles(profiles);
    if (id === activeMarker) {
      return;
    }
    setActiveMarker(id);
  }

  async function deleteProfilePostition(id) {
    Swal.fire({
      title: t("Delete this profile from this location?"),
      showCancelButton: true,
      confirmButtonText: t(`Delete`),
      denyButtonText: t(`Don't delete`),
    }).then(async (result) => {
      try {
        const { data: response } = await axios.delete(
          process.env.REACT_APP_BACKEND_URL + "/graveyard/deletepos/" + id
        );
        if (response) {
          await getPins();
          setProfiles(profiles.filter((profile) => profile._id !== id));
          Swal.fire({
            icon: "success",
            title: t("Profile deleted from this location"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    });
  }

  function getGraveyard() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/users/" + decoded.userId)
      .then((response) => {
        setCenter({
          lat: parseFloat(response?.data?.graveyard?.Lat),
          lng: parseFloat(response?.data?.graveyard?.Lng),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPins() {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/positions/" +
          decoded.userId
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  React.useEffect(() => {
    getPins();
    getGraveyard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <h5 className="my-4">{t("Locations / Profiles")}</h5>
            </Col>
            <Col className="text-start">
              <div className="mt-3">
                <Row>
                  <Col>
                    <img
                      alt="blue sign"
                      src="https://maps.google.com/mapfiles/ms/icons/blue.png"
                    ></img>{" "}
                    {t('Has at least one profile')}
                  </Col>

                  <Col>
                    <img
                      alt="green sign"
                      src="https://maps.google.com/mapfiles/ms/icons/green.png"
                    ></img>{" "}
                    {t("empty")}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="bg-white shadow-sm mb-4">
            <Row>
              <Col sm={3} className="mb-3">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label={t("a dense table")}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"> {t("image")}</TableCell>
                        <TableCell align="left">{t("name")}</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    {profiles?.length > 0 ? (
                      <>
                        <TableBody>
                          {profiles?.map((prof) => (
                            <TableRow
                              key={prof._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                <Avatar
                                  onClick={() => {
                                    window.open(
                                      "/prof/" + prof?._id,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                  src={
                                    process.env.REACT_APP_API_IMAGE +
                                    "/" +
                                    prof?.image
                                  }
                                  alt="photo"
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <h6
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(
                                      "/prof/" + prof?._id,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  {prof?.name} {prof?.lastn}
                                </h6>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  onClick={() => {
                                    deleteProfilePostition(prof._id);
                                  }}
                                  size="sm"
                                  className="m-1"
                                  style={{
                                    backgroundColor: "transparent",
                                    borderColor: "transparent",
                                  }}
                                  title={t("delete")}
                                  //disabled={profiles.length === 1}
                                  
                                >
                                  <i
                                    style={{ color: "black" }}
                                    className="ri-delete-bin-line"
                                  ></i>
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      t("No profiles at this location")
                    )}
                  </Table>
                </TableContainer>
              </Col>
              <Col sm={9} className="mb-3">
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                    // satellite map with labels
                    mapTypeId="hybrid"
                  >
                    {data.map((item) => {
                      return (
                        <Marker
                          key={item.code}
                          icon={{
                            url:
                              item?.profiles?.length > 0
                                ? "https://maps.google.com/mapfiles/ms/icons/blue.png"
                                : "https://maps.google.com/mapfiles/ms/icons/green.png",
                            scaledSize: new window.google.maps.Size(30, 30),
                          }}
                          position={{
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                          }}
                          onClick={() => {
                            profiling(item.profiles, item.code);
                          }}
                        >
                          {activeMarker === item?.code ? (
                            <InfoWindow
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <>
                                <div>{item?.code}</div>

                                {profiles?.length > 0 ? (
                                  <TableBody>
                                    {profiles?.map((prof) => (
                                      <TableRow
                                        key={prof._id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                        >
                                          <Avatar
                                            onClick={() => {
                                              window.open(
                                                "/prof/" + prof?._id,
                                                "_blank",
                                                "noopener,noreferrer"
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                            src={
                                              process.env.REACT_APP_API_IMAGE +
                                              "/" +
                                              prof?.image
                                            }
                                            alt="photo"
                                          />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <h6
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              window.open(
                                                "/prof/" + prof?._id,
                                                "_blank",
                                                "noopener,noreferrer"
                                              );
                                            }}
                                          >
                                            {prof?.name} {prof?.lastn}
                                          </h6>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                ) : (
                                  t("No profiles at this location")
                                )}
                              </>
                            </InfoWindow>
                          ) : null}
                        </Marker>
                      );
                    })}
                  </GoogleMap>
                </LoadScript>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
