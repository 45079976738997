import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import jwt_decode from "jwt-decode";
import axios from 'src/utils/axios';import { useTranslation } from "react-i18next";

function SearchForProfile({ popupIcon, onChange, error, helperText, value,textValue }) {
  const [localValue, setLocalvalue] = useState([]);
  const [options, setOptions] = useState([]);
  const getOptionLabel = (option) => option.fullName;
  const { t } = useTranslation();


  const textFieldOnChange = (event) => {
    textValue(event.target.value);
    setLocalvalue(event.target.value);

  };

  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);

  const getProfiles = async (fullName) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/profile/searchprofiles/${decoded?.graveyardId}?fullname=${fullName}`
    );
    return response.data;
  };

  useEffect(() => {
    getProfiles(localValue).then((res) => {
      setOptions(res);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  const autocompleteStyles = {
    inputRoot: {
      color: "inherit",
      width: "100%",
      borderRadius: "40px",
    },
  };

  return (
    <>
      <Autocomplete
        popupIcon={popupIcon}
        handleHomeEndKeys
        onChange={(changedValue, event, reason, details) =>
          onChange(event, changedValue, reason, details)
        }
        freeSolo={true}
        id="free-solo-with-text-demo"
        // style like google search
        options={options}
        style={{
          width: 450,
          margin: "auto",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              maxLength: 1,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <div style={autocompleteStyles.endAdornment}></div>
                </>
              ),
              style: autocompleteStyles.inputRoot,
            }}
            placeholder={t("Search for profiles")}
            required
            variant="outlined"
            error={error}
            helperText={helperText}
            value={value}
            name="fullName"
            onChange={textFieldOnChange}
          />
        )}
      />
    </>
  );
}

export default SearchForProfile;
