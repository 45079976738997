import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";

import axios from 'src/utils/axios';import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { faPlus } from "@fortawesome/fontawesome-free-solid";

const Addadmin = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const [marker, setMarker] = React.useState({
    lat: 45.424721,
    lng: -75.695,
  });
  const [code, setCode] = useState("");
  const containerStyle = {
    width: "100%",
    height: "500px",
  };
  const [center, setCenter] = React.useState({
    lat: 45.424721,
    lng: -75.695,
  });
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const [alert, setAlert] = useState(false);

  const [emplacements, setEmplacements] = useState([]);

  const getPositions = async () => {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/positions/" +
          decoded.userId
      );
      setEmplacements(response);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/getAdmin/" + decoded.userId
      );
      setData(response);
      setCenter({
        lat: parseFloat(response.graveyard.Lat),
        lng: parseFloat(response.graveyard.Lng),
      });
      setMarker({
        lat: parseFloat(response.graveyard.Lat),
        lng: parseFloat(response.graveyard.Lng),
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getPositions();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function settingCord(e) {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }

  async function submit(e) {
    e.preventDefault();
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/addposition/" +
          data.graveyard._id,
        {
          lat: marker.lat,
          lng: marker.lng,
          code: code,
        }
      )
      .then((res) => {
        if (res) {
          setAlert(true);
          setMarker({
            lat: marker.lat + 0.00001,
            lng: marker.lng + 0.00001,
          });
          setCode("");
          getPositions();
        } else {
          Swal.fire({
            icon: "error",
            title: t("Sorry!"),
            text: t("No location"),
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: t("Sorry!"),
          text: t("Location code already exist!"),
        });
      });
    setTimeout(() => {
      setAlert(false);
    }, 2500);
  }

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        {alert ? (
          <Alert
            style={{ position: "absolute", top: 0, right: 0, width: "200px" }}
            className="mt-3"
            severity="success"
          >
            {t("Location Added")}
          </Alert>
        ) : (
          <></>
        )}
        <Card.Body>
          <h5 className="my-4">{t("locations")}</h5>
          <Form onSubmit={submit}>
            <Row>
              <Col md={4} className="mb-3">
                <Form.Group id="Lat">
                  <Form.Label>{t("Latitude")}</Form.Label>
                  <Form.Control
                    disabled
                    required
                    type="text"
                    placeholder={t("Latitude")}
                    value={marker.lat}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group id="Lng">
                  <Form.Label>{t("Longitude")}</Form.Label>
                  <Form.Control
                    disabled
                    required
                    type="text"
                    placeholder={t("Longitude")}
                    value={marker.lng}
                  />
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="code">
                  <Form.Label>{t("Code")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={t("Code")}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={2}>
                <Form.Label>{t("add")}</Form.Label>
                <Button variant="primary" className="w-100" type="submit">
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="bg-white shadow-sm mb-4">
            <Row>
              <Col sm={6} className="mb-3">
                {data?.graveyard?.plan ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <ReactPanZoom
                      image={
                        process.env.REACT_APP_API_IMAGE +
                        "/" +
                        data?.graveyard?.plan
                      }
                      alt={t("plan")}
                    />
                  </div>
                ) : (
                  <>
                    <h5 className="mb-4">{t("You don't have a plan yet")}</h5>
                  </>
                )}
              </Col>
              <Col sm={6} className="mb-3">
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={19}
                    // satellite map with labels
                    mapTypeId="hybrid"
                  >
                    {emplacements.map((empl) => (
                      <Marker
                        key={empl._id}
                        position={{
                          lat: parseFloat(empl.lat),
                          lng: parseFloat(empl.lng),
                        }}
                        icon={{
                          url: "https://cdn-icons-png.flaticon.com/512/149/149059.png",
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      />
                    ))}

                    <Marker
                      position={marker}
                      draggable={true}
                      setZIndex={100}
                      // this marker above all markers
                      zIndex={100}
                      onDragEnd={(e) => {
                        settingCord(e);
                      }}
                    />
                  </GoogleMap>
                </LoadScript>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default Addadmin;
