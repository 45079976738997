import React, { useState, useEffect, useRef } from "react";
import {
  faArrowLeft,
  faChartLine,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Card,
  Table,
  Breadcrumb,
  Row,
  Button,
  ButtonGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import axios from 'src/utils/axios';import TextField from "@material-ui/core/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { CounterWidget } from "../../components/Widgets";
import logo_colored from "../../assets/img/logo_colored.png";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { Paper, TableContainer } from "@mui/material";
import { CSVLink } from "react-csv";

export default function AllProfiles() {
  const history = useHistory();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const [locale, setLocale] = React.useState(currentLanguageCode);

  useEffect(() => {
    setLocale(currentLanguageCode);
  }, [currentLanguageCode]);

  const d = new Date();
  const { t } = useTranslation();

  const [formdata, setFormdata] = useState({
    startDate: moment(d).format("YYYY-MM"),
    endDate: moment(d).format("YYYY-MM-DD"),
  });
  async function gofilter() {
    fetchData();
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const id = useParams().id;
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);

  const fetchData = async () => {
    try {
      const { data: response } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/getfiltredprofiles/" + id,
        formdata
      );
      setData(response);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/getadminbygrave/" + id
      );
      setUser(response);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchUser();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headers = [
    { label: "_id", key: "_id" },
    { label: "Name", key: "profileName" },
    { label: "Last Name", key: "profileLastName" },
    { label: "Created_At", key: "createdAt" },
    { label: "Dead_At", key: "profileDatedeath" },
  ];
  const csvReport = {
    data: data,
    headers: headers,
    filename:
      user?.graveyard?.name +
      " " +
      formdata.startDate +
      "-" +
      formdata.endDate +
      ".csv",
  };
  return (
    <>
      <div className="btn-toolbar mb-2 mb-md-2">
        <ButtonGroup>
          <Dropdown.Toggle
            onClick={(e) => history.goBack()}
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
            {t("Retour")}
          </Dropdown.Toggle>
        </ButtonGroup>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          ></Breadcrumb>
          <h4>{t("list_of_profiles")}</h4>
          {/*           <p className="mb-0">Your web analytics dashboard template.</p>

 */}
          {/*           
    from date to date range
   

  

 */}
        </div>
      </div>
      <div className="d-block mb-4 mb-md-2">
        <Card border="light" className="shadow-sm m-3 p-3">
          <Row className="align-items-center">
            <h5>{t("Filter by date")}</h5>
            <Col md={3} className="mb-3">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <Stack spacing={3}>
                  <DatePicker
                    disableFuture
                    label={t("from_date")}
                    // inputFormat="dd/MM/yyyy"
                    value={formdata.startDate}
                    openTo="day"
                    views={["year", "month", "day"]}
                    onChange={(e) => {
                      setFormdata({ ...formdata, startDate: e });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Col>

            <Col md={3} className="mb-3">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <Stack spacing={3}>
                  <DatePicker
                    disableFuture
                    // inputFormat="dd/MM/yyyy"
                    label={t("until")}
                    value={formdata.endDate}
                    openTo="day"
                    views={["year", "month", "day"]}
                    onChange={(e) => {
                      setFormdata({ ...formdata, endDate: e });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Col>
            <Col>
              <Button onClick={(e) => gofilter()}> {t("Apply")}</Button>
            </Col>
            <Col>
              <Button onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} /> {t("invoice")}
              </Button>
            </Col>
            <Col>
              <Button variant="secondary" size="sm">
                <CSVLink {...csvReport}>{t("Export to CSV")}</CSVLink>
              </Button>
            </Col>

            <Col md={3}>
              <CounterWidget
                category={t("number_of_profiles")}
                title={data?.length}
                icon={faChartLine}
              />
            </Col>
          </Row>
        </Card>
      </div>

      <Card border="light" className="table-wrapper shadow-sm">
        <Card.Body className="p-0">
          <TableContainer component={Paper}>
            <Table className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">{t("First and Last Name")}</th>
                  <th className="border-0">{t("Profile Creation Date")}</th>
                  <th className="border-0">{t("date_of_death")}</th>
                  <th className="border-0">{t("Contact Information")}</th>
                  <th className="border-0">{t("reference_email")}</th>
                  <th className="border-0">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((dm, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td className="fw-bold">
                      {dm?.profileName} {dm?.profileLastName}
                    </td>
                    <td>{moment(dm?.createdAt).format("YYYY-MM-DD")}</td>
                    <td>{moment(dm?.profileDatedeath).format("YYYY-MM-DD")}</td>
                    <td>{dm?.cords}</td>
                    <td>{dm?.profileEmail}</td>
                    <td>
                      {" "}
                      <Button
                        onClick={() => history.push("/prof/" + dm._id)}
                        size="sm"
                        className="m-1"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        title={t("View")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-eye-line"
                        ></i>
                      </Button>
                      <Button
                        onClick={() => history.push("/editprof/" + dm._id)}
                        size="sm"
                        color="secondary"
                        className="m-1"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        title={t("Edit")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-pencil-line"
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          {/* <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>Précédent</Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>Suivant</Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Affichage de <b>{2}</b> sur <b>25</b> entrées
            </small>
          </Card.Footer> */}
        </Card.Body>
      </Card>
      <>
        <Card
          hidden
          border="light"
          className="bg-white shadow-sm mb-4 all-center"
        >
          <Card.Body>
            <div className="card" ref={componentRef}>
              <div className="card-header bg-black" />
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <img height={"100px"} alt="logo" src={logo_colored} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <ul className="list-unstyled float-start">
                        <li style={{ fontSize: 30, color: "blue" }}>
                          Skiesbook inc.
                        </li>
                        <li>123, Elm Street</li>
                        <li>123-456-789</li>
                        <li>mail@mail.com</li>
                      </ul>
                      <ul className="list-unstyled float-end">
                        <li style={{ fontSize: 30, color: "Black" }}>
                          {user?.graveyard?.name}
                        </li>
                        <li>{user?.graveyard?.address}</li>
                        <li>{user?.phone}</li>
                        <li>{user?.email}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row text-center">
                    <h3
                      className="text-uppercase text-center mt-3"
                      style={{ fontSize: 40 }}
                    >
                      {t("Invoice")}
                    </h3>
                    <p>{user?._id}</p>
                  </div>
                  <Card border="light" className="table-wrapper shadow-sm">
                    <Card.Body className="">
                      <TableContainer component={Paper}>
                        <Table className="table-centered">
                          <thead className="thead-light">
                            <tr>
                              <th className="border-0">#</th>
                              <th className="border-0">{t("First and Last Name")}</th>
                              <th className="border-0">
                              {t("Profile Creation Date")}
                              </th>
                              <th className="border-0">{t("date_of_death")}</th>
                              <th className="border-0">{t("reference_email")}</th>
                              <th className="border-0">{t("price")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((dm, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td className="fw-bold">
                                  {dm?.profileName} {dm?.profileLastName}
                                </td>
                                <td>
                                  {moment(dm?.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {moment(dm?.profileDatedeath).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>{dm?.profileEmail}</td>
                                <td> 20 $ </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </TableContainer>
                    </Card.Body>
                  </Card>
                  <div className="row">
                    <div className="col-xl-8">
                      <ul className="list-unstyled me-0  float-end">
                        <li>
                          <span className="me-3">t{("Total Amount")}:</span>
                          <i className="fas fa-dollar-sign" />{" "}
                          {data?.length * 20} $
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-xl-8" style={{ marginLeft: 60 }}>
                      <p
                        className=""
                        style={{
                          fontSize: 30,
                          color: "blue",
                          fontWeight: 400,
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        {t("Total")}:
                        <span>
                          <i className="fas fa-dollar-sign" />{" "}
                          {data?.length * 20} $
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-5">
                    <p className="fw-bold">
                      Date:{" "}
                      <span className="text-muted">
                        {" "}
                        {moment(d).format("YYYY/MM/DD")}
                      </span>
                    </p>
                    <p className="fw-bold mt-3">{t("Signature:")}</p>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-black" />
            </div>
          </Card.Body>
        </Card>
      </>
    </>
  );
}
