import React from "react";
import { useTranslation } from "react-i18next";

const PersonalInformationPolicyEn = () => {
  return (
    <div>
      <h1
        style={{
          fontSize: "2.5rem",
          fontWeight: "700",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        Protection of personal information policy
      </h1>
      <div className="row justify-content-center">
        <p className="col-7 text-left">
          This policy aims to ensure the protection of personal information and
          to define the procedures for collecting, using, disclosing, retaining,
          destroying and managing information by Skiesbook LLC, which includes
          management, employees, suppliers, etc. Moreover, it aims to inform
          anyone concerned about how their personal information is processed by
          Skiesbook LLC, whether it be customers, employees, or any other
          individuals.
        </p>

        <h2 className="policytitle col-7 text-left">RESPONSIBILITY</h2>
        <p className="col-7 text-left">
          Skiesbook LLC assumes full responsibility for the protection of
          personal information under its control. Information collected, used,
          disclosed, retained, or destroyed is governed by this policy in order
          to protect the privacy of every individual.
        </p>
        <p className="col-7 text-left">
          To ensure the optimal protection of personal information, Skiesbook
          LLC’s Privacy Officer shall:
          <p />
          <ul>
            <li>
              Oversee and review internal practices and procedures for
              processing personal information as well as compliance with current
              laws;
            </li>
            <li>
              Suggest measures to ensure ongoing protection of personal
              information in line with Privacy Impact Assessments;
            </li>
            <li>
              Implement necessary measures within the business to ensure the
              protection of information;
            </li>
            <li>
              Ensure staff compliance and training in best practices for
              protecting personal information.
            </li>
            <li>
              Coordinate, investigate, and respond to inquiries and complaints
              about personal information protection;
            </li>
            <li>
              Communicate with the concerned individual(s) and the Access to
              Information Commission (CAI) in case of a data leak or any
              incident;
            </li>
            <li>Keep a record of personal data-related incidents.</li>
          </ul>
          <br />
          The protection of personal information is everyone’s business. No
          retaliation can be made against an individual who files a complaint
          about the protection of personal information or participates in a CAI
          investigation procedure.
        </p>
        <h2 className="policytitle col-7 text-left">
          COLLECTION OF PERSONAL INFORMATION
        </h2>
        <p className="col-7 text-left">
          Personal information collected allows Skiesbook LLC to carry out its
          functions and activities in accordance with applicable laws and
          standards. Skiesbook LLC collects personal information only when
          necessary and to serve specific, predefined purposes. Personal
          information is collected directly from the concerned individual and
          with their consent unless an exception is provided for by law.
          <br />
          <br />
          A non-exhaustive list of the information collected and its intended
          use is provided in Appendix A. The majority of personal information
          collected pertains to employees in order to meet the business’s legal
          obligations. Personal information about other individuals may be
          requested in order to assist employees in case of emergency, for
          example. It is up to the employees to obtain their consent before
          providing us with their contact details.
          <br />
          <br />
          As far as customer information is concerned, data is provided to feed
          our CRM, contracts, and invoicing, but it is mostly information of a
          professional or business nature such as email and phone numbers for
          contact or payment method for rendered services. Payment information
          is, as often as possible, entered by the customer into the CRM and is
          masked for the rest of the business employees to ensure
          confidentiality. For clients who have filled out a form including
          their credit card or their business or professional bank account
          number, the data is accessible only to a small number of employees
          such as administration and owners to process files.
        </p>
        <h2 className="policytitle col-7 text-left">
          CONSENT AND ACCURACY OF PERSONAL INFORMATION
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC ensures that the collection of personal information is
          done for justified, clear, and specific reasons and with the free and
          informed consent of the person. Consent is required for any
          collection, use, or disclosure of personal information. Before
          collecting personal information, we will ensure that we obtain your
          informed consent in a clear and separate written form, providing clear
          details about the purpose of the collection and how the information
          will be used. Your consent is essential to ensure the protection of
          your personal data.
        </p>

        <h2 className="policytitle col-7 text-left">
          LIMITATION ON THE USE OF PERSONAL INFORMATION
        </h2>
        <p className="col-7 text-left">
          We collect and use your personal information only when necessary and
          for the purposes for which consent was obtained. Skiesbook LLC must
          provide certain information in order to meet the legal and regulatory
          verification processes and requirements. The use may vary but could
          serve different purposes as illustrated in Appendix A.
        </p>
        <br />
        <p className="col-7 text-left">
          LInformation may be transmitted to third parties to the extent
          necessary for the purposes of the activities mentioned in Appendix A.
          Skiesbook LLC cannot be held responsible for the behavior and usage
          undertaken by third parties.
        </p>
        <br />
        <p className="col-7 text-left">
          Personal information will not be used or disclosed for other purposes
          than for specific objectives, unless required by law.
        </p>
        <h2 className="policytitle col-7 text-left">
          PROTECTION OF YOUR PERSONAL INFORMATIONS
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC takes all reasonable precautions and has implemented
          significant physical and technical measures to prevent unauthorized or
          illegal use of, and access to, personal information. The measures in
          place include, among others:{" "}
        </p>
        <p className="col-7 text-left">
          <ul>
            <li>Use of information only when necessary ;</li>
            <li>
              Ensure the confidentiality and protection of personal information
              that someone may have learned in the course of their duties,
              unless authorized to disclose it by the person concerned ;
            </li>
            <li>
              Protection files with selective and limited access to authorized
              persons ;
            </li>
            <li>
              Secure access to offices with locked doors and access codes ;
            </li>
            <li>Secure shredding of paper files ;</li>
            <li>Two-factor authentication for all platform connections;</li>
            <li>
              Immediate withdrawal of access following the end of a business
              relationship.
            </li>
          </ul>
        </p>
        <p className="col-7 text-left">
          All individuals are required to contribute to the protection of
          personal information. If you suspect that sensitive information has
          been compromised, you must immediately notify the Privacy Officer.
        </p>
        <h2 className="policytitle col-7 text-left">
          RETENTION PERIOD FOR YOUR PERSONAL INFORMATION
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC undertakes to comply with the minimum retention periods
          provided by the category of personal information and applicable laws.
          However, if the information collected is no longer useful to Skiesbook
          LLC and its retention is not necessary or mandatory according to
          different legislative frameworks, it will be destroyed, erased, or
          converted in such a way as to remain anonymous.
        </p>
        <h2 className="policytitle col-7 text-left">
          COMMITMENT TO TRANSPARENCY
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC is committed to being transparent about the processing,
          procedures, and purposes for which personal information is used with
          customers, employees, interns and business partners.
        </p>
        <h2 className="policytitle col-7 text-left">
          ACCESS TO YOUR PERSONAL INFORMATION
        </h2>
        <p className="col-7 text-left">
          A person may request access to his or her personal information and the
          means by which it was collected. Depending on the content of the
          person’s file, exceptions may be applicable, such as personal
          information about a third party, however, the person will be informed.
          In case of inaccurate information in the file, the person concerned
          may request its correction.
          <br />
          <br />
          For any consultation, withdrawal, and/or modification of personal
          information, please write to info@Skiesbook LLC.com. At any time, you
          can withdraw your consent to the communication of your personal
          information. A written request must be submitted to the Privacy
          Officer at info@Skiesbook LLC.com. A response will be provided to you
          within 30 days of receipt. When it is not possible to share the
          requested information, legal justification and support must be
          provided to back up the decision to the requester.
        </p>
        <h2 className="policytitle col-7 text-left">COMPLAINTS</h2>
        <p className="col-7 text-left">
          A person who believes that their personal information has been
          collected, retained, used, disclosed, or destroyed in a way that is
          not in accordance with the provisions of this policy, may file a
          confidential complaint with the Privacy Officer at info@Skiesbook
          LLC.com. The individual must provide their name, contact details,
          including a phone number, and the subject and grounds for the
          complaint. It is necessary to provide sufficient details for the
          complaint to be properly assessed. A response will be provided within
          30 days following the date of receipt of the complaint. If the
          complaint is insufficiently specific, the Privacy Officer may request
          any additional information deemed necessary to evaluate the complaint.
          The Privacy Officer will conduct an investigation into the received
          complaints, minimize any possible damage, and make the necessary
          corrections.
        </p>
        <p className="col-7 text-left">
          You may also file a complaint with the Commission for Access to
          Information. However, Skiesbook LLC encourages concerned individuals
          to first communicate with the Privacy Officer and wait for the
          conclusion of the planned management process.
        </p>
        <h2 className="policytitle col-7 text-left">APPROVAL</h2>
        <p className="col-7 text-left">
          <b>This policy is approved by the Privacy Officer at Prosomo.</b>
          <br></br>
          <br></br>
          <b>Privacy Officer:</b>
          <br></br>
          <p>
            7901 4TH ST N STE 300 ST. PETERSBURG, FL 33702<br></br>
          </p>
          <a href="mailto:info@skiesbook.com">info@skiesbook.com </a>
          <br />
          <br />
          For all requests, questions or comments relating to this policy,
          please contact the person responsible by email.
        </p>
        <h2 className="policytitle col-7 text-left">Appendix A</h2>
        <table id="policyTable" className="policytitle col-7 text-left">
          <thead className="tablePolicies">
            <th>Concerned individuals</th>
            <th>Information categories</th>
            <th>Information types</th>
            <th>Purposes for which information is retained</th>
          </thead>
          <tbody>
            <tr>
              <td className="blueBorder">Employees</td>
              <td className="blueBorder">Recruitment</td>
              <td className="blueBorder" vertical-align="top">
                Recruitment information, such as curriculum vitae, educational
                and professional background, details of previous employers to
                verify employment for potential recruitment.
              </td>
              <td className="blueBorder">
                Internal management (resume evaluation)
              </td>
            </tr>
            <tr>
              <td className="blueBorder"></td>
              <td className="blueBorder">Staffing</td>
              <td className="blueBorder">
                Information to be included in the employee file, such as first
                and last name, contact details, SIN, salary, bank details,
                employment or internship contract, emergency contacts, etc.
              </td>
              <td className="blueBorder">
                Internal management (example : payroll, operations, legal
                obligations, pay equity, performance review, etc.
              </td>
            </tr>
            <tr>
              <td className="blueBorder">Customers and suppliers</td>
              <td className="blueBorder">
                Accounting, CRM and project management systems
              </td>
              <td className="blueBorder">
                Details of services requested and/or provided.Billing and
                financial information, such as a billing address, bank account
                information or payment details.
              </td>
              <td className="blueBorder">
                Internal management (IT services, cybersecurity, billing,
                project management, communication, information collection as
                part of a program, contracts, service agreements, etc.)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PersonalInformationPolicyFr = () => {
  return (
    <div>
      <h1
        style={{
          fontSize: "2.5rem",
          fontWeight: "700",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        Politique de protection des renseignements personnels
      </h1>
      <div className="row justify-content-center">
        <p className="col-7 text-left">
          L’objectif principal de cette politique est d’assurer la protection
          des informations personnelles tout en établissant des directives
          claires pour la collecte, l’utilisation, la communication, la
          conservation, la destruction et la gestion des données au sein de
          Skiesbook LLC. Cela concerne l’ensemble des parties prenantes, y
          compris la direction, les employés, les fournisseurs, etc. De plus,
          cette politique a pour but d’informer toute personne concernée, qu’il
          s’agisse de clients, d’employés ou d’autres individus, sur la manière
          dont Skiesbook LLC traite leurs informations personnelles.
        </p>

        <h2 className="policytitle col-7 text-left">RESPONSABILITÉ</h2>
        <p className="col-7 text-left">
          Skiesbook LLC assume pleinement la responsabilité de la préservation
          des renseignements personnels sous sa juridiction. Les données
          collectées, utilisées, communiquées, conservées ou détruites sont
          soumises aux dispositions de cette politique dans le but premier de
          préserver la confidentialité de chaque individu. Pour garantir la
          protection la plus efficace des renseignements personnels, le
          responsable de la sécurité des données au sein de Skiesbook LLC doit :
          <br />
          <ul>
            <li>
              Superviser et examiner les pratiques et les procédures internes de
              traitement des renseignements personnels ainsi que le respect des
              lois en cours;
            </li>
            <li>
              Suggérer des mesures afin d’assurer la protection continue des
              renseignements personnels qui concordent avec les évaluations des
              facteurs relatifs à la vie privée;
            </li>
            <li>
              Mettre en œuvre les mesures nécessaires au sein de l’entreprise
              pour assurer la protection des renseignements;
            </li>
            <li>
              Assurer la conformité et la formation du personnel en matière de
              bonnes pratiques pour protéger les renseignements personnels.
            </li>
            <li>
              Coordonner, enquêter et répondre aux demandes et aux plaintes
              relatives à la protection des renseignements personnels;
            </li>
            <li>
              Communiquer avec la ou les personnes concernées et la Commission
              d’accès à l’information (CAI) en présence d’une fuite de données
              ou tout incident;
            </li>
            <li>
              Tenir un registre des incidents relatifs aux données personnelles.
            </li>
          </ul>
          <br />
          La protection des renseignements personnels est l’affaire de tous.
          Aucunes représailles ne peuvent être faites envers un individu qui
          dépose une plainte relative à la protection des renseignements
          personnels ou participe à une procédure d’enquête de la CAI.
        </p>
        <h2 className="policytitle col-7 text-left">
          COLLECTE DES RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Les renseignements personnels recueillis permettent l’exercice des
          fonctions de Skiesbook LLC et la conduite de ses activités
          conformément aux lois et normes applicables. Skiesbook LLC procède à
          la collecte des renseignements personnels seulement si elle est
          nécessaire et pour répondre à des fins spécifiques et définies
          préalablement. La collecte des renseignements personnels est faite
          directement auprès de la personne concernée et avec son consentement,
          sauf si une exception est prévue par la loi.
          <br />
          <br />
          Vous trouverez en Annexe A, une liste non exhaustive des
          renseignements collectés et l’intention d’utilisation des données. La
          majorité des informations personnelles recueillies concernent les
          employés pour répondre aux obligations légales de l’entreprise. La
          communication de renseignements personnels d’autres individus peut
          être demandée afin d’assister des employés en cas d’urgence par
          exemple. Il appartient aux employés d’obtenir leur consentement avant
          de nous transmettre leurs coordonnées.
          <br />
          <br />
          En ce qui concerne les informations des clients, des données sont
          fournies pour alimenter nos dossiers, nos logiciels de gestion, les
          contrats et la facturation. Nous accordons une importance capitale à
          la confidentialité et à la sécurité des données de nos clients. Toutes
          les informations recueillies, qu’il s’agisse de coordonnées ou
          d’autres informations personnelles, sont traitées avec la plus grande
          rigueur et conformément aux lois et réglementations en vigueur en
          matière de protection des renseignements personnels. Notre équipe
          s’engage à mettre en place des mesures de sécurité robustes pour
          empêcher tout accès non autorisé, ainsi qu’à former régulièrement
          notre personnel sur les meilleures pratiques en matière de
          confidentialité des données. Nous considérons la protection des
          renseignements personnels de nos clients comme une responsabilité
          fondamentale pour garantir leur bien-être et leur confiance en nos
          services.
        </p>
        <h2 className="policytitle col-7 text-left">
          CONSENTEMENT ET EXACTITUDE DES RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC s’assure que le recueil des renseignements personnels
          est fait pour des raisons justifiées, claires et spécifiques et avec
          l’obtention du consentement libre et éclairé de la personne. Le
          consentement est requis pour toute collecte, utilisation ou
          divulgation de renseignements personnels. Avant de recueillir des
          informations personnelles, nous veillerons à obtenir votre
          consentement éclairé de façon écrite et distincte, en vous fournissant
          des détails clairs sur la finalité de la collecte et sur la manière
          dont les informations seront utilisées. Votre consentement est
          essentiel pour assurer la protection de vos données personnelles.
        </p>

        <h2 className="policytitle col-7 text-left">
          LIMITE DE L’UTILISATION DES RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Nous recueillons et utilisons vos renseignements personnels que
          lorsqu’il est nécessaire et aux fins pour lesquelles le consentement a
          été obtenu. Skiesbook LLC doit fournir certains renseignements afin de
          satisfaire aux processus et exigences légales et réglementaires en
          matière de vérification. L’utilisation peut varier, mais pourrait
          notamment servir à différentes fins comme illustrées en annexe A.
        </p>
        <br />
        <p className="col-7 text-left">
          Les informations peuvent être transmises à des tiers dans la mesure
          nécessaire aux fins des activités mentionnées en annexe A. Skiesbook
          LLC ne peut être tenu responsable du comportement et de l’usage
          entrepris par les tiers.
        </p>
        <br />
        <p className="col-7 text-left">
          Les renseignements personnels ne seront pas utilisés ou communiqués à
          d’autres fins que pour des objectifs précis à moins que la loi ne
          l’exige.
        </p>
        <h2 className="policytitle col-7 text-left">
          PROTECTION DE VOS RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC prend toutes les précautions raisonnables et met en
          place des mesures physiques et techniques importantes de façon à
          prévenir l’utilisation et l’accès non autorisé ou illégal aux
          renseignements personnels. Ainsi, les mesures en place sont, entre
          autres:
          <br />
          <ul>
            <li>Utilisation des renseignements que lorsque nécessaire;</li>
            <li>
              Assurer la confidentialité et la protection d’un renseignement
              personnel dont une personne aurait pris connaissance dans le cadre
              de ses fonctions, à moins d’être autorisée à en faire la
              divulgation par la personne concernée.
            </li>
            <li>
              Protection des dossiers avec un accès sélectif et limité aux
              personnes autorisées;
            </li>
            <li>
              Sécurisation des accès aux bureaux avec le verrouillage des portes
              et codes d’accès;
            </li>
            <li>Déchiquetage sécuritaire des dossiers papier;</li>
            <li>
              Double authentification dans toutes les connexions sur les
              plateformes;
            </li>
            <li>
              Retrait immédiat des accès suite à la fin d’une relation
              d’affaires.
            </li>
          </ul>
          <br />
          <br />
          Tous les individus sont tenus de contribuer à la protection des
          renseignements personnels. Si vous soupçonnez que des informations
          sensibles ont été compromises, vous devez immédiatement aviser le
          responsable de la protection des renseignements personnels.
        </p>
        <h2 className="policytitle col-7 text-left">
          DURÉE DE CONSERVATION DE VOS RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC s’engage à respecter les délais minimaux de conservation
          prévus selon la catégorie de renseignements personnels et les lois
          applicables. Cependant, si les informations recueillies ne sont plus
          utiles pour Skiesbook LLC et que leur conservation n’est pas
          nécessaire ni obligatoire selon les différents cadres législatifs,
          elles seront détruites, effacées ou converties de façon à conserver un
          caractère anonyme.
        </p>
        <h2 className="policytitle col-7 text-left">
          ENGAGEMENT À LA TRANSPARENCE
        </h2>
        <p className="col-7 text-left">
          Skiesbook LLC s’engage à être transparent face au traitement, aux
          procédures et aux fins d’utilisation qui encadrent les renseignements
          personnels auprès des clients, des employés, des stagiaires et des
          partenaires d’affaires.
        </p>
        <h2 className="policytitle col-7 text-left">
          ACCÈS À VOS RENSEIGNEMENTS PERSONNELS
        </h2>
        <p className="col-7 text-left">
          Une personne peut demander un accès aux renseignements personnels qui
          la concernent et les moyens qui ont été utilisés pour en faire la
          collecte. Selon le contenu du dossier de la personne, des exceptions
          pourraient être applicables, telles que des informations personnelles
          portant sur autrui, cependant la personne en sera informée. En cas
          d’information(s) inexacte(s) au dossier, la personne concernée peut en
          demander la correction.
          <br />
          <br />
          Pour toute consultation, retrait et/ou modification des renseignements
          personnels, vous pouvez écrire à l’adresse courriel
          info@skiesbook.com. En tout temps, vous pouvez retirer votre
          consentement à la communication de vos renseignements personnels. Une
          demande écrite devra être soumise au responsable de la protection des
          renseignements personnels au info@skiesbook.com. Une réponse vous sera
          fournie dans les 30 jours suivant la date de sa réception. Lorsqu’il
          n’est pas possible de partager l’information demandée, une
          justification légale et un soutien devront être fournis pour appuyer
          la décision auprès du requérant.
        </p>
        <h2 className="policytitle col-7 text-left">PORTER PLAINTE</h2>
        <p className="col-7 text-left">
          Une personne qui croit que leurs renseignements personnels ont été
          recueillis, retenus, utilisés, divulgués ou détruits de façon non
          conforme aux dispositions de la présente politique, peut formuler une
          plainte confidentielle auprès du responsable de la protection des
          renseignements personnels à l’adresse courriel info@skiesbook.com.
          L’individu devra fournir son nom, ses coordonnées, y compris un numéro
          de téléphone, ainsi que l’objet et les motifs de la plainte. Il est
          nécessaire de fournir des détails suffisants pour que la plainte
          puisse être évaluée convenablement. Une réponse vous sera fournie dans
          les 30 jours suivant la date de la réception de la plainte. Si la
          plainte est insuffisamment précise, le responsable de la protection
          des renseignements personnels peut demander toute information
          supplémentaire jugée nécessaire pour évaluer la plainte. Le
          responsable va conduire une enquête sur les plaintes reçues, minimiser
          les dommages et apporter les correctifs nécessaires.
          <br />
          <br />
          Il est également possible de déposer une plainte auprès de la
          Commission d’accès à l’information du Québec. Toutefois, Skiesbook LLC
          encourage les personnes concernées à communiquer d’abord avec le
          responsable de la protection des renseignements personnels et à
          attendre la conclusion du processus de traitement prévu.
        </p>
        <h2 className="policytitle col-7 text-left">APPROBATION</h2>
        <p className="col-7 text-left">
          La présente politique est approuvée par le responsable de la
          protection des renseignements personnels au sein de Skiesbook LLC.
          <br />
          <br />
          <b>Responsable de la protection des renseignements personnels:</b>
          7901 4TH ST N STE 300 ST. PETERSBURG, FL 33702
          <br />
          <br />
          info@skiesbook.com
          <br />
          <br />
          Pour toute demande, question ou commentaire dans le cadre de la
          présente politique, veuillez communiquer avec le responsable par
          courriel.
        </p>
        <h2 className="policytitle col-7 text-left">ANNEXE A</h2>
        <table id="policyTable" className="policytitle col-7 text-left">
          <thead className="tablePolicies">
            <th>Personnes concernées</th>
            <th>Catégories des renseignements</th>
            <th>Types de renseignements</th>
            <th>Fins pour lesquelles les renseignements sont conservés</th>
          </thead>
          <tbody>
            <tr>
              <td className="blueBorder">Employés</td>
              <td className="blueBorder">Dotation</td>
              <td className="blueBorder">
                Renseignements relatifs au recrutement,comme un curriculum
                vitae, des renseignements sur le parcours scolaire et
                professionnelle, des détails concernant les employeurs
                précédents pour la vérification d’emploi pour un recrutement
                potentiel.
              </td>
              <td className="blueBorder">
                Gestion interne (évaluation des candidatures)
              </td>
            </tr>
            <tr>
              <td className="blueBorder"></td>
              <td className="blueBorder">Embauche (Employés et stages)</td>
              <td className="blueBorder">
                Renseignements relatifs à constituer au dossier employé comme le
                nom et prénom, les coordonnées, le CIN, la rémunération, les
                informations bancaires, le contrat de travail ou de stage, les
                contacts d’urgence, etc.
              </td>
              <td className="blueBorder">
                Gestion interne (fonctionnement de la paie, obligations légales,
                Équité salariale, gestion de la performance, etc.)
              </td>
            </tr>
            <tr>
              <td className="blueBorder">Clients et fournisseurs</td>
              <td className="blueBorder">
                Système de gestion comptabl e, CRM et gestion de projets
              </td>
              <td className="blueBorder">
                Renseignements relatifs à des services demandés et/ou rendus.
                Renseignements relatifs à la facturation et des renseignements
                financiers, comme une adresse de facturation, de l’information
                relative à un compte bancaire ou des données de paiement.
              </td>
              <td className="blueBorder">
                Gestion interne (services informatiques, cybersécurité,
                facturation, gestion des projets, gestion de la communication,
                recueille d’informations dans le cadre d’un programme, contrat
                et, entente de service)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PersonalInformationPolicy = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      {i18n.language === "fr" ? (
        <PersonalInformationPolicyFr />
      ) : (
        <PersonalInformationPolicyEn />
      )}
    </div>
  );
};

export default PersonalInformationPolicy;
