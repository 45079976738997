import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { login } from "src/utils/axios";

import Swal from "sweetalert2";
import logo_colored from "../../assets/img/logo_colored.png";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useLoadScript } from "@react-google-maps/api";

const PlacesAutocomplete = ({ setSelected, setCenter2, setAddress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t } = useTranslation();
  const handleSelect = async (address) => {
    setValue(address, false);

    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    setSelected({ lat, lng });
    setAddress(address);
    setCenter2({ lat, lng });
  };

  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="form-control"
          placeholder={t("Search an address")}
        />
        <ComboboxPopover style={{ zIndex: 9999999999 }}>
          <ComboboxList style={{ zIndex: 9999999999 }}>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default function Signin() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: ["places"],
  });
  const history = useHistory();
  const [myForm, setMyForm] = useState({
    email: "",
    password: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    lastn: "",
    email: "",
    address: "",
    phone: "",
    country: "Canada",
    graveyardName: "",
    ville: "",
    region: "",
    zip: "",
  });
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [addr, setAddr] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setShow(false);
  async function submit(e) {
    myForm.email = myForm.email.toLocaleLowerCase();
    e.preventDefault();
    await login(myForm.email, myForm.password)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          if (response?.data.role === "superadmin") {
            history.go("/AdminClients");
          } else if (response?.data.role === "admin") {
            history.go("/adminclients");
          } else if (response?.data.role === "client") {
            history.go("/myProfiles");
          } else if (response?.data.role === "gstaff") {
            history.go("/Staffclient");
          } else if (response?.data.role === "gadmin") {
            history.go("/Staffclient");
          } else if (response?.data.role === "gcompta") {
            history.go("/adminclients");
          } else if (response?.data.role === "sales") {
            history.go("/myProfiles");
          } else if (response?.data.role === "help") {
            history.go("/myProfiles");
          } else if (response?.data.role === "sadmin") {
            history.go("/myProfiles");
          } else if (response?.data.role === "search") {
            history.go("/search");
          } else if (response?.data.role === "funHomeAdmin") {
            history.go("/profiles");
          } else if (response?.data.role === "funSales") {
            history.go("/profiles");
          }
        } else {
          Swal.fire({
            title: t("wrong_credentials"),
            text: t("try_enter_valid_mail_or_password"),
            icon: "error",
            confirmButtonText: t("cool_i'll_try_again"),
          });
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: t("wrong_credentials"),
          text: t("try_enter_valid_mail_or_password"),
          icon: "error",
          confirmButtonText: t("cool_i'll_try_again"),
        });
      });
  }

  function submitB2b(e) {
    e.preventDefault();

    if (
      formData.phone === "14747474747" ||
      formData.phone === "" ||
      formData.address === ""
    ) {
      Swal.fire({
        title: t("Missing information"),
        text: t("Try to fill all the fields"),
        icon: "error",
        confirmButtonText: t("Ok"),
      });
    } else {
      formData.address = addr;
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/request", formData)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: t("Request sent"),
              text: t("We will contact you soon"),
              icon: "success",
              confirmButtonText: t("Ok"),
            });
            handleClose();
          }
        });
    }
  }

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <main>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Form
          onSubmit={(e) => {
            submitB2b(e);
          }}
        >
          <Modal.Header style={{ borderColor: "transparent" }} closeButton>
            <Modal.Title>{t("become_a_partner")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <h5 className="mb-2">{t("Information")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    name="profileName"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>{t("firstname")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    name="profileLastName"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        lastn: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("email")}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="profileName"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>{t("phone")}</Form.Label>
                  <PhoneInput
                    required
                    country={"ca"}
                    onlyCountries={["us", "ca"]}
                    onChange={(e) => setFormData({ ...formData, phone: e })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h5 className="mb-2">Cimetière </h5>

            <Row className="align-items-center">
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("cemetery_name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    name="cim name"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        graveyardName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="address">
                  <Form.Label>{t("address")}</Form.Label>
                  <PlacesAutocomplete
                    className="form-control"
                    setAddress={setAddr}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ borderColor: "transparent" }}>
            <Button variant="secondary" onClick={handleClose}>
              {t("close")}
            </Button>
            <Button type="submit" variant="primary">
              {t("save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
        <Container>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className=" object-center text-center text-md-center mb-4 mt-md-0 pt-2"
              >
                <img alt="logo" src={logo_colored} width="20%" />
              </div>
              <div className="flex items-center justify-center pb-3">
                <h3 className="mb-0">{t("sign_in_skiesbook")}</h3>
              </div>
              <Form onSubmit={(e) => submit(e)}>
                <Form.Group id="email" className="mb-4">
                  <Form.Label>{t("email")}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) =>
                        setMyForm({ ...myForm, email: e.target.value })
                      }
                      autoFocus
                      required
                      type="email"
                      placeholder="example@email.com"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>{t("your_password")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) =>
                          setMyForm({ ...myForm, password: e.target.value })
                        }
                        required
                        type={showPassword ? "text" : "password"}
                        placeholder={t("password")}
                      />
                      <InputGroup.Text
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          borderRight: "0.0625rem solid #d1d7e0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Form.Check style={{ marginLeft: "20px" }} type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" />
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                        {t("remember_me")}
                      </FormCheck.Label>
                    </Form.Check>
                    <Card.Link
                      className="small text-end"
                      onClick={() => history.push("/forgot-password")}
                    >
                      {t("forgot_password")}
                    </Card.Link>
                  </div>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  {t("login")}
                </Button>
              </Form>
              <div className="mt-2 mb-2">
                {/* eslint-disable-next-line */}
                <a
                  style={{ color: "#0E63C0" }}
                  onClick={() => history.push("/b2b")}
                >
                  {t("become_a_partner")}
                </a>
              </div>
              {/*}
                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-twitter me-2"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pil text-dark"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
                */}
            </div>
          </Col>
        </Container>
      </section>
    </main>
  );
}
