import React, { useState, useEffect } from "react";
import { Col, Card, Collapse, Form } from "@themesberg/react-bootstrap";

import "./../../assets/styles/Style.css";

const CookieConsentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [openStatistiques, setOpenStatistiques] = useState(false);
  const [openFonctionnel, setOpenFonctionnel] = useState(false);
  const [openMarketing, setOpenMarketing] = useState(false);
  const [openPreferences, setOpenPreferences] = useState(false);
  const [toggleSwitchFonctionnel, setToggleSwitchFonctionnel] = useState(true);
  const [toggleSwitchStatistiques, setToggleSwitchStatistiques] =
    useState(false);
  const [toggleSwitchMarketing, setToggleSwitchMarketing] = useState(false);

  useEffect(() => {
    // Vérifier la valeur de cookieConsent dans localStorage lors du chargement de la page
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    // Stocker le consentement dans localStorage
    localStorage.setItem("cookieConsent", "true");
    setShowPopup(false);
  };

  const refuseCookies = () => {
    // Logique pour refuser les cookies
    setShowPopup(false);
  };

  return (
    <div
      className={
        showPopup
          ? "cookie-consent-popup col-xl-4 col-sm-6 col-12 "
          : "cookie-consent-popup hidden"
      }
    >
      <div className="mb-2" style={{ textAlign: "center" }}>
        Nous utilisons des témoins (cookies) pour améliorer votre expérience de
        navigation, diffuser des publicités ou des contenus personnlisés et
        analyser notre trafic. En cliquant sur « Accepter », vous consentez a
        notre utilisation des cookies.
      </div>
      <Collapse in={openPreferences}>
        <div>
          <div
            className="mb-2 "
            style={{
              padding: "5px",
              border: "1px solid #edf0f4",
              borderRadius: " 5px",
              background: "#edf0f4",
            }}
          >
            <div className="d-flex mb-1 w-100" style={{ cursor: "pointer" }}>
              <div
                onClick={() => setOpenFonctionnel(!openFonctionnel)}
                className="col-10"
              >
                {" "}
                Fonctionnel{" "}
              </div>
              <Form.Check
                type="switch"
                id="Fonctionnel-switch"
                className="justify-content-end col-1"
                checked={toggleSwitchFonctionnel}
                onChange={() =>
                  setToggleSwitchFonctionnel(toggleSwitchFonctionnel)
                }
                disabled
                style={{ cursor: "pointer" }}
              />
            </div>
            <Collapse in={openFonctionnel}>
              <div style={{ fontSize: "13px", padding: "10px" }}>
                Le stockage technique ou l'accès est strictement nécessaire dans
                le but légitime de permettre l'utilisation d'un service
                spécifique explicitement demandé par l'abonné ou l'utilisateur,
                ou dans le seul but d'effectuer la transmission d'une
                communication sur un réseau de communications électroniques.
              </div>
            </Collapse>
          </div>

          <div
            className="mb-2 "
            style={{
              padding: "5px",
              border: "1px solid #edf0f4",
              borderRadius: " 5px",
              background: "#edf0f4",
            }}
          >
            <div className="d-flex mb-1 w-100" style={{ cursor: "pointer" }}>
              <div
                onClick={() => setOpenStatistiques(!openStatistiques)}
                className="col-10"
              >
                {" "}
                Statistiques{" "}
              </div>
              <Form.Check
                type="switch"
                id="Statistiques-switch"
                className="justify-content-end col-1"
                checked={toggleSwitchStatistiques}
                onChange={() =>
                  setToggleSwitchStatistiques(!toggleSwitchStatistiques)
                }
                style={{ cursor: "pointer" }}
              />
            </div>
            <Collapse in={openStatistiques}>
              <div style={{ fontSize: "13px", padding: "10px" }}>
                Le stockage ou l'accès technique utilisé exclusivement à des
                fins statistiques.
              </div>
            </Collapse>
          </div>

          <div
            className="mb-2 "
            style={{
              padding: "5px",
              border: "1px solid #edf0f4",
              borderRadius: " 5px",
              background: "#edf0f4",
            }}
          >
            <div className="d-flex mb-1 w-100" style={{ cursor: "pointer" }}>
              <div
                onClick={() => setOpenMarketing(!openMarketing)}
                className="col-10"
              >
                Marketing
              </div>
              <Form.Check
                type="switch"
                id="Marketing-switch"
                className="justify-content-end col-1"
                checked={toggleSwitchMarketing}
                onChange={() =>
                  setToggleSwitchMarketing(!toggleSwitchMarketing)
                }
                style={{ cursor: "pointer" }}
              />
            </div>
            <Collapse in={openMarketing}>
              <div style={{ fontSize: "13px", padding: "10px" }}>
                Le stockage ou l'accès technique est nécessaire pour créer des
                profils d'utilisateurs afin d'envoyer de la publicité, ou pour
                suivre l'utilisateur sur un site web ou sur plusieurs sites web
                à des fins de marketing similaires.
              </div>
            </Collapse>
          </div>
        </div>
      </Collapse>
      <div style={{ textAlign: "center"}} className="mb-3">
        <button
          className="btn btn-primary col-xl-3 col-sm-6 col-12 mr-1 mb-3"
          onClick={acceptCookies}
        >
          Accepter
        </button>
        <button
          className="btn btn-light col-xl-3 col-sm-6 col-12 mr-1 mb-3"
          onClick={refuseCookies}
        >
          Refuser
        </button>
        <button
          className="btn btn-light col-xl-5 col-sm-6 col-12 mb-3"
          onClick={() => setOpenPreferences(!openPreferences)}
        >
          Voir les préférences
        </button>
      </div>
      <div
        id="link-Cookies"
        style={{
          display: "flex",
          borderTop: "1px solid #eaedf2",
          paddingTop: "3px",
        }}
      >
        <Col className="row justify-content-center col-12 col-lg-4 item">
          <Card.Link
            href="/privacy-policy"
            target="_blank"
            className="text-blue text-decoration-none fw-normal link "
          >
            Privacy Policy
          </Card.Link>
        </Col>
        <Col className="row justify-content-center col-12 col-lg-8 item ">
          <Card.Link
            href="/personal-information-policy"
            target="_blank"
            className="text-blue text-decoration-none fw-normal link "
          >
            Personal information protection policy
          </Card.Link>
        </Col>
      </div>
    </div>
  );
};

export default CookieConsentPopup;
