import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Function to handle login and set token in localStorage
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, { email, password });
    localStorage.setItem("email", JSON.stringify(response?.data.email));
    localStorage.setItem("token", JSON.stringify(response?.data.idToken));
    return response;
  } catch (error) {
    throw new Error('Login failed');
  }
};

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token.replace(/['"]+/g, '')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
