import React, { useState, useEffect, useRef } from "react";
import { faBookReader, faSearch } from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Nav,
  Card,
  Table,
  // Pagination,
  Breadcrumb,
  Form,
  InputGroup,
  Button,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import Pagination from "./pagination";
import "./pagination.css";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import axios from "src/utils/axios";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import cookies from "js-cookie";
import MetalPlate from "./metalPlate/index.js";
import { Paper, TableContainer } from "@material-ui/core";
import html2canvas from "html2canvas";
import { Table as TableBoost } from "@themesberg/react-bootstrap";
import { Autocomplete, Avatar } from "@mui/material";

const token = JSON.parse(localStorage.getItem("token"));
let decoded = null;
if (token !== null) decoded = jwt_decode(token);

export default function AllProfiles() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = React.useState(currentLanguageCode);
  const [hasAccess, setHasAccess] = React.useState(false);

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const fetchData = async (fetch) => {
    try {
      let indexOfLastPost = currentPage * postsPerPage;
      let indexOfFirstPost = indexOfLastPost - postsPerPage;
      if (decoded.role === "admin" || decoded.role === "gadmin") {
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL +
              "/users/getprofiles/" +
              decoded?.graveyardId
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      } else if (decoded?.role === "sales") {
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL +
              "/profile/staffAdminProfiles/" +
              decoded?.userId
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      } else if (decoded?.role === "gstaff") {
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL +
              "/profile/staffgetprofiles/" +
              decoded?.userId
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      } else if (decoded?.role === "funHomeAdmin") {
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL + "/funHome/profiles"
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      } else if (decoded?.role === "funSales") {
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL + "/funHome/profiles"
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      } else {
        setHasAccess(true);
        if (fetch) {
          const { data: response } = await axios.get(
            process.env.REACT_APP_BACKEND_URL + "/profile"
          );
          setData(response);
          setPData(response.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          setPostsPerPage(10);
          setPData(data.slice(indexOfFirstPost, indexOfLastPost));
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  async function handlePosition(pos) {
    const position = emplacement?.find((e) => e.code === pos);
    setProfile({
      ...profile,
      position: position,
    });
  }
  const [word, setWord] = useState("");

  useEffect(() => {
    if (data.length === 0) fetchData(true);
    if (word !== "") search(word);
    else fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, word]);

  async function search(word) {
    if (word === "") fetchData(false);
    let indexOfLastPost = currentPage * postsPerPage;
    let indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    if (word === "") {
      setPData(currentPosts);
    } else {
      searchKeyword(word);
    }
  }

  const [pData, setPData] = useState([]);
  const [profile, setProfile] = useState({
    profileName: "",
    profileLastName: "",
    profileDatebirth: null,
    profileDatedeath: null,
    gender: "F",
    cords: "",
    modeDeath: "I",
    hometown: "",
    client: "",
    vendor: "",
    alive: false,
    position: {},
  });

  async function searchKeyword(w) {
    w = w.replace(/\s/g, "");
    const filtredData = data.filter(
      (item) =>
        w?.toUpperCase()?.includes(item?.profileName?.toUpperCase()) ||
        w?.toUpperCase()?.includes(item?.profileLastName?.toUpperCase()) ||
        item?.profileName?.toUpperCase()?.includes(w?.toUpperCase()) ||
        item?.profileLastName?.toUpperCase()?.includes(w?.toUpperCase()) ||
        w?.toUpperCase()?.includes(item?.graveyard?.name?.toUpperCase()) ||
        item?.graveyard?.name?.toUpperCase()?.includes(w?.toUpperCase()) ||
        w?.toUpperCase()?.includes(item?.position?.code?.toUpperCase()) ||
        item?.position?.code?.toUpperCase()?.includes(w?.toUpperCase())
    );
    setPData(filtredData);
  }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [alive, setAlive] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [emplacement, setEmplacement] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(new Date(moment().format("YYYY-MM-DD")));

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [printProfile, setPrintProfile] = useState(null);
  const [showDefault2, setShowDefault2] = useState(false);
  const handleClose2 = () => {
    setShowDefault2(false);
    setPrintProfile(null);
  };

  // function printProfileModal(fullName, birthdate, deathDate, id) {
  //   setPrintProfile({
  //     fullName,
  //     birthdate,
  //     deathDate,
  //     id,
  //   });
  //   setShowDefault2(true);
  // }
  const screenShotRef = useRef(null);
  const downloadQRCode = () => {
    html2canvas(screenShotRef.current)
      .then((canvas) => {
        // Convert canvas to a data URL
        const dataUrl = canvas.toDataURL();
        // Create a temporary link and trigger a download
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "screenshot.png";
        link.click();
      })
      .catch((error) => {
        console.error(t("Error capturing screenshot:"), error);
      });
  };

  const [grave, setGrave] = useState({});

  async function handleEdit(profile) {
    setProfile(profile);
    if (profile.profileDatedeath) setAlive(false);
    const idGrave = profile?.graveyard?._id || profile?.graveyard;
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/graveyard/getemplacementsgrave/" +
          idGrave
      )
      .then((response) => {
        setEmplacement(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/graveyard/" + idGrave
      );
      setGrave(response);
    } catch (error) {
      console.error(error.message);
    }

    setShowDefault(true);
  }
  async function changeStatus(status) {
    setAlive(status);
    setProfile({ ...profile, profileDatedeath: null });
  }

  const [matrix, setMatrix] = React.useState([]);
  const [collabModal, setCollabModal] = React.useState(false);

  const handleCloseModal = () => {
    setCollabModal(false);
    setMatrix([]);
  };

  function handleCollab(val) {
    setProfile({
      ...profile,
      collab: val,
      collabValues: val?.values,
      collabValue: null,
      position: val?.position,
    });
  }

  const addNicheAsValue = (i, j) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    let alphabetCode = alphabet[i];
    const nicheCode = alphabetCode + (j + 1);
    const niche = {
      [alphabetCode]: j + 1,
      value: nicheCode,
    };
    profile.collabValue = niche;
    setProfile(profile);
    handleCloseModal();
  };

  const handleCollabModal = (collab) => {
    const myCollab = grave?.collabs?.find((c) => c._id === collab._id);
    setMatrix(myCollab?.data);
    setCollabModal(true);
  };

  async function updateProfile(e) {
    e.preventDefault();
    try {
      Swal.fire({
        title: t("Are you sure?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: t("cancel"),
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, update it!"),
        preConfirm: async () => {
          return await axios
            .put(
              process.env.REACT_APP_BACKEND_URL + "/profile/" + profile._id,
              profile
            )
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: t("Profile updated!"),
                showConfirmButton: false,
                timer: 1500,
              });
              fetchData(true);
              handleClose();
            })
            .catch(() => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: t("An error occurred"),
                showConfirmButton: false,
                timer: 1500,
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    } catch (error) {
      console.error(error.message);
    }
  }
  function filterByDate() {
    // get date from datepicker dateFrom
    // get date from datepicker dateTo

    if (dateFrom === null || dateTo === null || dateFrom > dateTo) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: t("Please select a date"),
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    } else {
      const filtredData = data.filter(
        (item) =>
          moment(item?.createdAt).format("YYYY-MM-DD") >=
            moment(new Date(dateFrom)).format("YYYY-MM-DD") &&
          moment(item?.createdAt).format("YYYY-MM-DD") <=
            moment(new Date(dateTo)).format("YYYY-MM-DD")
      );
      setPData(filtredData);
      if (filtredData.length !== 0) {
        setPostsPerPage(500);
      }
    }
  }
  return (
    <>
      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={showDefault}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {t("edit profile")}{" "}
            <div
              style={{
                fontSize: "12px",
                color: "gray",
              }}
            >
              {profile?.vendor && (
                <>
                  {" "}
                  {t("added by ")} {profile?.vendor?.name}{" "}
                  {profile?.vendor?.lastn}{" "}
                </>
              )}
              {profile?.funHome && (
                <>
                  {t("via")} {profile?.funHome?.name}
                </>
              )}
            </div>
          </Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleClose}
          />
        </Modal.Header>
        <Form onSubmit={(e) => updateProfile(e)}>
          <Modal.Body>
            <Row>
              {decoded.role === "superadmin" && (
                <>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>{t("lastname")}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        maxLength={50}
                        name="profileName"
                        defaultValue={profile?.profileName}
                        onChange={(e) =>
                          setProfile({
                            ...profile,
                            profileName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>{t("firstname")}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        maxLength={50}
                        defaultValue={profile?.profileLastName}
                        name="profileLastName"
                        onChange={(e) =>
                          setProfile({
                            ...profile,
                            profileLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Row className="align-items-center">
              <Col md={4} className="mb-3">
                <Form.Group id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        disableFuture
                        disabled={!hasAccess}
                        value={profile?.profileDatebirth}
                        label={t("date_of_birth")}
                        openTo="year"
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setProfile({ ...profile, profileDatebirth: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="checkbox">
                  <Form.Check
                    type="checkbox"
                    disabled={!hasAccess}
                    checked={!alive}
                    label={t("Deceased")}
                    onChange={(e) => changeStatus(!alive)}
                  />
                </Form.Group>
              </Col>

              <Col md={4} className="mb-3">
                <Form.Group hidden={alive} id="birthday">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        // inputFormat="dd/MM/yyyy"
                        disableFuture
                        openTo="year"
                        disabled={!hasAccess}
                        label={t("date_of_death")}
                        value={profile?.profileDatedeath}
                        views={["year", "month", "day"]}
                        onChange={(e) =>
                          setProfile({ ...profile, profileDatedeath: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>{t("gender")}</Form.Label>
                  <Form.Select
                    defaultValue={profile?.gender}
                    name="gender"
                    disabled={!hasAccess}
                    onChange={(e) =>
                      setProfile({ ...profile, gender: e.target.value })
                    }
                  >
                    <option value="F">{t("women")}</option>
                    <option value="M">{t("man")}</option>
                    <option value="I">{t("other")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="funérailles">
                  <Form.Label>{t("Funeral arrangements")}</Form.Label>
                  <Form.Select
                    disabled={!hasAccess}
                    defaultValue={profile?.modeDeath}
                    name="modeDeath"
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        modeDeath: e.target.value,
                        collab: null,
                        collabValue: null,
                        position: null,
                      })
                    }
                  >
                    <option value="I">{t("Burial")}</option>
                    <option value="C">{t("Columbarium")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>{t("reference_email")}</Form.Label>
                  <Form.Control
                    required
                    name="profileEmail"
                    type="email"
                    onChange={(e) =>
                      setProfile({ ...profile, profileEmail: e.target.value })
                    }
                    defaultValue={profile?.profileEmail}
                  />
                </Form.Group>
              </Col>
              {profile?.modeDeath === "I" ? (
                <Col md={6} className="mb-3">
                  <Form.Group id="location">
                    <Form.Label>{t("location")}</Form.Label>
                    <Autocomplete
                      id="position"
                      options={emplacement.map((option) => option?.code)}
                      onChange={(e, value) => {
                        handlePosition(value);
                      }}
                      disabled={!hasAccess}
                      value={profile?.position?.code}
                      renderInput={(params) => (
                        <TextField {...params} label={t("location")} />
                      )}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <>
                  <Col md={4} className="mb-3">
                    <Form.Group id="Columbarium">
                      <Form.Label>{t("Columbarium")}</Form.Label>
                      {profile?.collab ? (
                        // eye icon to see the columbarium
                        <i
                          style={{
                            color: "black",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          className="ri-eye-line"
                          onClick={() => {
                            handleCollabModal(profile?.collab);
                          }}
                        ></i>
                      ) : (
                        <> </>
                      )}
                      <Autocomplete
                        id="position"
                        disabled={!hasAccess}
                        options={grave?.collabs}
                        disableClearable
                        value={profile?.collab}
                        getOptionLabel={(option) => option?.name}
                        onChange={(e, value) => handleCollab(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group id="Niche">
                      <Form.Label>{t("Niche")}</Form.Label>
                      <Autocomplete
                        disabled={!hasAccess}
                        id="position"
                        options={
                          profile?.collabValues || profile?.collab?.values
                        }
                        getOptionLabel={(option) =>
                          option?.value?.toUpperCase()
                        }
                        value={profile?.collabValue}
                        onChange={(e, value) =>
                          setProfile({ ...profile, collabValue: value })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("edit")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        as={Modal.Dialog}
        show={showDefault2}
        onHide={handleClose2}
        widht="100%"
        fullscreen
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Print profile")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleClose}
          />
        </Modal.Header>
        <div ref={screenShotRef}>
          <MetalPlate profile={printProfile}></MetalPlate>
        </div>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={downloadQRCode}>
            {t("print")}{" "}
          </Button>
          <Button
            variant="secondary"
            className="text-gray ms-auto"
            onClick={handleClose2}
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={collabModal}
        size="xl"
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Select a niche")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => console(e)}>
            <Row>
              <Col xs={12} className="mb-3">
                <Card.Body className="p-0">
                  <TableContainer component={Paper}>
                    <TableBoost hover className="user-table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="border-2">
                            #
                          </th>
                          {
                            // number of columns
                          }
                          {matrix[0]?.map((col, i) => {
                            return (
                              <>
                                <th scope="col" className="border-2">
                                  {i + 1}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {matrix.map((row, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="border-2">
                                  {
                                    // alphabet
                                  }
                                  {String.fromCharCode(65 + i)}
                                </td>
                                {row.map((col, j) => {
                                  return (
                                    <>
                                      <td
                                        key={j}
                                        className={matrix[i][j] ? "active" : ""}
                                      >
                                        {matrix[i][j].length > 0 ? (
                                          // if matrix[i][j] has only one element
                                          matrix[i][j].length === 1 ? (
                                            <div
                                              className="periodic-element just-trying"
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                              data-description={
                                                matrix[i][j][0]?.profileName +
                                                " " +
                                                // if col.profileLastName is undefined don't show it
                                                (matrix[i][j][0]
                                                  ?.profileLastName || "")
                                              }
                                            >
                                              <span
                                                style={{
                                                  color: "black",
                                                  float: "right",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  hasAccess
                                                    ? addNicheAsValue(i, j)
                                                    : Swal.fire({
                                                        position: "center",
                                                        icon: "error",
                                                        title: t(
                                                          "You don't have access"
                                                        ),
                                                        showConfirmButton: false,
                                                        timer: 1500,
                                                      });
                                                }}
                                              >
                                                <i className="ri-add-fill" />{" "}
                                              </span>
                                              <div className="periodic-element-inner">
                                                <Avatar
                                                  style={{
                                                    marginLeft: "10px",
                                                  }}
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_IMAGE +
                                                    "/" +
                                                    matrix[i][j][0]
                                                      ?.profileImage
                                                  }
                                                />
                                                {
                                                  // span in the middle of the element
                                                }
                                                <div
                                                  style={{
                                                    width: "90px",
                                                    whiteSpace: "normal",
                                                  }}
                                                >
                                                  {matrix[i][j][0]?.profileName}{" "}
                                                  {
                                                    matrix[i][j][0]
                                                      ?.profileLastName?.[0]
                                                  }
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                width: "100px",
                                                height: "80px",
                                                // blue background color
                                                background: "#323c46",
                                                // all elements are in the center
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                            >
                                              <div
                                                // circle with number of elements
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  borderRadius: "50%",
                                                  background: "#2c343d",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  color: "white",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                {matrix[i][j].length}
                                              </div>
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            style={{
                                              width: "100px",
                                              height: "80px",
                                              border: "2px solid #323c46",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // circle with the add icon
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                background: "#2c343d",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "white",
                                                fontSize: "20px",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                hasAccess
                                                  ? addNicheAsValue(i, j)
                                                  : Swal.fire({
                                                      position: "center",
                                                      icon: "error",
                                                      title: t(
                                                        "You don't have access"
                                                      ),
                                                      showConfirmButton: false,
                                                      timer: 1500,
                                                    });
                                              }}
                                            >
                                              <i className="ri-add-fill" />
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </TableBoost>
                  </TableContainer>
                </Card.Body>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          ></Breadcrumb>
          <h4>{t("Profiles")}</h4>
          {/*           <p className="mb-0">Your web analytics dashboard template.</p>

 */}
        </div>
      </div>
      <div className="d-block mb-4 mb-md-2">
        <Row>
          <Col xs={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                maxLength={50}
                placeholder={t("search")}
                onChange={(e) => setWord(e.target.value)}
              />
              {
                // date filter
              }
            </InputGroup>
          </Col>
          <Col xs={2}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <Stack spacing={3}>
                <DatePicker
                  label={t("from_date")}
                  openTo="month"
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            {
              // date filter
            }
          </Col>
          <Col xs={2}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <Stack spacing={3}>
                <DatePicker
                  label={t("until")}
                  inputFormat="DD/MM/YYYY"
                  value={dateTo}
                  views={["year", "month", "day"]}
                  onChange={(e) => setDateTo(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Col>
          <Col xs={2}>
            <Button
              variant="primary"
              onClick={() => {
                setWord("");
                filterByDate();
              }}
            >
              {t("filter")}
            </Button>
          </Col>
          <Col xs={2}>
            <Button
              variant="primary"
              onClick={() => {
                fetchData(false);
              }}
            >
              {t("Reset")}
            </Button>
          </Col>
        </Row>
      </div>
      <Card border="light" className="table-wrapper shadow-sm">
        <Card.Body className="p-0">
          <TableContainer component={Paper}>
            <Table className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">{t("full_name")}</th>
                  <th className="border-0">{t("date_of_birth")}</th>
                  <th className="border-0">{t("Date of death")}</th>
                  <th className="border-0">{t("Profile creation date")}</th>
                  <th className="border-0">{t("reference_email")}</th>
                  {decoded?.role === "admin" || decoded?.role === "gadmin" ? (
                    <th className="border-0">{t("location")}</th>
                  ) : (
                    <th className="border-0">{t("cemetery")}</th>
                  )}

                  <th className="border-0">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {pData?.map((dm, index) => (
                  <tr>
                    <td>{index + 1 + (currentPage - 1) * 10}</td>
                    <td className="fw-bold">
                      {dm?.profileName} {dm?.profileLastName}
                    </td>
                    <td>{moment(dm?.profileDatebirth).format("YYYY-MM-DD")}</td>
                    <td>
                      {dm?.profileDatedeath
                        ? moment(dm?.profileDatedeath).format("YYYY-MM-DD")
                        : ""}
                    </td>
                    <td>{moment(dm?.createdAt).format("YYYY-MM-DD")}</td>
                    <td>{dm?.profileEmail}</td>
                    {decoded?.role === "admin" || decoded?.role === "gadmin" ? (
                      <td>{dm?.position?.code}</td>
                    ) : (
                      <td>
                        {" "}
                        <h6
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              "/GraveyardsProfiles/" + dm?.graveyard?._id
                            )
                          }
                        >
                          {dm?.graveyard?.name}
                        </h6>
                      </td>
                    )}

                    <td>
                      <Button
                        onClick={() =>
                          // history push in new window
                          window.open("/prof/" + dm._id, "_blank")
                        }
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        size="sm"
                        className="m-1"
                        title={t("view")}
                      >
                        <i style={{ color: "black" }} className="ri-eye-line" />
                      </Button>
                      <Button
                        onClick={() =>
                          window.open("/editprof/" + dm._id, "_blank")
                        }
                        size="sm"
                        className="m-1"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        title={t("edit")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-pencil-line"
                        />
                      </Button>
                      <Button
                        onClick={() => handleEdit(dm)}
                        size="sm"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        className="m-1"
                        title={t("inspect")}
                      >
                        <FontAwesomeIcon
                          style={{ color: "black" }}
                          icon={faBookReader}
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination
                className="pagination"
                postsPerPage={postsPerPage}
                totalPosts={data.length}
                paginate={paginate}
              />
            </Nav>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
}
