/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "@themesberg/react-bootstrap";
import "flag-icons/css/flag-icons.min.css";
import './../assets/styles/language.css';
import canada from './../assets/img/flags/canada.svg';
import spain from './../assets/img/flags/spain.svg';
import japan from './../assets/img/flags/japan.svg';

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
    flagIcon: canada
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
    flagIcon: canada
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
    flagIcon: spain
  },
  {
    code: "ja",
    name: "日本語",
    country_code: "ja",
    flagIcon: japan
  },
];

const Language = () => {
  const browserLanguage = navigator.language;
  const supportedLanguageCodes = languages.map((l) => l.code);

  let currentLanguageCode;

  const storedLanguageCode = cookies.get("i18next");
  if (storedLanguageCode && supportedLanguageCodes.includes(storedLanguageCode)) {
    currentLanguageCode = storedLanguageCode;
  } else {
    if (browserLanguage.includes("fr")) {
      cookies.set("i18next", "fr");
    } else if (browserLanguage.includes("en")) {
      cookies.set("i18next", "en");
    } else {
      // Check if browserLanguage exists in the list of supported language codes
      const browserLanguageExists = supportedLanguageCodes.includes(browserLanguage);

      // If browserLanguage exists in the list of supported language codes, set currentLanguageCode to browserLanguage
      // Otherwise, default it to "en"
      if (browserLanguageExists) {
        currentLanguageCode = browserLanguage;
      } else {
        currentLanguageCode = "en";
      }
    }
  }

  const currentLanguageObj = languages.find((l) => l.code === currentLanguageCode);

  // Check if currentLanguageObj is defined before accessing its properties
  const currentFlag = currentLanguageObj ? currentLanguageObj.flagIcon : null;

  const { t } = useTranslation();


  useEffect(() => {
    document.body.dir = currentLanguageObj && currentLanguageObj.dir ? currentLanguageObj.dir : "ltr";
    document.title = "Skiesbook";
  }, [currentLanguageObj, t]);

  return (
    <div className="language-select">
      <div className="d-flex justify-content-start align-items-center language-select-root">
        <div className="dropdown" style={{padding:0}}>
          <DropdownButton 
            variant="outline-link"
            title={<img src={currentFlag} alt="Current Flag" style={{height:'1em',marginTop:"-7px",border:'1px solid #4a5073',borderRadius:'10px'}} />}
            id="input-group-dropdown-2"
          >
            {languages.map(({ code, name, country_code, flagIcon }, i) => (
              <div key={i}>
                <Dropdown.Item
                  key={country_code}
                  onClick={() => {
                    i18next.changeLanguage(code);
                  }}
                  className={classNames("dropdown-item", {
                    disabled: currentLanguageCode === code,
                  })}
                  style={{
                    backgroundColor:
                      currentLanguageCode === code ? "#e9ecef" : "white",
                    fontSize: "0.8rem",
                  }}
                >
                  <img className="mr-2" src={flagIcon} alt={`${flagIcon} Flag`} style={{height:'1em'}}/>
                  {name}
                </Dropdown.Item>
                {i !== languages.length - 1 ? <Dropdown.Divider /> : null}
              </div>
            ))}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default Language;
