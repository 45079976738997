/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Col, Row, Form, Button, Card } from "@themesberg/react-bootstrap";
import axios from "src/utils/axios";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useLoadScript } from "@react-google-maps/api";
import jwt_decode from "jwt-decode";

const PlacesAutocomplete = ({ setAddress, setLatLng }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t } = useTranslation();
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    setAddress(address);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLatLng({ lat, lng });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="form-control"
          placeholder={t("Search an address")}
        />
        <ComboboxPopover style={{ zIndex: 9999999999 }}>
          <ComboboxList style={{ zIndex: 9999999999 }}>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default function Refferal() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: ["places"],
  });

  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);

  const [formData, setFormData] = useState({
    name: "",
    lastn: "",
    email: "",
    address: "",
    phone: "",
    graveyardName: "",
  });
  const { t } = useTranslation();
  const [addr, setAddr] = useState("");
  const [latLng, setLatLng] = useState({});

  function submitB2b(e) {
    e.preventDefault();
    if (formData.phone === "" || addr === "") {
      Swal.fire({
        title: t("Missing information"),
        text: t("Try to fill all the fields"),
        icon: "error",
        confirmButtonText: t("Ok"),
      });
    } else {
      formData.address = addr;
      formData.Lat = latLng.lat;
      formData.Lng = latLng.lng;
      formData.referral = decoded?.userId;
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/request", formData)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: t("Request sent"),
              text: t("We will contact you soon"),
              icon: "success",
              confirmButtonText: t("Ok"),
            });
          }
        });
    }
  }

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{t("General informations")}</h5>

        <Form
          style={{ padding: "20px" }}
          onSubmit={(e) => {
            submitB2b(e);
          }}
        >
          <Row>
            <h5 className="mb-2">{t("Information")}</h5>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("Name")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={50}
                  name="profileName"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={50}
                  name="profileLastName"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      lastn: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="profileName"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("phone")}</Form.Label>
                <PhoneInput
                  required
                  country={"ca"}
                  onlyCountries={["us", "ca"]}
                  onChange={(e) => setFormData({ ...formData, phone: e })}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5 className="mb-2">{t("cemetery")}</h5>

          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("cemetery_name")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={50}
                  name="cim name"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      graveyardName: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>{t("address")}</Form.Label>
                <PlacesAutocomplete
                  className="form-control"
                  setAddress={setAddr}
                  setLatLng={setLatLng}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit" variant="primary">
            {t("save")}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
