import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import axios from 'src/utils/axios';import jwt_decode from "jwt-decode";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import redPin from "../../assets/img/red.png";

import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { faPlus } from "@fortawesome/fontawesome-free-solid";

const AddCollab = () => {
  const [data, setData] = useState([]);
  const [marker, setMarker] = useState({
    lat: 45.424721,
    lng: -75.695,
  });
  const [code, setCode] = useState("");
  const containerStyle = {
    width: "100%",
    height: "500px",
  };
  const { t } = useTranslation();
  const [center, setCenter] = useState({
    lat: 45.424721,
    lng: -75.695,
  });
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const graveyardId = decoded?.graveyardId;
  const [alert, setAlert] = useState(false);

  const fetchCollabs = async (reset) => {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/columbarium/" + graveyardId
      );
      setData(response);
      if (reset) {
        setMarker({
          lat: parseFloat(response.Lat),
          lng: parseFloat(response.Lng),
        });
        setCenter({
          lat: parseFloat(response.Lat),
          lng: parseFloat(response.Lng),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCollabs(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [activeMarker, setActiveMarker] = useState(null);

  const [collab, setCollab] = useState({
    name: "",
    position: {},
    data: [],
    rows: 0,
    columns: 0,
  });

  function settingCord(e) {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }

  function generateMatrix(rows, columns) {
    let newMatrix = [];
    for (let i = 0; i < rows; i++) {
      let row = [];
      for (let j = 0; j < columns; j++) {
        row.push([]);
      }
      newMatrix.push(row);
    }

    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    let alphabetArray = [];
    for (let i = 0; i < columns; i++) {
      for (let j = 0; j < rows; j++) {
        // object key alphabet and value number
        alphabetArray.push({
          [alphabet[j]]: i + 1,
          value: [alphabet[j]] + (i + 1),
        });
      }
    }
    alphabetArray.sort((a, b) => {
      let keyA = Object.keys(a)[0];
      let keyB = Object.keys(b)[0];
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });

    return { newMatrix, alphabetArray };
  }
  async function submit(e) {
    e.preventDefault();
    let { newMatrix, alphabetArray } = generateMatrix(
      collab.rows,
      collab.columns
    );
    let position = {
      ...marker,
      code,
    };

    const collobaruim = {
      name: collab.name,
      position,
      data: newMatrix,
      graveyard: data?._id,
      values: alphabetArray,
    };
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/columbarium", collobaruim)
      .then((res) => {
        if (res) {
          setAlert(true);
          setMarker({
            lat: marker.lat + 0.00001,
            lng: marker.lng + 0.00001,
          });
          fetchCollabs(false);
          setCode("");
          setCollab({
            name: "",
            position: {},
            data: [],
            rows: 0,
            columns: 0,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("Sorry!"),
            text: t("No location"),
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: t("Sorry!"),
          text: t("Location code already exist!"),
        });
      });
    setTimeout(() => {
      setAlert(false);
    }, 2500);
  }

  // mouse zoom in on pointer on hover over image

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        {alert ? (
          <Alert
            style={{ position: "absolute", top: 0, right: 0, width: "200px" }}
            className="mt-3"
            severity="success"
          >
            {t("Location Added")}
          </Alert>
        ) : (
          <></>
        )}
        <Card.Body>
          <h5 className="my-4">{t("Columbarium")}</h5>
          <Form onSubmit={submit}>
            <Row>
              <Col md={4} className="mb-3">
                <Form.Group id="Lat">
                  <Form.Label>{t("name")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    placeholder=""
                    value={collab.name}
                    onChange={(e) =>
                      setCollab({ ...collab, name: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="col">
                  <Form.Label>{t("col")}</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    value={collab.columns}
                    min={1}
                    placeholder=""
                    onChange={(e) =>
                      setCollab({ ...collab, columns: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={2} className="mb-3">
                <Form.Group id="row">
                  <Form.Label>{t("row")}</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder=""
                    max={26}
                    min={1}
                    value={collab.rows}
                    onChange={(e) =>
                      setCollab({ ...collab, rows: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={2} className="mb-3">
                <Form.Group id="code">
                  <Form.Label>{t("Code")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={10}
                    placeholder={t("Code")}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Label>{t("add")}</Form.Label>
                <Button variant="primary" className="w-100" type="submit">
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
            <Row></Row>
          </Form>
          <div className="bg-white shadow-sm mb-4">
            <Row>
              <Col sm={12} className="mb-3">
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={20}
                    // satellite map with labels
                    mapTypeId="hybrid"
                  >
                    {data?.collabs?.map((empl, i) => (
                      <Marker
                        key={i}
                        position={{
                          lat: parseFloat(empl?.position?.lat),
                          lng: parseFloat(empl?.position?.lng),
                        }}
                        icon={redPin}
                        onClick={() => {
                          setActiveMarker(empl);
                        }}
                      >
                        {activeMarker === empl ? (
                          <InfoWindow>
                            <>
                              <div>
                                {t("code")} {": "}
                                {empl?.position?.code}
                              </div>
                              <div>
                                {t("name")} {": "}
                                {empl?.name}
                              </div>
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ))}
                    <Marker
                      position={marker}
                      draggable={true}
                      zIndex={99999}
                      onDragEnd={(e) => {
                        settingCord(e);
                      }}
                    />
                  </GoogleMap>
                </LoadScript>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default AddCollab;
