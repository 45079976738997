import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  InputGroup,
  ButtonGroup,
  Dropdown,
  Button,
} from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "src/utils/axios";
import { useDropzone } from "react-dropzone";

import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import cookies from "js-cookie";
const EditUser = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const [locale, setLocale] = React.useState(null);
  useEffect(() => {
    async function importLocale(languageCode) {
      let importedLocale;
      setLocale(null);
      switch (languageCode) {
        case "fr":
          importedLocale = await import("react-phone-input-2/lang/fr.json");
          break;
        case "es":
          importedLocale = await import("react-phone-input-2/lang/es.json");
          break;
        case "ja":
          importedLocale = await import("react-phone-input-2/lang/jp.json");
          break;
        default:
          importedLocale = { default: {} };
      }
      setLocale(importedLocale.default);
    }

    importLocale(currentLanguageCode);
  }, [currentLanguageCode]);

  const dropzoneStyle = {
    border: "2px dashed #ccc",
    borderRadius: "50%", // Makes it round
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "200px", // Adjust width and height as needed
    height: "200px", // Adjust width and height as needed
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imagePreviewStyle = {
    marginTop: "20px",
  };
  const [passwordType, setPasswordType] = useState("password");
  const [user, setUser] = useState({});
  let { id } = useParams();
  const history = useHistory();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  async function getGrave() {
    try {
      const { data: response } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/users/" + id
      );
      setUser(response);
      setFormData({
        ...formData,
        renderImage: `${process.env.REACT_APP_API_IMAGE}/${response.userimage}`,
      });
    } catch (error) {
      console.error(error.message);
    }
  }
  useEffect(() => {
    getGrave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    console.log(file);
    // Check if the dropped file is an image

    const reader = new FileReader();

    reader.onload = () => {
      setFormData({
        ...formData,
        userimage: file,
        renderImage: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    // upload images
    maxFiles: 1,
    accept: "image/*",
    onDrop,
  });
  const [formData, setFormData] = useState({
    name: user?.name,
    lastn: user?.lastn,
    Datebirth: user?.Datebirth,
    email: user?.email,
    sex: user?.sex,
    password: "",
    confirmPassword: "",
    userimage: "",
    phone: user?.phone,
    renderImage: "",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function Submit(e) {
    e.preventDefault();
    const mydata = new FormData();
    mydata.append("name", e.target[0].value);
    mydata.append("lastn", e.target[1].value);
    mydata.append("Datebirth", e.target[2].value);
    mydata.append("email", e.target[4].value);
    mydata.append("sex", e.target[3].value);

    mydata.append("phone", e.target[5].value);
    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        title: t("Error"),
        text: t("Password not match"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
      return;
    }
    if (
      formData.password !== "" &&
      formData.password === formData.confirmPassword
    ) {
      mydata.append("password", formData.password);
    }
    if (formData.userimage !== "") {
      mydata.append("userimage", formData.userimage);
    }

    /*     mydata.append("latitude", position.lat);
    mydata.append("logitude", position.lng); */
    const { data } = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/users/" + id,
      mydata,
      config
    );
    if (data._id) {
      Swal.fire({
        title: t("Success"),
        text: t("User Updated"),
        icon: "success",
        confirmButtonText: t("OK"),
      }).then((result) => {
        if (result.value) {
          history.push("/");
        }
      });
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("User not Updated"),
        icon: "error",
        confirmButtonText: t("OK"),
      });
    }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <div className="btn-toolbar mb-2 mb-md-2">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.goBack()}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              {t("back")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
        <h5 className="mb-4">{t("My profile")}</h5>

        <h5 className="mb-4">{t("General informations")}</h5>
        <Form onSubmit={(e) => Submit(e)}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.name}
                  type="text"
                  maxLength={50}
                  placeholder={t("Enter your first name")}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("lastname")}</Form.Label>
                <Form.Control
                  defaultValue={user?.lastn}
                  type="text"
                  maxLength={50}
                  placeholder={t("And your last name")}
                  onChange={(e) =>
                    setFormData({ ...formData, lastn: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>{t("date_of_birth")}</Form.Label>
                <Col style={{ padding: 0 }} md={6} className="mb-3">
                  <Form.Group id="birthday">
                    <InputGroup>
                      <Form.Control
                        className="date"
                        name="Datedeath"
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        defaultValue={user?.Datebirth}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Datebirth: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>{t("gender")}</Form.Label>
                <Form.Select
                  defaultValue={user?.sex}
                  onChange={(e) =>
                    setFormData({ ...formData, sex: e.target.value })
                  }
                >
                  <option selected={user?.sex === "o" ? true : false} value="o">
                    {t("other")}
                  </option>
                  <option selected={user?.sex === "F" ? true : false} value="F">
                    {t("women")}
                  </option>
                  <option selected={user?.sex === "M" ? true : false} value="M">
                    {t("man")}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  defaultValue={user?.email}
                  type="email"
                  placeholder="name@company.com"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            {locale && (
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>{t("phone")}</Form.Label>
                  <PhoneInput
                    className="mt-1"
                    country={"ca"}
                    onlyCountries={["us", "ca", "jp"]}
                    value={user?.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e })}
                    style={{ zIndex: 999 }}
                    localization={locale}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    autoComplete="new-password"
                    type={passwordType}
                    placeholder={t("enter_your_password")}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("confirm_your_password")}</Form.Label>
                <InputGroup>
                  <Button
                    size="sm"
                    style={{
                      borderColor: "transparent",
                      marginRight: 5,
                      backgroundColor: "#d7dce4",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEye} style={{ zIndex: 0 }} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </InputGroup.Text>
                    )}
                  </Button>
                  <Form.Control
                    type={passwordType}
                    placeholder={t("confirm_your_password")}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      })
                    }
                  ></Form.Control>
                </InputGroup>
                {formData.confirmPassword !== formData.password &&
                formData.confirmPassword !== "" ? (
                  <MuiAlert severity="warning">
                    {t("password_does_not_match")}
                  </MuiAlert>
                ) : (
                  <span></span>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>{t("image")}</Form.Label>
                <Row
                  // add spacing
                  style={{
                    // space between the two columns
                    display: "flex",
                    justifyContent: "space-between",
                    // allign items in the center
                    alignItems: "center",
                  }}
                >
                  <Col xs={6} {...getRootProps()} style={dropzoneStyle}>
                    <input {...getInputProps()} />
                    <p>
                      {t("Drag and drop an image here, or click to select one")}
                    </p>
                  </Col>
                  {formData?.renderImage && (
                    <Col xs={6} style={imagePreviewStyle}>
                      <img
                        src={formData?.renderImage}
                        alt={t("uploaded")}
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3">
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default EditUser;
