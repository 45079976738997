import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import axios from "src/utils/axios";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

export const TextInput = ({ flip }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  async function send(e) {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("token"));
    let decoded = null;
    if (token !== null) decoded = jwt_decode(token);
    let side = 1;
    if (decoded.role === "admin" || decoded.role === "funHomeAdmin") side = 0;
    const data = {
      message: message,
      sender: decoded.userId,
      side: side,
    };
    await axios
      .put(
        process.env.REACT_APP_BACKEND_URL + "/profile/sendmessage/" + id,
        data
      )
      .then((res) => {
        setMessage("");
        flip(message);
      });
  }
  return (
    <>
      <form
        onSubmit={(e) => send(e)}
        className={classes.wrapForm}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-text"
          label={t("Send message")}
          className={classes.wrapText}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          //margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <SendIcon />
        </Button>
      </form>
    </>
  );
};
