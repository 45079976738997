import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar, Paper, Box } from "@mui/material";
import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
} from "@themesberg/react-bootstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/fontawesome-free-solid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { Table as TableBoost } from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
export default function MyPlace() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwtDecode(token);

  const [matrix, setMatrix] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState(null);
  const [line, setLine] = useState(null);
  const [coll, setColl] = useState(null);
  const [removedProfiles, setRemovedProfiles] = useState([]);
  const [removedAssignedProfiles, setRemovedAssignedProfiles] = useState([]);
  const [showDefault, setShowDefault] = useState(false);
  const [checked, setChecked] = useState(true);
  const [toggleSettings, settoggleSettings] = useState(false);
  const [data, setData] = useState([]);
  const [collab, setCollab] = useState(null);
  const [skProfiles, setSkProfiles] = useState([]);

  const [collabLine, setCollabLine] = useState(null);
  const [collabColl, setCollabColl] = useState(null);
  const handleClose = () => setShowDefault(false);

  const handleShow = (line, coll) => {
    setLine(line);
    setColl(coll);
    setShowDefault(true);
  };

  async function getPins() {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/columbarium/" +
          decoded.graveyardId
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProfiles() {
    const { data: response } = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        "/users/getprofiles/" +
        decoded?.graveyardId
    );
    setSkProfiles(
      response.map((profile) => ({
        ...profile,
        label: profile.profileName + " " + profile.profileLastName,
        id: profile._id,
      }))
    );
  }

  React.useEffect(() => {
    getPins();
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addProfile = (e) => {
    e.preventDefault();
    let newMatrix = [...matrix];
    if (profile === null) return;
    if (!profile?._id) {
      profile._id = uuidv4();
    }
    let newCol = {
      ...profile,
    };
    newMatrix[line][coll].push(newCol);
    setMatrix(newMatrix);
    setShowDefault(false);
    let alphabeticLine = String.fromCharCode(97 + line);
    profile.collabValue = {
      [alphabeticLine]: coll + 1,
      value: alphabeticLine + (coll + 1),
    };
    profile.collab = collab._id;
    if (checked) {
      profile.new = true;
      setCollab({
        ...collab,
        profiles: [...collab.profiles, profile],
      });
    } else {
      setCollab({
        ...collab,
        unassignedProfiles: [...collab.unassignedProfiles, profile],
      });
    }
    setRemovedProfiles(
      removedProfiles.filter((removedProfile) => removedProfile !== profile._id)
    );
    setProfile({});
    setLine(null);
    setColl(null);
    settoggleSettings(true);
  };

  const removeProfile = (line, coll, id, active) => {
    let newMatrix = [...matrix];
    newMatrix[line][coll] = newMatrix[line][coll].filter(
      (profile) => profile._id !== id
    );
    setMatrix(newMatrix);
    setProfiles(profiles.filter((profile) => profile._id !== id));

    if (active === 0) {
      setRemovedProfiles([...removedProfiles, id]);
      setCollab({
        ...collab,
        profiles: collab.profiles.filter((profile) => profile !== id),
      });
    } else {
      setRemovedAssignedProfiles([...removedAssignedProfiles, id]);
      setCollab({
        ...collab,
        unassignedProfiles: collab.unassignedProfiles.filter(
          (profile) => profile._id !== id
        ),
      });
    }
    setLine(null);
    setColl(null);
    settoggleSettings(true);
  };

  const submitChanges = async () => {
    const response = await axios.put(
      process.env.REACT_APP_BACKEND_URL +
        "/columbarium/updateColl/" +
        collab?._id,
      {
        profiles: collab.profiles,
        position: collab.position,
        removedProfiles: removedProfiles,
        unassignedProfiles: collab.unassignedProfiles,
        data: matrix,
      }
    );
    setMatrix(response?.data?.data);
    settoggleSettings(false);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#1da1c1" : "#1da1f2",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function handleListing(profiles, line, coll) {
    setProfiles(profiles);
    setCollabLine(line);
    setCollabColl(coll);
  }

  return (
    <>
      <Modal
        as={Modal.Dialog}
        show={showDefault}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Add a new profile")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleClose}
          />
        </Modal.Header>
        <Form onSubmit={(e) => addProfile(e)}>
          <Modal.Body>
            <Row>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={checked}
                    onChange={(e) => setChecked(!checked)}
                  />
                }
                label={t("Skiesbook Profile")}
              />
              {checked ? (
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Autocomplete
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          <Avatar
                            src={
                              process.env.REACT_APP_API_IMAGE +
                              "/" +
                              option.profileImage
                            }
                            sx={{ mr: 2, flexShrink: 0 }}
                          />
                          {option.label}
                          {option?.profileDatebirth ? (
                            <span
                              style={{
                                color: "gray",
                                fontSize: "12px",
                                marginLeft: 8,
                              }}
                            >
                              {" "}
                              {moment(option?.profileDatebirth).format(
                                "YYYY-MM-DD"
                              )}
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "gray",
                                fontSize: "12px",
                                marginLeft: 8,
                              }}
                            >
                              {" "}
                              {t("Date of birth not set")}
                            </span>
                          )}
                        </Box>
                      )}
                      disablePortal
                      id="combo-box-demo"
                      options={skProfiles}
                      onChange={(event, newValue) => {
                        setProfile(newValue);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Profile")} />
                      )}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>{t("First and Last Name")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={60}
                      placeholder={t("First and Last Name")}
                      onChange={(e) =>
                        setProfile({
                          profileName: e.target.value,
                          profileImage: "avatar.jpg",
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("add")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Row className="mb-3">
            <Col sx={12}>
              <div
                // in the middle
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  id="combo-box-demo"
                  options={data?.collabs || []}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Columbarium")}
                      variant="outlined"
                    />
                  )}
                  // on select console.log(_id)
                  onChange={(event, value) => {
                    if (value !== null) {
                      setCollab(value);
                      setMatrix(value?.data);
                    }
                  }}
                  // size is large
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>

          <div className="bg-white shadow-sm mb-4">
            <Row>
              <Col sm={3} className="mb-3">
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"> {t("image")}</TableCell>
                        <TableCell align="left">{t("name")}</TableCell>
                        <TableCell align="left">
                          {
                            // if collabColl and  collabLine are not null
                            collabColl !== null && collabLine !== null ? (
                              <span
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleShow(collabLine, collabColl);
                                }}
                              >
                                <i className="ri-add-fill" />{" "}
                              </span>
                            ) : (
                              ""
                            )
                          }
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {profiles?.length > 0 ? (
                      <>
                        <TableBody>
                          {profiles?.map((prof) => (
                            <TableRow
                              key={prof?._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                <Avatar
                                  onClick={() => {
                                    window.open(
                                      "/prof/" + prof?._id,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                  src={
                                    process.env.REACT_APP_API_IMAGE +
                                    "/" +
                                    prof?.profileImage
                                  }
                                  alt="photo"
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <h6
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(
                                      "/prof/" + prof?._id,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  {prof?.profileName} {prof?.profileLastName}
                                </h6>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <span
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    removeProfile(
                                      collabLine,
                                      collabColl,
                                      prof._id,
                                      prof?.active
                                    );
                                  }}
                                >
                                  <i className="ri-delete-bin-7-fill" />{" "}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      t("No profiles at this location")
                    )}
                  </Table>
                </TableContainer>
              </Col>
              <Col sm={9} className="mb-3">
                {collab !== null ? (
                  <Card.Body className="p-0">
                    <TableContainer component={Paper}>
                      <TableBoost
                        hover
                        className="user-table align-items-center"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="border-2">
                              #
                            </th>
                            {
                              // number of columns
                            }
                            {matrix[0]?.map((col, i) => {
                              return (
                                <>
                                  <th scope="col" className="border-2">
                                    {i + 1}
                                  </th>
                                </>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {matrix.map((row, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td className="border-2">
                                    {
                                      // alphabet
                                    }
                                    {String.fromCharCode(65 + i)}
                                  </td>
                                  {row.map((col, j) => {
                                    return (
                                      <>
                                        <td
                                          key={j}
                                          className={
                                            matrix[i][j] ? "active" : ""
                                          }
                                        >
                                          {matrix[i][j].length > 0 ? (
                                            // if matrix[i][j] has only one element
                                            matrix[i][j].length === 1 ? (
                                              <div
                                                className="periodic-element just-trying"
                                                onClick={() => {
                                                  handleListing(
                                                    matrix[i][j],
                                                    i,
                                                    j
                                                  );
                                                }}
                                                data-description={
                                                  matrix[i][j][0]?.profileName +
                                                  " " +
                                                  // if col.profileLastName is undefined don't show it
                                                  (matrix[i][j][0]
                                                    ?.profileLastName || "")
                                                }
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    float: "right",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    removeProfile(
                                                      i,
                                                      j,
                                                      matrix[i][j][0]?._id,
                                                      matrix[i][j][0]?.active
                                                    );
                                                  }}
                                                >
                                                  <i className="ri-delete-bin-5-line" />{" "}
                                                </span>

                                                <span
                                                  style={{
                                                    color: "black",
                                                    float: "right",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    handleShow(i, j);
                                                  }}
                                                >
                                                  <i className="ri-add-fill" />{" "}
                                                </span>
                                                <div className="periodic-element-inner">
                                                  <Avatar
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    src={
                                                      process.env
                                                        .REACT_APP_API_IMAGE +
                                                      "/" +
                                                      matrix[i][j][0]
                                                        ?.profileImage
                                                    }
                                                  />
                                                  {
                                                    // span in the middle of the element
                                                  }
                                                  <div
                                                    style={{
                                                      width: "90px",
                                                      whiteSpace: "normal",
                                                    }}
                                                  >
                                                    {
                                                      matrix[i][j][0]
                                                        ?.profileName
                                                    }{" "}
                                                    {
                                                      matrix[i][j][0]
                                                        ?.profileLastName?.[0]
                                                    }
                                                    .
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  width: "100px",
                                                  height: "80px",
                                                  // blue background color
                                                  background: "#323c46",
                                                  // all elements are in the center
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",

                                                  // pointer
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleListing(
                                                    matrix[i][j],
                                                    i,
                                                    j
                                                  );
                                                }}
                                              >
                                                <div
                                                  // circle with number of elements
                                                  style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    borderRadius: "50%",
                                                    background: "#2c343d",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "white",
                                                    fontSize: "20px",
                                                  }}
                                                >
                                                  {matrix[i][j].length}
                                                </div>
                                              </div>
                                            )
                                          ) : (
                                            <div
                                              // onClick={() => {
                                              //   let newMatrix = [...matrix];
                                              //   let newCol = {
                                              //     name: "Aziz",
                                              //     img: "https://skiesbook.nyc3.cdn.digitaloceanspaces.com/skBucket/avatar.jpg",
                                              //   };
                                              //   newMatrix[i][j] = newCol;
                                              //   setMatrix(newMatrix);
                                              // }}
                                              style={{
                                                width: "100px",
                                                height: "80px",
                                                // thick border
                                                border: "2px solid #323c46",
                                                // all elements are in the center
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                // pointer
                                              }}
                                            >
                                              <div
                                                style={{
                                                  // circle with the add icon
                                                  width: "30px",
                                                  height: "30px",
                                                  borderRadius: "50%",
                                                  background: "#2c343d",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  color: "white",
                                                  fontSize: "20px",

                                                  // pointer
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleShow(i, j);
                                                }}
                                              >
                                                <i className="ri-add-fill" />
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </TableBoost>
                    </TableContainer>
                  </Card.Body>
                ) : (
                  <div className="text-center">
                    <h3>{t("Choose a Columbarium")}</h3>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} className="mb-3">
              <h5 className="my-4">{t("Columbariums")}</h5>
              <TableContainer component={Paper}>
                <Table size="small" aria-label={t("a dense table")}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"> {t("columbarium")}</TableCell>
                      <TableCell align="left"> {t("code")}</TableCell>
                      <TableCell align="left">
                        {" "}
                        {t("profiles unassigned")}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {t("Profiles Skiesbook")}
                      </TableCell>
                      <TableCell align="left"> {t("action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  {data?.collabs?.length > 0 ? (
                    <>
                      <TableBody>
                        {data?.collabs?.map((collab) => (
                          <TableRow
                            key={collab?._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              {collab?.name}
                            </TableCell>
                            <TableCell align="left">
                              {collab?.position?.code}
                            </TableCell>
                            <TableCell align="left">
                              {collab?.unassignedProfiles?.length}
                            </TableCell>
                            <TableCell align="left">
                              {collab?.profiles?.length}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setCollab(collab);
                                  setMatrix(collab?.data);
                                  window.scrollTo(0, 0);
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  borderColor: "transparent",
                                }}
                                size="sm"
                                title={t("view")}
                              >
                                <i
                                  style={{ color: "black" }}
                                  className="ri-eye-line"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  ) : (
                    t("No columbariums at this location")
                  )}
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {toggleSettings ? (
        <Card // add padding
          className="theme-settings p-4"
        >
          <div>
            <Button
              className="theme-settings-close"
              variant="close"
              size="sm"
              aria-label={t("Close")}
              onClick={() => {
                settoggleSettings(false);
              }}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 mb-1 me-3 fs-7">
                {t("You have modified the content of this columbarium.")}{" "}
              </p>
            </div>
            <Button
              onClick={(e) => submitChanges(e)}
              variant="primary"
              className="mb-3 w-100"
            >
              <FontAwesomeIcon icon={faDownload} className="me-1" />{" "}
              {t("Save Changes")}
            </Button>
            <div className="d-flex justify-content-center"></div>
          </div>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
