import { useEffect } from "react";

import { Avatar, Box, Paper, TableContainer } from "@mui/material";
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  Modal,
  Form,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/fontawesome-free-solid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import axios from 'src/utils/axios';export default function Collabruim() {
  const { t } = useTranslation();

  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const [matrix, setMatrix] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState(null);
  const [line, setLine] = useState(null);
  const [coll, setColl] = useState(null);
  const [removedProfiles, setRemovedProfiles] = useState([]);
  const [removedAssignedProfiles, setRemovedAssignedProfiles] = useState([]);
  const [showDefault, setShowDefault] = useState(false);
  const [checked, setChecked] = useState(true);
  const [toggleSettings, settoggleSettings] = useState(false);
  const [collab, setCollab] = useState({});
  const collId = useParams().id;
  const history = useHistory();

  const handleClose = () => setShowDefault(false);

  const handleShow = (line, coll) => {
    setLine(line);
    setColl(coll);
    setShowDefault(true);
  };

  async function getCollabruim() {
    const { data: response } = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/columbarium/getColl/" + collId
    );
    if (response === null) {
      history.push("/404");
    }
    setMatrix(response?.data);
    setProfiles(response?.profiles);
    setCollab(response);
  }
  async function getProfiles() {
    const { data: response } = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        "/users/getprofiles/" +
        decoded?.graveyardId
    );
    setProfiles(
      response.map((profile) => ({
        ...profile,
        label: profile.profileName + " " + profile.profileLastName,
        id: profile._id,
      }))
    );
  }
  useEffect(() => {
    getCollabruim();
    getProfiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addProfile = (e) => {
    e.preventDefault();
    let newMatrix = [...matrix];
    if (profile === null) return;
    if (!profile?._id) {
      profile._id = uuidv4();
    }
    let newCol = {
      ...profile,
    };
    newMatrix[line][coll] = newCol;
    setMatrix(newMatrix);
    setShowDefault(false);
    if (checked) {
      setCollab({
        ...collab,
        profiles: [...collab.profiles, profile._id],
      });
    } else {
      setCollab({
        ...collab,
        unassignedProfiles: [...collab.unassignedProfiles, profile],
      });
    }
    setRemovedProfiles(
      removedProfiles.filter((removedProfile) => removedProfile !== profile._id)
    );
    setProfile({});
    settoggleSettings(true);
  };

  const removeProfile = (line, coll, id, active) => {
    let newMatrix = [...matrix];
    let newCol = null;
    newMatrix[line][coll] = newCol;
    setMatrix(newMatrix);
    if (active) {
      setRemovedProfiles([...removedProfiles, id]);
      setCollab({
        ...collab,
        profiles: collab.profiles.filter((profile) => profile !== id),
      });
    } else {
      setRemovedAssignedProfiles([...removedAssignedProfiles, id]);
      setCollab({
        ...collab,
        unassignedProfiles: collab.unassignedProfiles.filter(
          (profile) => profile._id !== id
        ),
      });
    }
    settoggleSettings(true);
  };

  const submitChanges = async () => {
    const response = await axios.put(
      process.env.REACT_APP_BACKEND_URL + "/columbarium/updateColl/" + collId,
      {
        profiles: collab.profiles,
        removedProfiles: removedProfiles,
        unassignedProfiles: collab.unassignedProfiles,
        data: matrix,
      }
    );
    setMatrix(response?.data?.data);
    settoggleSettings(false);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#1da1c1" : "#1da1f2",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <Modal
        as={Modal.Dialog}
        show={showDefault}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Add a new profile")}</Modal.Title>
          <Button variant="close" aria-label={t("Close")} onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={(e) => addProfile(e)}>
          <Modal.Body>
            <Row>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={checked}
                    onChange={(e) => setChecked(!checked)}
                  />
                }
                label={t("Skiesbook Profile")}
              />
              {checked ? (
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Autocomplete
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          <Avatar
                            src={
                              process.env.REACT_APP_API_IMAGE +
                              "/" +
                              option.profileImage
                            }
                            sx={{ mr: 2, flexShrink: 0 }}
                          />
                          {option.label}
                        </Box>
                      )}
                      disablePortal
                      id="combo-box-demo"
                      options={profiles}
                      onChange={(event, newValue) => {
                        setProfile(newValue);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Profile")} />
                      )}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>{t("First and Last Name")}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={60}
                      placeholder={t("First and Last Name")}
                      onChange={(e) =>
                        setProfile({
                          profileName: e.target.value,
                          profileImage: "avatar.jpg",
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              {t("add")}{" "}
            </Button>
            <Button
              variant="secondary"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="my-4"> t("Columbarium") </h5>
          <Row className="mb-3">
            <Col>
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="p-0">
                  <TableContainer component={Paper}>
                    <Table hover className="user-table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="border-2">
                            #
                          </th>
                          {
                            // number of columns
                          }
                          {matrix[0]?.map((col, i) => {
                            return (
                              <>
                                <th scope="col" className="border-2">
                                  {i + 1}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {matrix.map((row, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="border-2">
                                  {
                                    // alphabet
                                  }
                                  {String.fromCharCode(65 + i)}
                                </td>
                                {row.map((col, j) => {
                                  return (
                                    <>
                                      <td
                                        key={j}
                                        className={matrix[i][j] ? "active" : ""}
                                      >
                                        {matrix[i][j] ? (
                                          <div
                                            className="periodic-element just-trying"
                                            data-description={
                                              col?.profileName +
                                              " " +
                                              // if col.profileLastName is undefined don't show it
                                              (col?.profileLastName || "")
                                            }
                                          >
                                            <span
                                              style={{
                                                color: "black",
                                                float: "right",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                removeProfile(
                                                  i,
                                                  j,
                                                  col?._id,
                                                  col?.active
                                                );
                                              }}
                                            >
                                              <i className="ri-delete-bin-5-line" />{" "}
                                            </span>
                                            <div className="periodic-element-inner">
                                              <Avatar
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                                src={
                                                  process.env
                                                    .REACT_APP_API_IMAGE +
                                                  "/" +
                                                  col?.profileImage
                                                }
                                              />
                                              {
                                                // span in the middle of the element
                                              }
                                              <div
                                                style={{
                                                  width: "90px",
                                                  whiteSpace: "normal",
                                                }}
                                              >
                                                {col?.profileName}{" "}
                                                {col?.profileLastName?.[0]}.
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <Button
                                            // onClick={() => {
                                            //   let newMatrix = [...matrix];
                                            //   let newCol = {
                                            //     name: "Aziz",
                                            //     img: "https://skiesbook.nyc3.cdn.digitaloceanspaces.com/skBucket/avatar.jpg",
                                            //   };
                                            //   newMatrix[i][j] = newCol;
                                            //   setMatrix(newMatrix);
                                            // }}
                                            onClick={() => {
                                              handleShow(i, j);
                                            }}
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {toggleSettings ? (
        <Card // add padding
          className="theme-settings p-4"
        >
          <div>
            <Button
              className="theme-settings-close"
              variant="close"
              size="sm"
              aria-label={t("Close")}
              onClick={() => {
                settoggleSettings(false);
              }}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 mb-1 me-3 fs-7">
                {t("You have modified the content of this columbarium.")}{" "}
              </p>
            </div>
            <Button
              onClick={(e) => submitChanges(e)}
              variant="primary"
              className="mb-3 w-100"
            >
              <FontAwesomeIcon icon={faDownload} className="me-1" /> {t("Save Changes")} 
            </Button>
            <div className="d-flex justify-content-center"></div>
          </div>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
