/* eslint-disable import/no-anonymous-default-export */

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import Expired from "./expired";
import ErrorBoundary from "./error";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@material-ui/lab/Alert";
import axios from 'src/utils/axios';import Swal from "sweetalert2";
import { Modal } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

function Reset() {
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);

  const [passwordType, setPasswordType] = useState("password");
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkBox, setCheckBox] = useState(false);

  let decoded = null;
  if (localStorage.getItem("token") !== null) {
    decoded = jwt_decode(localStorage.getItem("token"));
  }
  if (decoded !== null) {
    // clear local storage
    localStorage.clear();
    // clear session storage
    sessionStorage.clear();
  }

  // eslint-disable-next-line no-unused-vars
  let { t } = useTranslation();

  const [welcome, setWelcome] = useState(false);

  // get token from query in the url called token
  const token = new URLSearchParams(window.location.search).get("id");

  // check if token is valid and decodable

  const decodedTocken = jwt_decode(token || "");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const history = useHistory();

  async function Submit(e) {
    e?.preventDefault();
    const mydata = {
      id: decodedTocken?.id,
      password: Password,
    };
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/users/resetpasswordrequest",
        mydata
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: t("password_saved_successfully"),
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // reload
            history.push("/");
          }
        });
      })
      .catch((e) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: t("error"),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  async function checkToken() {
    if (decodedTocken) {
      setWelcome(decodedTocken?.welcome);
    }
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/auth/checkresetpasswordrequest",
        { token: token }
      )
      .then((response) => {
        setDisplay(true);
      })
      .catch((e) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: t("Link Expired"),
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          history.push("/");
        }, 4000);
      });
  }

  React.useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {display === true ? (
        <main>
          <Modal size="lg" as={Modal.Dialog} show={open}>
            <Modal.Header>
              <Modal.Title className="h6">
                {t("Terms and conditions")}
              </Modal.Title>
              <Button
                variant="close"
                aria-label={t("Close")}
                onClick={() => setOpen(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <iframe
                title={t("terms")}
                src={process.env.REACT_APP_API_IMAGE + "/terms.pdf"}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Modal.Body>
          </Modal>
          <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
            <Container>
              <form onSubmit={Submit}>
                <Row className="justify-content-center">
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                      <h3 className="mb-4">
                        {t("Please enter your password")}{" "}
                      </h3>

                      <Form.Group id="firstName" className="mb-4">
                        <Form.Label>{t("password")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={passwordType}
                            required
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          ></Form.Control>
                          <Button
                            size="sm"
                            style={{
                              borderColor: "transparent",
                              marginRight: 5,
                              backgroundColor: "#d7dce4",
                            }}
                            onClick={() => togglePassword()}
                          >
                            {passwordType === "password" ? (
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEye} />
                              </InputGroup.Text>
                            ) : (
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </InputGroup.Text>
                            )}
                          </Button>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="firstName" className="mb-4">
                        <Form.Label> {t("confirm_your_password")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={passwordType}
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          ></Form.Control>
                          <Button
                            size="sm"
                            style={{
                              borderColor: "transparent",
                              marginRight: 5,
                              backgroundColor: "#d7dce4",
                            }}
                            onClick={() => togglePassword()}
                          >
                            {passwordType === "password" ? (
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEye} />
                              </InputGroup.Text>
                            ) : (
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </InputGroup.Text>
                            )}
                          </Button>
                        </InputGroup>
                        {confirmPassword !== Password &&
                        confirmPassword !== "" ? (
                          <MuiAlert severity="warning">
                            {t("password_does_not_match")}
                          </MuiAlert>
                        ) : (
                          <span></span>
                        )}

                        {welcome === true ? (
                          <Form.Check
                            style={{
                              marginTop: "20px",
                              marginLeft: "20px",
                              backgroundColor: "transparent",
                              color: "black",
                              borderColor: "transparent",
                            }}
                            type="checkbox"
                          >
                            <Form.Check.Input
                              onChange={(e) => setCheckBox(e.target.checked)}
                              type="checkbox"
                            />
                            <Form.Check.Label
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() => setOpen(true)}
                            >
                              {t("I accept the terms and conditions")}
                            </Form.Check.Label>
                          </Form.Check>
                        ) : (
                          <span></span>
                        )}
                      </Form.Group>

                      <Button
                        variant="primary"
                        className="w-100"
                        disabled={
                          Password !== confirmPassword ||
                          Password === "" ||
                          (welcome ? checkBox === false : false)
                        }
                        type="submit"
                        onClick={() => Submit()}
                      >
                        {t("confirm_my_password")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Container>
          </section>
        </main>
      ) : (
        <Expired />
      )}
    </>
  );
}

export default function WelcomePage() {
  return (
    <ErrorBoundary>
      <Reset />
    </ErrorBoundary>
  );
}
