import React from "react";

import { useTranslation } from "react-i18next";

import { Grid, Paper, Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import axios from 'src/utils/axios';import { Form, Button } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";

export default function MyPlace() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("token"));
  let decoded = null;
  if (token !== null) decoded = jwtDecode(token);

  const [data, setData] = React.useState([]);

  const [error, setError] = React.useState(false);
  const handleUpload = (e) => {
    const type = e.target.files[0].type.split("/")[0];
    if (type !== "image") {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Image is required!"),
      });
      setError(true);
      return;
    } else {
      setFile(e.target.files[0]);
    }
  };

  const [file, setFile] = React.useState(null);

  function uploadPlan(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("plan", file);
    formData.append("id", decoded?.userId);
    if (error || file === null) {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Image is required!"),
      });
      return;
    }

    Swal.fire({
      title: t("uploading"),
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/graveyard/uploadplan",
        formData
      )
      .then((response) => {
        Swal.fire({
          title: t("uploaded"),
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          title: t("error"),
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  function getUser() {
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL + "/users/getAdmin/" + decoded.userId
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={2}>
      {data?.graveyard?.plan ? (
        <>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 500,
              }}
            >
              <img
                src={
                  process.env.REACT_APP_API_IMAGE + "/" + data?.graveyard?.plan
                }
                alt={t("plan")}
                style={{ width: "100%", height: "100%" }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Form onSubmit={uploadPlan}>
                <Form.Group id="plan">
                  <Form.Label>{t("Update plan")}</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                    isInvalid={error}
                  />
                </Form.Group>
                <Button className="mt-3" variant="primary" type="submit">
                  {t("upload")}
                </Button>
              </Form>
            </Paper>
          </Grid>
        </>
      ) : (
        <Grid className="p-2" item xs={12}>
          <Paper className="p-6">
            <h4 style={{ padding: "20px" }} variant="h6" component="h2">
              {t("You don't have a plan yet")}
            </h4>

            <Typography style={{ padding: "20px" }} variant="h6" component="h2">
              {t("Please upload your plan")}
            </Typography>

            <Form style={{ padding: "20px" }} onSubmit={(e) => uploadPlan(e)}>
              <Form.Group id="plan">
                <Form.Label>{t("Plan")}</Form.Label>
                <Form.Control
                  required
                  type="file"
                  accept="image/*"
                  onChange={(e) =>handleUpload(e)}
                  isInvalid={error}
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-20 mt-3">
                {t("Upload")}
              </Button>
            </Form>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
