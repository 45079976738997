import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";
import axios from 'src/utils/axios';import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Avatar, Paper, TableContainer } from "@mui/material";
import { Table as TableBoost } from "@themesberg/react-bootstrap";
import { right } from "@popperjs/core";

const PlacesAutocomplete = ({ setAddress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    // eslint-disable-next-line no-unused-vars
    const { lat, lng } = getLatLng(results[0]);
    setAddress(address);
  };

  return (
    <div>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="form-control"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};
const AddClient = () => {
  const { t } = useTranslation();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const [locale, setLocale] = React.useState(currentLanguageCode);
  const [address, setAddress] = useState("");

  useEffect(() => {
    setLocale(currentLanguageCode);
  }, [currentLanguageCode]);

  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("token"));
  const [data, setData] = useState([]);
  let decoded = null;
  if (token !== null) decoded = jwt_decode(token);
  const d = new Date();
  const [formData, setFormData] = useState({
    name: "",
    lastn: "",
    Datebirth: moment(d).format("YYYY-MM-DD"),
    email: "",
    sex: "",
    password: "",
    confirmPassword: "",
    userimage: "",
    phone: "",
    address: "Canada",
    postalcode: "",
    role: "client",
    graveyard: decoded.graveyardId,
    collab: "",
    alive: true,
  });
  const [inputList, setInputList] = useState([
    {
      profileName: "",
      profileLastName: "",
      profileDatebirth: null,
      profileDatedeath: null,
      gender: "F",
      cords: "",
      modeDeath: "I",
      profileEmail: formData.email,
      hometown: "",
      graveyard: decoded.graveyardId,
      banner: "banner.jpg",
      profileImage: "avatar.jpg",
      alive: true,
      collab: "",
      collabValue: null,
      collabValues: [],
      position: {
        lat: null,
        lng: null,
      },
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name === "modeDeath") {
      list[index]["collabValue"] = null;
    }
    setInputList(list);
  };

  const handleAlive = (e, index) => {
    const list = [...inputList];
    list[index]["alive"] = !list[index]["alive"];
    setInputList(list);
  };

  const handlePositionChange = (value, name, index) => {
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleCollabChange = (value, name, index) => {
    if (value === null) {
      // set the collabValue to null and collabValues to []
      const list = [...inputList];
      list[index][name] = "";
      list[index]["collabValue"] = null;
      list[index]["collabValues"] = [];
      setInputList(list);
      return;
    }
    const list = [...inputList];
    list[index][name] = value?._id;
    setCollabValues(value, index);
    setInputList(list);
  };

  const setCollabValues = (values, index) => {
    const list = [...inputList];
    list[index]["collabValues"] = values?.values;
    list[index]["position"] = values?.position;
    setInputList(list);
  };

  const handleCollabNiche = (value, index) => {
    const list = [...inputList];
    list[index]["collabValue"] = value;
    setInputList(list);
  };

  const handleInputChange2 = (e, index) => {
    const list = [...inputList];
    list[index]["profileDatebirth"] = e;
    setInputList(list);
  };

  const handleInputChange3 = (e, index) => {
    const list = [...inputList];
    list[index]["profileDatedeath"] = e;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    // check if the last one in the list death mode is C if its check if he has collab and value
    let valid = true;
    inputList.forEach((item) => {
      if (item.modeDeath === "C") {
        if (item.collab === "" || item.collabValue === null) {
          Swal.fire({
            icon: "error",
            title: t("Sorry!"),
            text: t("Please select a columbarium and a niche!"),
          });
          valid = false;
        }
      }
    });
    if (!valid) return;
    setInputList([
      ...inputList,
      {
        profileName: "",
        profileLastName: "",
        profileDatebirth: null,
        profileDatedeath: null,
        gender: "F",
        cords: "",
        modeDeath: "I",
        profileEmail: formData.email,
        hometown: "",
        graveyard: decoded.graveyardId,
        banner: "banner.jpg",
        profileImage: "avatar.jpg",
        collabValue: null,
        collabValues: [],
        alive: true,
        position: {
          lat: null,
          lng: null,
        },
      },
    ]);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/users/getAdmin/" +
            decoded.userId
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const Submit = async (e) => {
    e.preventDefault();

    if (formData.phone === "") {
      Swal.fire({
        icon: "error",
        title: t("Sorry!"),
        text: t("Phone is required!"),
      });
      return;
    }

    formData.Datebirth = moment("05/05/2000").format("DD-MM-YYYY");
    const mydata = new FormData();

    const profiles = inputList.map((profile) => {
      return {
        ...profile,
        profileEmail: formData.email,
      };
    });
    let valid = true;
    profiles.forEach((profile) => {
      if (
        profile.modeDeath === "C" &&
        profile.collab === "" &&
        profile.collabValue === null
      ) {
        Swal.fire({
          icon: "error",
          title: t("Sorry!"),
          text: t("Please select a columbarium and a niche!"),
        });
        valid = false;
      } else if (profile.modeDeath !== "C") {
        profile.collab = null;
        profile.collabValue = null;
      }
    });
    if (!valid) return;

    mydata.append("name", formData.name);
    mydata.append("lastn", formData.lastn);
    mydata.append("Datebirth", formData.Datebirth);
    mydata.append("email", formData.email);
    mydata.append("sex", formData.sex);
    mydata.append("password", formData.password);
    mydata.append("userimage", formData.userimage);
    mydata.append("phone", formData.phone);
    mydata.append("role", formData.role);
    mydata.append("address", address);
    mydata.append("postalcode", formData.postalcode);
    mydata.append("graveyard", decoded.graveyardId);
    mydata.append("profiles", JSON.stringify(profiles));
    mydata.append("vendor", decoded.userId);

    Swal.fire({
      title: t("Are you sure you want to add this client?"),
      showCancelButton: true,
      confirmButtonText: t("yes"),
      showLoaderOnConfirm: true,
      cancelButtonText: t("cancel"),
      preConfirm: async () => {
        return await axios
          .post(
            process.env.REACT_APP_BACKEND_URL + "/users/addclient",
            mydata,
            config
          )
          .then((result) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: t("Customer added successfully"),
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/adminclients");
              }
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(t("email_address_already_exists"));
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  function handlePlace(index, val) {
    const location = data?.graveyard?.places.find(
      (place) => place.code === val
    );
    const position = {
      lat: location?.lat,
      lng: location?.lng,
      code: location?.code,
      id: location?._id,
    };
    handlePositionChange(position, "position", index);
  }

  function handleCollab(index, val) {
    const collab = val;
    handleCollabChange(collab, "collab", index);
  }

  const [collabModal, setCollabModal] = React.useState(false);
  const handleCloseModal = () => {
    setCollabModal(false);
    setMatrix([]);
    setOnModalActive(null);
  };

  const [matrix, setMatrix] = React.useState([]);
  const [onModalActive, setOnModalActive] = React.useState(null);

  const handleCollabModal = (id, index) => {
    const myCollab = data?.graveyard?.collabs?.find(
      (collab) => collab._id === id
    );
    setOnModalActive(index);
    setMatrix(myCollab?.data);
    setCollabModal(true);
  };

  const addNicheAsValue = (i, j) => {
    // i is the row and j is the column
    // convert i into an alphabet and add it to j
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    let alphabetCode = alphabet[i];
    const nicheCode = alphabetCode + (j + 1);
    const niche = {
      // object key in here is alphabetCode + (j + 1)
      [alphabetCode]: j + 1,
      value: nicheCode,
    };
    const list = [...inputList];

    list[onModalActive].collabValue = niche;
    setInputList(list);
    handleCloseModal();
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: ["places"],
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <Modal
        as={Modal.Dialog}
        className="d-flex justify-content-center"
        show={collabModal}
        size="xl"
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Select a niche")}</Modal.Title>
          <Button
            variant="close"
            aria-label={t("Close")}
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => console(e)}>
            <Row>
              <Col xs={12} className="mb-3">
                <Card.Body className="p-0">
                  <TableContainer component={Paper}>
                    <TableBoost hover className="user-table align-items-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="border-2">
                            #
                          </th>
                          {
                            // number of columns
                          }
                          {matrix[0]?.map((col, i) => {
                            return (
                              <>
                                <th scope="col" className="border-2">
                                  {i + 1}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {matrix.map((row, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="border-2">
                                  {
                                    // alphabet
                                  }
                                  {String.fromCharCode(65 + i)}
                                </td>
                                {row.map((col, j) => {
                                  return (
                                    <>
                                      <td
                                        key={j}
                                        className={matrix[i][j] ? "active" : ""}
                                      >
                                        {matrix[i][j].length > 0 ? (
                                          // if matrix[i][j] has only one element
                                          matrix[i][j].length === 1 ? (
                                            <div
                                              className="periodic-element just-trying"
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                              data-description={
                                                matrix[i][j][0]?.profileName +
                                                " " +
                                                // if col.profileLastName is undefined don't show it
                                                (matrix[i][j][0]
                                                  ?.profileLastName || "")
                                              }
                                            >
                                              <span
                                                style={{
                                                  color: "black",
                                                  float: "right",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  addNicheAsValue(i, j);
                                                }}
                                              >
                                                <i className="ri-add-fill" />{" "}
                                              </span>
                                              <div className="periodic-element-inner">
                                                <Avatar
                                                  style={{
                                                    marginLeft: "10px",
                                                  }}
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_IMAGE +
                                                    "/" +
                                                    matrix[i][j][0]
                                                      ?.profileImage
                                                  }
                                                />
                                                {
                                                  // span in the middle of the element
                                                }
                                                <div
                                                  style={{
                                                    width: "90px",
                                                    whiteSpace: "normal",
                                                  }}
                                                >
                                                  {matrix[i][j][0]?.profileName}{" "}
                                                  {
                                                    matrix[i][j][0]
                                                      ?.profileLastName?.[0]
                                                  }
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                width: "100px",
                                                height: "80px",
                                                // blue background color
                                                background: "#323c46",
                                                // all elements are in the center
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                console.log(matrix[i][j], i, j);
                                              }}
                                            >
                                              <div
                                                // circle with number of elements
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                  borderRadius: "50%",
                                                  background: "#2c343d",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  color: "white",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                {matrix[i][j].length}
                                              </div>
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            style={{
                                              width: "100px",
                                              height: "80px",
                                              border: "2px solid #323c46",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // circle with the add icon
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                background: "#2c343d",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "white",
                                                fontSize: "20px",

                                                // pointer
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                addNicheAsValue(i, j);
                                              }}
                                            >
                                              <i className="ri-add-fill" />
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </TableBoost>
                  </TableContainer>
                </Card.Body>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div className="btn-toolbar mb-2 mb-md-2">
            <ButtonGroup>
              <Dropdown.Toggle
                onClick={(e) => history.goBack()}
                as={Button}
                variant="primary"
                size="sm"
                className="me-2"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                {t("back")}
              </Dropdown.Toggle>
            </ButtonGroup>
          </div>

          <h5 className="mb-4">{t("General informations")}</h5>
          <Form onSubmit={(e) => Submit(e)}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("lastname")}</Form.Label>
                  <Form.Control
                    required
                    maxLength={50}
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>{t("firstname")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    maxLength={50}
                    onChange={(e) =>
                      setFormData({ ...formData, lastn: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>{t("email")}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>{t("phone")}</Form.Label>
                  <PhoneInput
                    country={"ca"}
                    onlyCountries={["us", "ca"]}
                    value=""
                    onChange={(value, country, e, formattedValue) => {
                      setFormData({ ...formData, phone: formattedValue });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mb-3">
                <Form.Group id="address">
                  <Form.Label>{t("address")}</Form.Label>

                  <PlacesAutocomplete
                    className="form-control"
                    setAddress={setAddress}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>{t("image")}</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      setFormData({ ...formData, userimage: e.target.files[0] })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="my-4">{t("Cemetery Information")}</h5>
            <Row>
              <Col sm={4} className="mb-3">
                <Form.Group id="address">
                  <Form.Label>{t("cemetery")}</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    maxLength={50}
                    value={decoded.graveyardName}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="my-4">{t("Profile Information")} </h5>

            {inputList.map((x, i) => {
              return (
                <>
                  <h6 className="my-4">
                    {" "}
                    {t("Profile num")} {i + 1}{" "}
                  </h6>

                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>{t("lastname")}</Form.Label>
                        <Form.Control
                          required
                          key={i}
                          type="text"
                          maxLength={50}
                          name="profileName"
                          defaultValue={x.profileName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="lastName">
                        <Form.Label>{t("firstname")}</Form.Label>
                        <Form.Control
                          required
                          key={i}
                          type="text"
                          maxLength={50}
                          name="profileLastName"
                          defaultValue={x.profileLastName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col md={5} className="mb-3">
                      <Form.Group id="birthday">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}
                        >
                          <Stack spacing={3}>
                            <DatePicker
                              //  inputFormat="DD/MM/YYYY"
                              disableFuture
                              key={i}
                              required
                              value={x.profileDatebirth}
                              label={t("date_of_birth")}
                              name="profileDatebirth"
                              openTo="day"
                              views={["year", "month", "day"]}
                              onChange={(e) => handleInputChange2(e, i)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Form.Group>
                    </Col>
                    <Col md={2} className="mb-3">
                      <Form.Group id="checkbox">
                        <Form.Check
                          type="checkbox"
                          key={i}
                          checked={x.alive}
                          label={t("Deceased")}
                          name="alive"
                          onChange={(e) => {
                            handleAlive(e, i);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col hidden={!x.alive} md={5} className="mb-3">
                      <Form.Group id="birthday">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}
                        >
                          <Stack spacing={3}>
                            <DatePicker
                              // inputFormat="dd/MM/yyyy"
                              key={i}
                              value={x.profileDatedeath}
                              disableFuture
                              label={t("Date of death")}
                              name="profileDatedeath"
                              openTo="day"
                              views={["year", "month", "day"]}
                              onChange={(e) => handleInputChange3(e, i)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>{t("gender")}</Form.Label>
                        <Form.Select
                          defaultValue="0"
                          name="gender"
                          key={i}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="F">{t("women")}</option>
                          <option value="M">{t("man")}</option>
                          <option value="0">{t("other")}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="funérailles">
                        <Form.Label>{t("Funeral arrangements")}</Form.Label>
                        <Form.Select
                          defaultValue="I"
                          key={i}
                          name="modeDeath"
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="I">{t("Burial")} </option>
                          <option value="C">{t("Columbarium")}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {x?.modeDeath === "I" ? (
                      <Col md={6} className="mb-3">
                        <Form.Group id="location">
                          <Form.Label>{t("location")}</Form.Label>
                          {
                            // free solo option with search
                          }
                          <Autocomplete
                            id="position"
                            options={data?.graveyard?.places.map(
                              (option) => option?.code
                            )}
                            onChange={(e, value) => handlePlace(i, value)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      <>
                        <Col md={4} className="mb-3">
                          <Form.Group id="Columbarium">
                            <Form.Label>{t("Columbarium")}</Form.Label>

                            <Autocomplete
                              id="position"
                              options={data?.graveyard?.collabs}
                              getOptionLabel={(option) => option?.name}
                              onChange={(e, value) => handleCollab(i, value)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Form.Group>
                        </Col>

                        <Col md={6} className="mb-3">
                          <Form.Group id="Niche">
                            <Form.Label>{t("Niche")}</Form.Label>
                            {x?.collab !== "" ? (
                              <i
                                style={{
                                  color: "black",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                className="ri-eye-line"
                                onClick={() => {
                                  handleCollabModal(x?.collab, i);
                                }}
                              ></i>
                            ) : (
                              <> </>
                            )}
                            <Autocomplete
                              id="position"
                              options={x?.collabValues}
                              getOptionLabel={(option) =>
                                option?.value?.toUpperCase()
                              }
                              value={x?.collabValue}
                              onChange={(e, value) =>
                                handleCollabNiche(value, i)
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md={6} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>{t("reference_email")}</Form.Label>
                        <Form.Control
                          required
                          name="profileEmail"
                          key={i}
                          type="email"
                          value={formData.email}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="ml-50" variant="primary">
                    {inputList.length !== 1 && (
                      <Button
                        style={{ marginBottom: 20 }}
                        className="btn btn-danger"
                        key={i}
                        onClick={() => handleRemoveClick(i)}
                      >
                        {t("delete")}
                      </Button>
                    )}
                    {inputList.length - 1 === i && (
                      <Button
                        style={{ marginBottom: 20, marginLeft: 20 }}
                        className="ml-50"
                        variant="secondary"
                        key={i}
                        onClick={handleAddClick}
                      >
                        {t("Add another profile")}
                      </Button>
                    )}
                  </div>
                </>
              );
            })}

            <div className="mt-3" style={{textAlign:right}}>
              <Button variant="primary" type="submit">
                {t("save")}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default AddClient;
