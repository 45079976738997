import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Pagination from "./pagination";
import "./pagination.css";
import {
  Col,
  Nav,
  Card,
  Table,
  Row,
  Button,
  Image,
  ButtonGroup,
  Dropdown,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import axios from 'src/utils/axios';import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Paper, TableContainer } from "@mui/material";

export default function AllSuperAdmins() {
  const { t } = useTranslation();

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/users/getsupers"
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  async function deleteSuperAdmin(id) {
    try {
      Swal.fire({
        title: t("Are you sure?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it!"),
        cancelButtonText: t("cancel"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          const { data: response } = await axios.delete(
            `${process.env.REACT_APP_BACKEND_URL}/users/${id}`
          );
          Swal.fire(t("Deleted!"), t("user has been deleted."), "success").then(
            (result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            }
          );
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }
  //console.log(currentPosts);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent",
            }}
          ></Breadcrumb>
          <h4>{t("List of Super admins")}</h4>
          {/*           <p className="mb-0">Your web analytics dashboard template.</p>

 */}
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Dropdown.Toggle
              onClick={(e) => history.push("/addsuperadmin")}
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              {t("add_a_new_super_admin")}
            </Dropdown.Toggle>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}></Col>
        </Row>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          <TableContainer component={Paper}>
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">{t("full_name")}</th>
                  <th className="border-bottom">{t("reference_email")}</th>
                  <th className="border-bottom">{t("phone")}</th>
                  <th className="border-bottom">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts?.map((dm, index) => (
                  <tr>
                    <td>
                      <div className="user-avatar lg-avatar me-4">
                        <Image
                          style={{
                            flex: 1,
                            width: "50px",
                            height: "50px",
                          }}
                          src={
                            process.env.REACT_APP_API_IMAGE + "/" + dm.userimage
                          }
                          className="card-img-top rounded-circle border-white"
                        />
                      </div>
                    </td>
                    <td className="fw-bold">
                      {dm.name} {dm.lastn}
                    </td>
                    <td>{dm.email}</td>
                    <td>{dm?.phone}</td>

                    <td>
                      {" "}
                      <Button
                        onClick={() =>
                          history.push("/editsuperadmin/" + dm?._id)
                        }
                        size="sm"
                        className="m-1"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        title={t("edit")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-pencil-line"
                        />
                      </Button>
                      <Button
                        onClick={() => deleteSuperAdmin(dm?._id)}
                        size="sm"
                        disabled={dm?.name?.toUpperCase()?.includes("CHEDLY")}
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        className="m-1"
                        title={t("delete")}
                      >
                        <i
                          style={{ color: "black" }}
                          className="ri-delete-bin-line"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination
                className="pagination m-2"
                postsPerPage={postsPerPage}
                totalPosts={data.length}
                paginate={paginate}
              />
            </Nav>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
}
