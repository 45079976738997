import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyEn = () => {
  return (
    <div>
      <h1
        style={{
          fontSize: "2.5rem",
          fontWeight: "700",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        Privacy policy
      </h1>
      <div className="row justify-content-center">
        <p className="col-7 text-left mt-3">
          At Skiesbook LLC, we place the utmost importance on protecting your
          privacy. We want you to be transparently informed about our use of
          your information when you browse our site. By transacting with the
          Skiesbook LLC site, you agree to comply with these policies. We
          therefore invite you to read the following policy concerning the
          confidentiality of information.
        </p>
        <p className="col-7 text-left">
          For information purposes, we understand personal information to be:
        </p>
        <p className="col-7 text-left">
          “Any information that allows you to be identified such as your name,
          your phone number, your email and your address”.
        </p>

        <p className="col-7 text-left">
          The terms “business, “our” and “we” are used to refer to Skiesbook
          LLC. As a general rule, a person’s professional or business contact
          information, such as their name, title, address, business email
          address or phone number, are not considered personal information.
        </p>

        <p className="col-7 text-left">
          The following policy is the latest version available and is only valid
          for our site. If significant changes need to be made, we will post a
          notice on the main page of our site to inform you.
        </p>

        <h2 className="policytitle col-7 text-left">
          DATA COLLECTED (WEBSITE OR APPLICATION)
        </h2>
        <p className="col-7 text-left">
          Our site does not collect personal information unless you voluntarily
          transmit your data using our services for example:
          <ul>
            <li>
              Submitting a message to obtain additional information about a
              service. In this case, your first name, last name, email and phone
              number are collected.
            </li>
            <li>
              Subscribing to our newsletter by providing your email address.
            </li>
            <li>
              Sending your application for a position at Skiesbook LLC. We
              collect the following information: first name, last name, email,
              phone number, your resume detailing your professional and academic
              background.
            </li>
          </ul>
        </p>
        <p className="col-7 text-left">
          However, the reasons for the collection will always be mentioned and
          the information used clearly explained.
        </p>
        <h2 className="policytitle col-7 text-left">
          CELL PHONE OR OTHER MOBILE DEVICE USE
        </h2>
        <p className="col-7 text-left">
          When using our website on your cell phone or other mobile device, we
          may obtain information about your device ID and your location.
          However, you can block the location service directly on the device you
          are using.
        </p>
        <h2 className="policytitle col-7 text-left">
          COOKIES AND OTHER COMPARABLE TECHNOLOGIES
        </h2>
        <p className="col-7 text-left">
          Some cookies or other comparable technologies are used on our site to
          facilitate your page-to-page navigation, but they are not used to
          obtain any personal information. You can disable them in your browser
          settings. However, it is possible that disabling cookies will cause
          the features to appear differently.
        </p>
        <h2 className="policytitle col-7 text-left">TOOLS</h2>
        <p className="col-7 text-left">
          Some data is automatically collected when using our site such as your
          IP address, operating system and browser, pages you visit and requests
          you make and time and day of your connection to the site. This
          information does not reveal your identity and is therefore not
          considered confidential information.
        </p>
        <p className="col-7 text-left">
          We use analysis tools on this site. These are used to measure, collect
          and store information, allowing us to analyze traffic on our website
          and improve our services. We care about the well-being of our
          customers which is why we continually strive to improve ourselves. You
          can choose to disable and refuse the cookies from analysis tools. You
          will still be able to access the website, but access to some secure
          pages may be blocked.
        </p>
        <h2 className="policytitle col-7 text-left">USE OF INFORMATION</h2>
        <p className="col-7 text-left">
          The information collected by the analysis tools is used to produce
          statistics and reports for this site, enabling us to get to know our
          customers better. The statistics and reports are used to manage and
          improve the services we offer our customers.
          <p>
            Your personal information is not sold, rented, shared or disclosed
            in any way to any third party for the purpose of marketing your
            information.
          </p>
        </p>
        <h2 className="policytitle col-7 text-left">
          PUBLICATION AND THIRD PARTIES
        </h2>
        <p className="col-7 text-left">
          We may be called upon to share information that is neither
          personalized nor confidential about the business, including usage
          trends of our services, with third parties. We ensure that no
          transmission of confidential information is made without the
          customer’s consent.
        </p>
        <h2 className="policytitle col-7 text-left">SECURITY</h2>
        <p className="col-7 text-left">
          We do our utmost to protect the user. The personal information we
          collect is encrypted by SSL (Secure Sockets Layer) technology, as
          mentioned above, and access to data is strictly limited to the people
          who process data. They are subject to strict confidentiality
          obligations.
        </p>
        <p className="col-7 text-left">
          The data stored in the servers is only disclosed in case of legal
          obligation; to apply our terms of use; preserve our rights, property
          and safety as well as those of our users; to counter fraud; or with
          your consent and are protected by a privacy and security setting.
        </p>
        <h2 className="policytitle col-7 text-left">CHILDREN 13 AND UNDER</h2>
        <p className="col-7 text-left">
          We understand the importance of paying special attention to the
          protection of information of a child who uses our site. This site is
          not intended for minors. No personal information of a child under the
          age of 13 is stored on our servers without parental consent. If this
          is the case, measures will be taken as soon as possible to remedy the
          situation.
        </p>
        <h2 className="policytitle col-7 text-left">TRANSITION</h2>
        <p className="col-7 text-left">
          In the event of a sale, purchase, partnership, reorganization, merger,
          bankruptcy or any other possible transition of the company, we are
          committed to protecting the information collected in the transfer of
          these.
        </p>
        <h2 className="policytitle col-7 text-left">LINKS TO OTHER SITES</h2>
        <p className="col-7 text-left">
          This site may contain links to other sites. These sites are not
          governed by our privacy policy. We recommend that you check the
          privacy policies of other sites to understand their procedures
          regarding your confidential information. The business cannot be held
          responsible for the processing of confidential data from other sites
          from which it offers links for the convenience of its user.
        </p>

        <p className="col-7 text-left">
          Please do not hesitate to contact Skiesbook LLC if you would like any
          further information about our handling of your personal data, or if
          you would like to make any changes to the data we already have on
          hand. We will be happy to provide you with more information.
        </p>
        <h2 className="policytitle col-7 text-left">Skiesbook LLC</h2>
        <p className="col-7 text-left">
          7901 4th St N, St. Petersburg, FL 33702, United States
          <br />
          <a href="mailto:info@skiesbook.com"> info@skiesbook.com </a>
        </p>
      </div>
    </div>
  );
};

const PrivacyPolicyFr = () => {
  return (
    <div>
      <h1
        style={{
          fontSize: "2.5rem",
          fontWeight: "700",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        Politique de confidentialité
      </h1>
      <div className="row justify-content-center">
        <p className="col-7 text-left mt-3">
          Chez Skiesbook LLC, le respect de votre vie privée est primordial.
          Nous tenons à ce que vous soyez informé de façon transparente sur
          notre utilisation de vos informations lorsque vous naviguez sur notre
          site. En transigeant avec le site de Skiesbook LLC, vous acceptez de
          vous conformer aux présentes politiques. Nous vous invitons donc à
          prendre connaissance de la politique suivante concernant la
          confidentialité des informations.
        </p>
        <p className="col-7 text-left">
          À titre indicatif, nous entendons par informations personnelles :
        </p>
        <p className="col-7 text-left">
          « Toute information qui permet de vous identifier telle que votre nom,
          votre numéro de téléphone, votre courriel et votre adresse ».
        </p>

        <p className="col-7 text-left">
          Les termes « entreprise », « notre » et « nous » sont utilisés pour
          faire référence à Skiesbook LLC.
        </p>

        <p className="col-7 text-left">
          La politique suivante est la dernière version disponible et n’est
          valable que pour notre site. Si des modifications importantes sont à
          apporter, nous procéderons par avis sur la page principale de notre
          site pour vous informer.
        </p>

        <h2 className="policytitle col-7 text-left">
          DONNÉES COLLECTÉES (SITE WEB OU APPLICATION)
        </h2>
        <p className="col-7 text-left">
          Notre site ne collecte pas d’informations personnelles à moins que
          vous procédiez volontairement à la transmission de vos données.
          Lorsqu’un formulaire doit être complété, votre consentement sera
          clairement demandé afin d’utiliser vos données fournies pour des fins
          précises et expliquées. Par exemple, pour une inscription à une
          infolettre ou encore lors de l’envoi d’un message pour obtenir de
          l’information supplémentaire pour un service ou pour obtenir une
          soumission. Dans ce cas précis, votre nom, prénom, courriel et numéro
          de téléphone sont recueillis uniquement à cette fin.
        </p>
        <p className="col-7 text-left">
          Or, les motifs de la collecte vous seront toujours mentionnés et les
          renseignements utilisés clairement exposés.
        </p>
        <h2 className="policytitle col-7 text-left">
          UTILISATION DU CELLULAIRE OU AUTRE APPAREIL MOBILE
        </h2>
        <p className="col-7 text-left">
          Lors de l’utilisation de notre site web sur votre cellulaire ou
          appareil mobile, il est probable que nous obtenions des informations
          concernant l’identifiant de votre appareil et votre localisation. Il
          est cependant possible de bloquer le service de localisation
          directement sur l’appareil que vous utilisez.
        </p>
        <h2 className="policytitle col-7 text-left">
          TÉMOINS (COOKIES) ET AUTRES TECHNOLOGIQUES COMPARABLES
        </h2>
        <p className="col-7 text-left">
          Certains témoins (cookies) ou autres technologies comparables sont
          utilisés sur notre site pour vous faciliter la navigation de page en
          page, ils ne servent toutefois pas à obtenir des informations
          personnelles à votre sujet. Vous pouvez les désactiver dans les
          paramètres de votre navigateur. Par contre, il est possible qu’en
          désactivant les témoins (cookies) les fonctionnalités vous
          apparaissent différemment.
        </p>
        <h2 className="policytitle col-7 text-left">OUTILS</h2>
        <p className="col-7 text-left">
          Certaines données sont collectées automatiquement lors de
          l’utilisation de notre site tel que votre adresse IP, votre système
          d’exploitation et votre navigateur, les pages que vous avez visitées
          et les requêtes que vous y faites, l’heure et le jour de la connexion
          au site. Ces informations ne permettent pas de connaître votre
          identité et ne sont donc pas considérées comme étant des informations
          confidentielles.
        </p>
        <p className="col-7 text-left">
          Nous utilisons sur le présent site des outils d’analyse. Ceux-ci
          servent à mesurer, collecter et stocker des informations, nous
          permettant d’analyser le trafic sur notre site web et améliorer nos
          services. Nous avons à cœur le bien-être de nos clients c’est pourquoi
          nous tentons continuellement de nous surpasser. Vous pouvez choisir de
          désactiver et refuser les témoins des outils d’analyse. Vous pourrez
          tout de même accéder au site web, mais il est possible que l’accès à
          certaines pages sécurisées vous soit bloqué.
        </p>
        <h2 className="policytitle col-7 text-left">
          UTILISATION DES INFORMATIONS
        </h2>
        <p className="col-7 text-left">
          Les informations recueillies par les outils d’analyse aident à la
          production de statistiques et de rapports du présent site nous
          permettant de mieux connaître notre clientèle. Les statistiques et
          rapports sont utilisés pour la gestion et l’amélioration des services
          que nous proposons à notre clientèle.
          <p>
            Aucune vente ou location, aucun partage ou divulgation de vos
            renseignements personnels n’est fait, d’aucune manière, à une tierce
            partie dans un objectif de commercialisation de vos informations.
          </p>
        </p>
        <h2 className="policytitle col-7 text-left">PUBLICATION ET TIERS</h2>
        <p className="col-7 text-left">
          Nous pouvons être appelés à partager des informations qui ne sont ni
          personnalisées ni confidentielles sur l’entreprise, notamment les
          tendances d’utilisation de nos services à des tiers. Nous nous
          assurons qu’aucune transmission d’information confidentielle n’est
          faite sans le consentement du client.
        </p>
        <h2 className="policytitle col-7 text-left">SÉCURITÉ</h2>
        <p className="col-7 text-left">
          Nous en faisons le plus possible pour protéger l’utilisateur. Les
          renseignements personnels que nous recueillons sont cryptés par la
          technologie SSL (Secure Sockets Layer) comme mentionnée plus haut et
          l’accès aux données est strictement réservé aux personnes qui font le
          traitement des données. Ceux-ci sont soumis à des obligations de
          confidentialité stricte.
        </p>
        <p className="col-7 text-left">
          Les données stockées dans les serveurs ne sont communiquées qu’en cas
          d’obligation légale; pour appliquer nos conditions d’utilisations;
          préserver nos droits, nos biens et notre sécurité ainsi que ceux de
          nos utilisateurs; pour contrer la fraude; ou avec votre consentement
          et sont protégées par un paramètre de confidentialité et de sécurité.
        </p>
        <h2 className="policytitle col-7 text-left">
          ENFANTS DE 13 ANS ET MOINS
        </h2>
        <p className="col-7 text-left">
          Nous comprenons l’importance de porter une attention particulière à la
          protection des informations d’un enfant qui fait l’utilisation de
          notre site. Le présent site n’est pas destiné aux enfants mineurs.
          Aucune information personnelle d’un enfant âgé de moins de 13 ans
          n’est stockée dans nos serveurs sans l’accord des parents. Si tel est
          le cas, des mesures seront prises le plus tôt possible pour remédier à
          la situation.
        </p>
        <h2 className="policytitle col-7 text-left">TRANSITION</h2>
        <p className="col-7 text-left">
          Advenant la vente, l’achat, un partenariat, une réorganisation, la
          fusion, la faillite ou toute autre transition possible de
          l’entreprise, nous nous engageons à protéger les informations
          recueillies dans le transfert de ceux-ci.
        </p>
        <h2 className="policytitle col-7 text-left">LIENS À D’AUTRES SITES</h2>
        <p className="col-7 text-left">
          Le présent site peut contenir des liens vers d’autres sites. Ces sites
          ne sont pas régis par notre politique de confidentialité. Nous vous
          recommandons de vérifier les politiques de confidentialité des autres
          sites pour comprendre leurs procédures relatives à vos informations
          confidentielles. L’entreprise ne peut pas être tenue responsable du
          traitement des données confidentielles des autres sites desquels il
          propose des liens pour la convenance de son utilisateur.
        </p>
        <p className="col-7 text-left">
          N’hésitez pas à joindre Skiesbook LLC pour plus d’informations sur le
          traitement de vos données personnelles ou pour modifier celles que
          nous avons déjà entre les mains. C’est avec plaisir que nous vous
          informerons davantage.{" "}
        </p>
        <h2 className="policytitle col-7 text-left">Skiesbook LLC</h2>
        <p className="col-7 text-left">
          7901 4th St N, St. Petersburg, FL 33702, États-Unis
          <br />
          info@skiesbook.com
        </p>
      </div>
    </div>
  );
};

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      {i18n.language === "en" ? <PrivacyPolicyEn /> : <PrivacyPolicyFr />}
    </div>
  );
};

export default PrivacyPolicy;
