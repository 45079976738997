import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Autocomplete from "./autoComplete";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./search.css";

function Search() {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [valid, setValid] = useState("");
  const { t } = useTranslation();

  const search = (e) => {
    e.preventDefault();

    console.log("You hit the search button >>", valid);
    // history.go(
    //  `/result/${valid}`
    // )
    history.push(`/result?valid=${valid}`, { from: "Search" });
  };

  const handleAutocompleteOnChange = (event, value, reason, details) => {
    setProfile(value);
    setValid(details?.option?.fullName);
  };

  const handleValidity = (fullname) => {
    setValid(fullname);
  };

  return (
    <form className="search">
      <Autocomplete
        popupIcon={<ExpandMoreIcon />}
        onChange={handleAutocompleteOnChange}
        value={profile}
        textValue={handleValidity}
      />

      <div className="search__buttons">
        <Button
          type="submit"
          onClick={search}
          variant="outlined"
          disabled={valid === "" || valid === undefined}
        >
          {t("Skiesbook Search")}
        </Button>
      </div>
    </form>
  );
}

export default Search;
