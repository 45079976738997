/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { Nav, Navbar, Dropdown, Container } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import Language from "./language";
import "./../assets/styles/Navbar.css";

export default () => {
  const history = useHistory();
  function logout() {
    localStorage.clear();
    history.go(0);
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container id="navbar" fluid className="px-0">
        <div className="d-flex justify-content-end w-100">
          {/* <div className="d-flex align-items-center"></div> */}
          <Nav className="align-items-center">
            <div className="d-flex">
              <Dropdown
                style={{
                  cursor: "pointer",
                  BorderLeft: "1px solid #4a5073",
                  marginRight: "20px",
                }}
                as={Nav.Item}
              >
                <Dropdown.Toggle as={Nav.Link} className="px-0 noPadding">
                  <div className="media d-flex align-items-center">
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <div className="font-small fw-bold">Samples</div>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        "https://sales.skiesbook.com/prof/6424c0f026ca5b001a47f9fd",
                        "_blank"
                      )
                    }
                    className="fw-bold"
                  >
                    Eugène Dubois (EN)
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item
                    onClick={() => {
                      window.open(
                        "https://sales.skiesbook.com/prof/6424b4e626ca5b001a47f954",
                        "_blank"
                      );
                    }}
                    className="fw-bold"
                  >
                    Katrine Dubois (FR)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as={Nav.Item} className="">
                <Language />
              </Dropdown>

              <Dropdown
                as={Nav.Item}
                className="ml-1 pl-2"
                style={{ cursor: "pointer", BorderLeft: "1px solid #4a5073" }}
                onClick={() => logout()}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className=" me-2" />{" "}
              </Dropdown>
            </div>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
