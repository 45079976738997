import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    const { t } = this.props;
    
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return (
        <div>
          <h1>{t("Something went wrong. In the meantime, you can try to refresh the page or contact support.")}</h1>
        </div>
      );
    }

    // Render children if there's no error
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
