import React from "react";
  const Btn = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 7.16667H11.6667V9.66667H9.16667V11.3333H11.6667V13.8333H13.3333V11.3333H15.8333V9.66667H13.3333V7.16667ZM1.66667 10.5C1.66667 8.175 3.03333 6.16667 5 5.23333V3.41667C2.08333 4.46667 0 7.24167 0 10.5C0 13.7583 2.08333 16.5333 5 17.5833V15.7667C3.03333 14.8333 1.66667 12.825 1.66667 10.5ZM12.5 3C8.36667 3 5 6.36667 5 10.5C5 14.6333 8.36667 18 12.5 18C16.6333 18 20 14.6333 20 10.5C20 6.36667 16.6333 3 12.5 3ZM12.5 16.3333C9.28333 16.3333 6.66667 13.7167 6.66667 10.5C6.66667 7.28333 9.28333 4.66667 12.5 4.66667C15.7167 4.66667 18.3333 7.28333 18.3333 10.5C18.3333 13.7167 15.7167 16.3333 12.5 16.3333Z"
        fill="#0E63C0"
      />
    </svg>
  );
};
export default Btn;