import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
// pages

import graveyard_dashboard from "./dashboard/widgets";
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import jwt_decode from "jwt-decode";
import Addadmin from "./AddAdmin";
import clients from "./clients";
import AddClient from "./addClient";
import Addsuperadmin from "./addSuperAdmin";
import Myprofiles from "./myprofiles";
import ProfileCardWidget from "./profile";
import GetClient from "./adminListClient";
import AllProfiles from "./listeProfiles";
import AllSuperAdmins from "./listSuperAdmin";
import EditGrave from "./editGrave";
import EditSuperAdmin from "./editSuperAdmin";
import Test from "./testProfile";
import Testedit from "./testEditProfile";
import Staff from "./staff";
import addStaff from "./addGStaff";
//import StaffProfile from "./staffListeProfiles";
import Me from "./myProfile";
import editClient from "./editClient";
import Addastaff from "./addAstaff";
import Superadminstaff from "./superadminstaff";
import Prices from "./prices";
import Addprice from "./addprice";
import Mytickets from "./adminTickets";
import Ticketdetails from "./tickets/widgets";
import Alltickets from "./allTickets";
import Invi from "./invitations/searchList";
import InviOut from "./invitations/invitationsOut";
import InviIn from "./invitations/invitationsIn";
import Parente from "./invitations/prente";
import StaffTickets from "./staffTickets";
import GraveyardReport from "./dashboard/pergraveyard";
import graveyard_dashboard_cim from "./graveDashboard/widgets";
import comments from "./comments/index";
import welcome from "./password/welcomePassword";
import requests from "./requests";
import Language from "../components/language";
import Emplacement from "./map";
import EditStaff from "./staff/editGStaff";
import EditStaffSupperAdmin from "./staff/editAStaff";
import MapTest from "./map/test";
import B2B from "./b2b";
import Testing from "./collabruim/index_copy";
import Collabruim from "./collabruim/widgets";
import CollabAddProfile from "./collabruim/addProfiles";
import NonProfile from "./404profile";
import Search from "./search";

import Result from "./search/results";
import PrivacyPolicy from "./Policies/PrivacyPolicy";
import PersonalInformationPolicy from "./Policies/PersonalInformationPolicy";
import CookieConsentPopup from "./Policies/cookieConsentPopup";
import FooterMidlle from "../components/FooterMidlle";

import ListFunHomes from "./funHome/listFunHome";
import AddFunHome from "./funHome/addFunHome";
import AddClientFromFunHome from "./funHome/addClient";
import FunHomeListClients from "./funHome/listClients";
import EditFunHome from "./funHome/editFunHome";
import Refferal from "./funHome/referral";
import FunStaff from "./funHome/staff";
import AddFunStaff from "./funHome/addStaff";
import EditFunStaff from "./funHome/editStaff";

const RouteForProfile = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} />
          <Component {...props} />
          <CookieConsentPopup />
        </>
      )}
    />
  );
};

const RouteWithFooter = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} />
          <Component {...props} />
          <FooterMidlle />
          <CookieConsentPopup />
        </>
      )}
    />
  );
};

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <div className="p-1">
            <Language />
          </div>
          <Preloader show={loaded ? false : true} />
          <Component {...props} />
          <Footer centerStyle={true} />
          <CookieConsentPopup />
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <div style={{ minHeight: "85vh" }}>
              <Component {...props} />
            </div>
            <Footer
              centerStyle={false}
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
            <CookieConsentPopup />
          </main>
        </>
      )}
    />
  );
};

//const email = JSON.parse(localStorage.getItem("email"));
let token = null;
let decoded = null;
try {
  token = JSON.parse(localStorage.getItem("token"));
  if (token !== null) decoded = jwt_decode(token);
} catch (e) {
  // remove invalid token
  localStorage.removeItem("token");
}

export default function Myroutes() {
  return (
    <>
      {decoded !== null && decoded.role === "superadmin" ? (
        <Switch>
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />

          <Redirect path="/signin" to="/graveyard_dashboard" />
          <RouteWithSidebar exact path="/" component={clients} />
          <RouteWithSidebar
            exact
            path="/addsuperadmin"
            component={Addsuperadmin}
          />
          <RouteWithSidebar
            exact
            path="/addadmingstaff"
            component={Addastaff}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithSidebar exact path="/mystaff" component={Superadminstaff} />

          <RouteWithSidebar
            exact
            path="/singlereport/:id"
            component={GraveyardReport}
          />
          <RouteWithSidebar
            exact
            path="/editclient/:id"
            component={editClient}
          />

          <RouteWithSidebar
            exact
            path="/funeralHome"
            component={ListFunHomes}
          />

          <RouteWithSidebar
            exact
            path="/addFuneralHome"
            component={AddFunHome}
          />
          <RouteWithSidebar
            exact
            path="/editFunHome/:id"
            component={EditFunHome}
          />
          <RouteWithSidebar exact path="/requests" component={requests} />

          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/prices" component={Prices} />
          <RouteWithSidebar exact path="/addprices" component={Addprice} />
          <RouteWithSidebar exact path="/alltickets" component={Alltickets} />
          <RouteWithSidebar
            exact
            path="/ticketdetail/:id"
            component={Ticketdetails}
          />

          <RouteWithSidebar
            exact
            path="/funHomeClients/:funId"
            component={FunHomeListClients}
          />
          <RouteWithSidebar
            exact
            path="/editstaff/:id"
            component={EditStaffSupperAdmin}
          />

          <RouteWithSidebar exact path="/editgrave/:id" component={EditGrave} />
          <RouteWithSidebar
            exact
            path="/admin/superadmins"
            component={AllSuperAdmins}
          />
          <RouteWithSidebar
            exact
            path="/editsuperadmin/:id"
            component={EditSuperAdmin}
          />

          {/* pages */}
          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/AdminClients" component={clients} />
          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={GetClient}
          />

          <RouteWithSidebar exact path="/AdminAdd" component={Addadmin} />

          {/* a traduire cette page */}
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "admin" ? (
        <Switch>
          <Redirect path="/signin" to="/adminclients" />
          <RouteWithSidebar exact path="/" component={GetClient} />
          <RouteWithSidebar
            exact
            path="/invi/:id/:firstname/:lastname"
            component={Invi}
          />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/staff" component={Staff} />
          <RouteWithSidebar exact path="/addstaff" component={addStaff} />
          <RouteWithSidebar exact path="/columbarium" component={Collabruim} />
          <RouteWithSidebar
            exact
            path="/columbarium/:id"
            component={CollabAddProfile}
          />

          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithSidebar
            exact
            path="/emplacements"
            component={Emplacement}
          />
          <RouteWithSidebar exact path="/editstaff/:id" component={EditStaff} />

          <RouteWithSidebar
            exact
            path="/editclient/:id"
            component={editClient}
          />
          <RouteWithSidebar exact path="/mytickets" component={Mytickets} />
          <RouteWithSidebar
            exact
            path="/ticketdetail/:id"
            component={Ticketdetails}
          />
          <RouteWithSidebar
            exact
            path="/dashboard"
            component={graveyard_dashboard_cim}
          />

          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          {/* pages */}
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClient}
          />

          <RouteWithSidebar exact path="/adminclients" component={GetClient} />

          <RouteWithSidebar
            exact
            path="/adminprofiles"
            component={AllProfiles}
          />

          <RouteWithSidebar exact path="/clients" component={clients} />

          <RouteWithSidebar exact path="/AddAdmin" component={Addadmin} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "client" ? (
        <Switch>
          <Redirect path="/signin" to="/myProfiles" />
          <RouteWithSidebar exact path="/" component={Myprofiles} />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar
            exact
            path="/invi/:id/:firstname/:lastname"
            component={Invi}
          />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithSidebar
            exact
            path="/invitationsout/:id"
            component={InviOut}
          />
          <RouteWithSidebar
            exact
            path="/invitationsin/:id"
            component={InviIn}
          />
          <RouteWithSidebar exact path="/parente/:id" component={Parente} />
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          {/* pages */}
          <RouteWithSidebar
            exact
            path="/defunt/:id"
            component={ProfileCardWidget}
          />{" "}
          <RouteWithSidebar exact path="/comments" component={comments} />
          <RouteWithSidebar exact path="/myProfiles" component={Myprofiles} />
          <RouteWithSidebar exact path="/AddAdmin" component={Addadmin} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          {/* components */}
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "gstaff" ? (
        <Switch>
          <Redirect path="/signin" to="/adminclients" />
          <RouteWithSidebar exact path="/" component={GetClient} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />

          {/* pages */}
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClient}
          />
          <RouteWithSidebar
            exact
            path="/editclient/:id"
            component={editClient}
          />
          <RouteWithSidebar exact path="/adminclients" component={GetClient} />

          <RouteWithSidebar
            exact
            path="/staffprofile"
            component={AllProfiles}
          />
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />

          <RouteWithSidebar exact path="/clients" component={clients} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "gadmin" ? (
        <Switch>
          <Redirect path="/signin" to="/adminclients" />
          <RouteWithSidebar exact path="/" component={GetClient} />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar
            exact
            path="/editclient/:id"
            component={editClient}
          />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/staff" component={Staff} />
          <RouteWithSidebar exact path="/addstaff" component={addStaff} />
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithSidebar exact path="/editstaff/:id" component={EditStaff} />
          <Route exact path="/NonProfile" component={NonProfile} />

          {/* pages */}
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClient}
          />

          <RouteWithSidebar exact path="/adminclients" component={GetClient} />

          <RouteWithSidebar
            exact
            path="/adminprofiles"
            component={AllProfiles}
          />

          <RouteWithLoader exact path="/welcome" component={welcome} />

          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/clients" component={clients} />

          <RouteWithSidebar exact path="/AddAdmin" component={Addadmin} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "gcompta" ? (
        <Switch>
          <Redirect path="/signin" to="/dashboard" />
          <RouteWithSidebar
            exact
            path="/dashboard"
            component={graveyard_dashboard_cim}
          />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <Redirect path="/adminclients" to="/Staffclient" />
          <RouteWithSidebar exact path="/" component={GetClient} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/me/:id" component={Me} />

          {/* pages */}
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClient}
          />

          <RouteWithSidebar exact path="/Staffclient" component={GetClient} />

          <RouteWithSidebar
            exact
            path="/staffprofile"
            component={AllProfiles}
          />
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />

          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/clients" component={clients} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "help" ? (
        <Switch>
          <Redirect path="/signin" to="/alltickets" />
          <RouteWithSidebar exact path="/" component={clients} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteWithSidebar
            exact
            path="/addsuperadmin"
            component={Addsuperadmin}
          />

          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithSidebar exact path="/alltickets" component={StaffTickets} />
          <RouteWithSidebar
            exact
            path="/ticketdetail/:id"
            component={Ticketdetails}
          />
          <RouteWithSidebar
            exact
            path="/addadmingstaff"
            component={Addastaff}
          />
          <RouteWithSidebar exact path="/mystaff" component={Superadminstaff} />

          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />

          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteForProfile exact path="/prof/:id" component={Test} />

          <RouteWithSidebar exact path="/editgrave/:id" component={EditGrave} />
          <RouteWithSidebar
            exact
            path="/admin/superadmins"
            component={AllSuperAdmins}
          />
          <RouteWithSidebar
            exact
            path="/editsuperadmin/:id"
            component={EditSuperAdmin}
          />

          {/* pages */}
          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/AdminClients" component={clients} />
          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={GetClient}
          />

          <RouteWithSidebar exact path="/AdminAdd" component={Addadmin} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "sales" ? (
        <Switch>
          <Redirect path="/signin" to="/graveyard_dashboard" />
          <RouteWithSidebar exact path="/" component={clients} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/editgrave/:id" component={EditGrave} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <Route exact path="/NonProfile" component={NonProfile} />
          {/* pages */}
          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/AdminClients" component={clients} />
          <RouteWithSidebar exact path="/requests" component={requests} />
          <RouteWithSidebar
            exact
            path="/funeralHome"
            component={ListFunHomes}
          />
          <RouteWithSidebar
            exact
            path="/addFuneralHome"
            component={AddFunHome}
          />

          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={GetClient}
          />

          <RouteWithSidebar exact path="/AdminAdd" component={Addadmin} />

          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "sadmin" ? (
        <Switch>
          <Redirect path="/signin" to="/graveyard_dashboard" />
          <RouteWithSidebar exact path="/" component={clients} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteWithSidebar
            exact
            path="/addsuperadmin"
            component={Addsuperadmin}
          />
          <RouteWithSidebar
            exact
            path="/addadmingstaff"
            component={Addastaff}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithSidebar exact path="/mystaff" component={Superadminstaff} />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />
          <RouteForProfile exact path="/prof/:id" component={Test} />
          <RouteWithSidebar exact path="/editgrave/:id" component={EditGrave} />

          {/* pages */}
          <RouteWithSidebar
            exact
            path={Routes.Gaveyarddashboard.path}
            component={graveyard_dashboard}
          />
          <RouteWithSidebar exact path="/AdminClients" component={clients} />
          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={GetClient}
          />

          <RouteWithSidebar exact path="/AdminAdd" component={Addadmin} />

          <RouteForProfile exact path="/editprof/:id" component={Testedit} />
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "search" ? (
        <Switch>
          <Redirect path="/signin" to="/search" />
          <RouteWithLoader exact path="/" component={Search} />
          <Route exact path="/search" component={Search} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <Route exact path="/result" component={Result} />

          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader exact path="/me/:id" component={Me} />
          <RouteForProfile exact path="/prof/:id" component={Test} />

          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "funHomeAdmin" ? (
        <Switch>
          <Redirect path="/signin" to="/profiles" />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <RouteWithSidebar exact path="/" component={AllProfiles} />
          <RouteWithSidebar exact path="/mystaff" component={FunStaff} />
          <RouteWithSidebar exact path="/addstaff" component={AddFunStaff} />
          <RouteWithSidebar
            exact
            path="/editstaff/:id"
            component={EditFunStaff}
          />

          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteWithSidebar
            exact
            path="/adminclients"
            component={FunHomeListClients}
          />
          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={FunHomeListClients}
          />
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClientFromFunHome}
          />
          <RouteWithSidebar
            exact
            path="/refferal"
            component={Refferal}
          ></RouteWithSidebar>
          <RouteWithSidebar exact path="/mytickets" component={Mytickets} />
          <RouteWithSidebar
            exact
            path="/ticketdetail/:id"
            component={Ticketdetails}
          />
          <RouteWithLoader
            exact
            path="/prof/:id"
            component={Test}
          ></RouteWithLoader>
          <RouteWithLoader
            exact
            path="/editprof/:id"
            component={Testedit}
          ></RouteWithLoader>
          <Route exact path="/NonProfile" component={NonProfile} />

          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : decoded !== null && decoded.role === "funSales" ? (
        <Switch>
          <Redirect path="/signin" to="/profiles" />
          <RouteWithSidebar exact path="/me/:id" component={Me} />
          <RouteWithSidebar exact path="/profiles" component={AllProfiles} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <RouteWithSidebar exact path="/" component={AllProfiles} />
          <RouteWithSidebar exact path="/mystaff" component={FunStaff} />
          <RouteWithSidebar exact path="/addstaff" component={AddFunStaff} />
          <RouteWithSidebar
            exact
            path="/editstaff/:id"
            component={EditFunStaff}
          />

          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <RouteWithSidebar
            exact
            path="/adminclients"
            component={FunHomeListClients}
          />
          <RouteWithSidebar
            exact
            path="/GraveyardsProfiles/:id"
            component={FunHomeListClients}
          />
          <RouteWithSidebar
            exact
            path="/adminaddclient"
            component={AddClientFromFunHome}
          />
          <RouteWithSidebar
            exact
            path="/refferal"
            component={Refferal}
          ></RouteWithSidebar>
          <RouteWithSidebar exact path="/mytickets" component={Mytickets} />
          <RouteWithSidebar
            exact
            path="/ticketdetail/:id"
            component={Ticketdetails}
          />
          <RouteWithLoader
            exact
            path="/prof/:id"
            component={Test}
          ></RouteWithLoader>
          <RouteWithLoader
            exact
            path="/editprof/:id"
            component={Testedit}
          ></RouteWithLoader>
          <Route exact path="/NonProfile" component={NonProfile} />

          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <Redirect to={Routes.NotFound.path} />
        </Switch>
      ) : (
        <Switch>
          <RouteWithFooter exact path="/signin" component={Signin} />
          <RouteWithLoader
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <Route exact path="/NonProfile" component={NonProfile} />
          <RouteWithLoader
            exact
            path="/reset-password/:id"
            component={ResetPassword}
          />
          <RouteWithLoader exact path="/welcome" component={welcome} />
          <RouteWithLoader
            exact
            path="/defunt/:id"
            component={ProfileCardWidget}
          />
          <RouteWithLoader exact path="/signup" component={Signup} />
          <RouteWithLoader exact path="/map" component={MapTest} />
          <RouteWithFooter exact path="/b2b" component={B2B} />
          <RouteWithLoader exact path="/Collab" component={Testing} />

          <RouteForProfile exact path="/prof/:id" component={Test} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/personal-information-policy"
            component={PersonalInformationPolicy}
          />
          <Redirect to="/signin" />
        </Switch>
      )}
    </>
  );
}
